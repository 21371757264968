


























































import { useLPStore } from "@/store"
import { defineComponent, ref } from "@vue/composition-api"

export default defineComponent({
  name: "Hero",
  components: { },
  props: ["completed", "optedin", "optinfeatureenabled", "optinsavings"],
  setup() {
    const { sendOptInRequest } = useLPStore()
    const submittedOptIn = ref(false)

    const onOptIn = async () => {
      submittedOptIn.value = true
      sendOptInRequest()
    }

    return {
      onOptIn,
      submittedOptIn
    }
  }
})
