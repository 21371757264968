






import { computed, defineComponent } from "@vue/composition-api"
import { useAccountStore } from "@/store"
import { useGtm } from "vue-gtm"

export default defineComponent({
  name: "TagManagerFooter",
  setup() {
    const { email, phoneNumber } = useAccountStore()
    const emailValue = computed(() => {
      return email.value
    })
    const phoneNumberValue = computed(() => {
      return phoneNumber.value
    })

    const eventName = (process.env.VUE_APP_ENVIRONMENT === "staging" ? "Simplic Web View - Staging" : "Simplic Web View")
    window.dataLayer = window.dataLayer || []
    window.dataLayer?.push({
      event: eventName,
      email: emailValue.value,
      phone: phoneNumberValue.value
    })

    return {
      emailValue,
      phoneNumberValue
    }
  }
})
