








import { useSessionStore } from "@/store"
import { defineComponent } from "@vue/composition-api"
import Breadcrumbs from "@/components/Breadcrumbs.vue"
export default defineComponent({
  name: "NavigationDrawer",
  components: { Breadcrumbs },
  setup() {
    const { accountHomeHeadlineComputed } = useSessionStore()

    return {
      accountHomeHeadlineComputed
    }
  }
})
