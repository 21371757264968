
import { defineComponent, PropType, ref } from "@vue/composition-api"
import {
  createLowerMyPaymentLoanAgreement,
  EligibleProductDetails,
  Quote
} from "@/api/secure/account-home-service"
import { useAccountStore } from "@/store"
import { logError } from "@/lib/analytics"
import { interestRate, brazilDateFormat } from "@/lib/formatters/helpers"

export default defineComponent({
  name: "LowerMyPaymentQuoteDetails",
  props: {
    productDetails: {
      type:     Object as PropType<EligibleProductDetails>,
      required: true
    },
    quote: {
      type:     Object as PropType<Quote>,
      required: true
    }
  },
  setup(props) {
    const { accountID } = useAccountStore()
    const optedIn = ref(false)
    const topTableData = [
      {
        text: "Valor da parcela",
        value: props.quote.InstallmentAmount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
      }
    ]
    const bottomTableData = [
      {
        text: "Valor pago até agora",
        value: props.productDetails.paid_amount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
      },
      {
        text: "Saldo devedor do contrato atual",
        value: props.productDetails.loan_balance.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
      },
      {
        text: "Quantidade de parcelas",
        value: props.quote.InstallmentsRequested
      },
      {
        text: "Sua parcela vence todo dia",
        value: props.quote.FirstInstallmentDueDate && props.quote.FirstInstallmentDueDate.slice(-2)
      },
      {
        text: "Data da primeira parcela",
        value: brazilDateFormat(props.quote.FirstInstallmentDueDate)
      },
      {
        text: "Data da última parcela",
        value: brazilDateFormat(props.quote.LastInstallmentDueDate)
      },
      {
        text: "IOF",
        value: props.quote.IOF.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
      },
      {
        text: "Tarifas",
        value: props.quote.Fee.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
      },
      {
        text: "Taxa de juros",
        value: interestRate(props.quote.InterestRate, "a.m.")
      },
      {
        text: "Taxa de juros anual",
        value: interestRate(props.quote.APR, "a.a.")
      },
      {
        text: "CET",
        value: interestRate(props.quote.CET, "a.a.")
      }
    ]

    async function createLMPAgreement() {
      await createLowerMyPaymentLoanAgreement(accountID.value.toString(), props.quote, optedIn.value, optedIn.value).then(() => {
        window.location.href = "/contract-new?contract_attempted=true"
      }).catch((error) => {
        logError(error)
      })
    }

    return {
      createLMPAgreement,
      topTableData,
      bottomTableData,
      optedIn
    }
  }
})
