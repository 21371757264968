







/* eslint-disable @typescript-eslint/camelcase */
import { useAccountStore, useCookieStore } from "@/store"
import { defineComponent, ref } from "@vue/composition-api"
import SerasaQuestionnaireForm from "@/components/loan-processing/SerasaQuestionnaireForm.vue"
import { SerasaScoreRange, updateActivities } from "@/api/secure/lp-service"

//This page mainly added to demonstrate integration of the Serasa Questionnaire Form
export default defineComponent({
  name: "SerasaQuestionnairePage",
  components: { SerasaQuestionnaireForm },
  setup() {
    const { accountID, cpf } = useAccountStore()
    const { analyticDataLayer } = useCookieStore()
    const loanNumber = analyticDataLayer.value.loanNumber
    const scoreRange = ref<SerasaScoreRange | null>(null)
    const minSuccessfulScore = 719 // 719 to 1000 is low fraud risk

    const acceptScoreRange = async (sr: SerasaScoreRange) => {
      scoreRange.value = sr
      if (scoreRange.value.score >= minSuccessfulScore && loanNumber != null) {
        await updateActivities(loanNumber, [{ type: "identity_verification", new_status: "success" }])
          .catch()
      } else {
        await updateActivities(loanNumber!, [{ type: "identity_verification", new_status: "failure" }])
          .catch()
      }
      window.location.href = "/lp"
    }

    return {
      accountID,
      cpf,
      scoreRange,
      acceptScoreRange
    }
  }
})
