
import { defineComponent } from "@vue/composition-api"

export default defineComponent({
  name: "BankList",
  emits: ["select"],
  props: {
    value: {
      type: String,
      required: false,
      default: ""
    },
    rules: {
      type: Array,
      required: false,
      default: () => []
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(_, { emit }) {
    const bankList = [
      { name: "Por favor selecione", code: "" },
      { name: "Caixa", code: "104" },
      { name: "Itaú", code: "341" },
      { name: "Bradesco", code: "237" },
      { name: "Santander", code: "33" }
    ]

    const onChange = (value: string) => {
      emit("select", value)
    }

    return {
      bankList,
      onChange
    }
  }
})
