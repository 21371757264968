














import { defineComponent, ref } from "@vue/composition-api"
export default defineComponent({
  name: "SimplicCarousel",
  props: {
    id: {
      type: String,
      required: false
    },
    loading: {
      type: Boolean,
      requried: false,
      default: false
    }
  },
  setup() {
    const selectedObject = ref(null)

    return {
      selectedObject
    }
  }
})
