















import { defineComponent, onMounted } from "@vue/composition-api"
import SimplicExpressHeader from "@/components/loan-processing/SimplicExpressHeader.vue"
import { useCookieStore } from "@/store"
import { postEBSEvent } from "@/api/secure"

export default defineComponent({
  name: "KlaviOpenBanking",
  components: { SimplicExpressHeader },
  props: {
    redirect: {
      type: String,
      required: true
    }
  },
  setup () {
    const { analyticDataLayer, accessTokensData } = useCookieStore()

    const clicked = () => {
      if(accessTokensData.value.account_id && analyticDataLayer.value.loan_application_id) {
        postEBSEvent("klavi_frontend", accessTokensData.value.account_id, analyticDataLayer.value.loan_application_id, "klavi_open_clicked", "")
      }
    }

    onMounted(() => {
      if(accessTokensData.value.account_id && analyticDataLayer.value.loan_application_id) {
        postEBSEvent("klavi_frontend", accessTokensData.value.account_id, analyticDataLayer.value.loan_application_id, "klavi_open_landed", "")
      }
    })

    return {
      clicked
    }
  }
})
