import { render, staticRenderFns } from "./DesktopHeaderAuth.vue?vue&type=template&id=4ea1a0a0&scoped=true&"
import script from "./DesktopHeaderAuth.vue?vue&type=script&lang=ts&"
export * from "./DesktopHeaderAuth.vue?vue&type=script&lang=ts&"
import style0 from "./DesktopHeaderAuth.vue?vue&type=style&index=0&id=4ea1a0a0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ea1a0a0",
  null
  
)

/* custom blocks */
import block0 from "./DesktopHeaderAuth.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAppBar,VContainer,VDivider,VIcon,VList,VListItem,VListItemTitle,VMenu,VSpacer})
