









import { defineComponent } from "@vue/composition-api"
import { useLPStore } from "@/store"
import BankConnector from "@/components/loan-processing/BankConnector.vue"
export default defineComponent({
  name: "BorderEbs",
  components: { BankConnector },
  setup() {
    const { lpType } = useLPStore()
    const noDocUploadStatuses = ["opt_in_lp", "ebs_lp"]
    const showUploadDocsButton = !noDocUploadStatuses.includes(lpType.value)

    return {
      showUploadDocsButton
    }
  }
})
