import Vue from "vue"
import VueMeta from "vue-meta"

Vue.use(VueMeta)

export const defaultMeta = {
  title: "Empréstimo Pessoal Online Sem Burocracia - Simplic",
  meta: [
    { name: "description", content: "Escolha agora o valor do seu empréstimo e o prazo de pagamento. Receba o dinheiro em sua conta hoje mesmo. Crédito aprovado mesmo para negativados*." },
    { property: "og:title", content: "Empréstimo Pessoal Online Sem Burocracia - Simplic" },
    { property: "og:description", content: "Escolha agora o valor do seu empréstimo e o prazo de pagamento. Receba o dinheiro em sua conta hoje mesmo. Crédito aprovado mesmo para negativados*." },
    { property: "og:site_name", content: "Simplic" },
    { property: "og:type", content: "website" },
    { property: "og:image", content: "~@/images/Simplic-logo-og.png" },
    { property: "og:image:width", content: "1200" },
    { property: "og:image:height", content: "630" },
    { name: "robots", content: "index,follow" },
    { name: "twitter:card", content: "Escolha agora o valor do seu empréstimo e o prazo de pagamento. Receba o dinheiro em sua conta hoje mesmo. Crédito aprovado mesmo para negativados*." },
    { name: "twitter:site", content: `${process.env.FULL_URL}` },
    { name: "twitter:title", content: "Empréstimo Pessoal Online Sem Burocracia - Simplic" },
    { name: "twitter:description", content: "Escolha agora o valor do seu empréstimo e o prazo de pagamento. Receba o dinheiro em sua conta hoje mesmo. Crédito aprovado mesmo para negativados*." }
  ],
  link: [
    { rel: "canonical", href: `${process.env.FULL_URL}` }
  ]
}
