var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"id":"simplic-header","app":"","height":"100px"}},[_c('v-container',{attrs:{"id":"simplic-header--container"}},[_c('router-link',{attrs:{"to":_vm.logoLink}},[_c('img',{attrs:{"id":"simplic-logo","alt":_vm.$t('simplicLogoAlt'),"src":require("@/assets/header-logo.png")}})]),_c('v-spacer'),_c('div',{attrs:{"id":"simplic-header--links"}},[_c('router-link',{staticClass:"nav-link mx-2",attrs:{"to":"/account_home"}},[_vm._v(_vm._s(_vm.$t("dashboard")))]),_c('div',{staticClass:"ml-2"},[_c('v-menu',{attrs:{"bottom":"","right":"","open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('span',{attrs:{"id":"simplic-header--my-account"}},[_vm._v(_vm._s(_vm.$t("myLoans")))]),(_vm.loanApplicationsMenu)?_c('v-icon',[_vm._v("mdi-menu-up")]):_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}]),model:{value:(_vm.loanApplicationsMenu),callback:function ($$v) {_vm.loanApplicationsMenu=$$v},expression:"loanApplicationsMenu"}},[_c('v-list',[(!_vm.onLPPage)?_c('v-list-item',{staticClass:"dropdown-link-container",attrs:{"to":"/loan-status"}},[_c('v-list-item-title',{staticClass:"dropdown-link"},[_vm._v(" "+_vm._s(_vm.$t("myLoan"))+" ")])],1):_vm._e(),(_vm.displayRefiLink)?_c('v-list-item',{staticClass:"dropdown-link-container",attrs:{"to":"/top-up-refinance"}},[_c('v-list-item-title',{staticClass:"dropdown-link"},[_vm._v(" "+_vm._s(_vm.$t("refinance"))+" ")])],1):_vm._e(),_c('v-list-item',{staticClass:"dropdown-link-container",attrs:{"to":"/loan_applications"}},[_c('v-list-item-title',{staticClass:"dropdown-link",attrs:{"to":"/loan_applications"}},[_vm._v(" "+_vm._s(_vm.$t("myLoans"))+" ")])],1)],1)],1)],1),_c('div',{staticClass:"ml-2"},[_c('v-menu',{attrs:{"bottom":"","right":"","open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('span',{attrs:{"id":"simplic-header--my-account"}},[_vm._v(_vm._s(_vm.$t("aboutSimplic")))]),(_vm.aboutMenu)?_c('v-icon',[_vm._v("mdi-menu-up")]):_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}]),model:{value:(_vm.aboutMenu),callback:function ($$v) {_vm.aboutMenu=$$v},expression:"aboutMenu"}},[_c('v-list',[_c('v-list-item',{staticClass:"dropdown-link-container",attrs:{"to":"/como-funciona"}},[_c('v-list-item-title',{staticClass:"dropdown-link"},[_vm._v(" "+_vm._s(_vm.$t("howItWorks"))+" ")])],1),_c('v-list-item',{staticClass:"dropdown-link-container",attrs:{"to":"/quem_somos"}},[_c('v-list-item-title',{staticClass:"dropdown-link"},[_vm._v(" "+_vm._s(_vm.$t("whoWeAre"))+" ")])],1),_c('v-list-item',{staticClass:"dropdown-link-container",attrs:{"to":"/faq"}},[_c('v-list-item-title',{staticClass:"dropdown-link"},[_vm._v(" "+_vm._s(_vm.$t("faq"))+" ")])],1),_c('v-list-item',{staticClass:"dropdown-link-container",attrs:{"to":"/blog"}},[_c('v-list-item-title',{staticClass:"dropdown-link"},[_vm._v(" "+_vm._s(_vm.$t("blog"))+" ")])],1)],1)],1)],1),_c('v-divider',{attrs:{"vertical":""}}),_c('div',{staticClass:"ml-2"},[_c('v-menu',{attrs:{"bottom":"","right":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{attrs:{"size":"32px"}},[_vm._v("mdi-account-circle")]),(_vm.firstName)?_c('span',{attrs:{"id":"simplic-header--my-account"},domProps:{"innerHTML":_vm._s(_vm.$t('helloMessage', {firstName: _vm.firstName}))}}):_vm._e(),(_vm.userMenu)?_c('v-icon',[_vm._v("mdi-menu-up")]):_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}]),model:{value:(_vm.userMenu),callback:function ($$v) {_vm.userMenu=$$v},expression:"userMenu"}},[_c('v-list',[_c('v-list-item',{staticClass:"dropdown-link-container",attrs:{"to":"/redefinir_senha"}},[_c('v-list-item-title',{staticClass:"dropdown-link"},[_vm._v(" "+_vm._s(_vm.$t("resetPassword"))+" ")])],1),_c('v-list-item',{staticClass:"dropdown-link-container",attrs:{"to":"/atualizar_dados"}},[_c('v-list-item-title',{staticClass:"dropdown-link"},[_vm._v(" "+_vm._s(_vm.$t("updateProfileInfo"))+" ")])],1),_c('v-list-item',{staticClass:"dropdown-link-container"},[_c('LogoutListItem')],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }