<template>
  <v-btn
    v-if="submit"
    class="v-btn"
    type="submit"
    :text="text"
    :ripple="ripple"
    :disabled="disabled"
    :loading="loading"
    @click="clicked">
    <slot />
  </v-btn>
  <v-btn
    v-else
    class="v-btn"
    :text="text"
    :ripple="ripple"
    :disabled="disabled"
    :loading="loading"
    :depressed="depressed"
    @click="clicked">
    <slot />
  </v-btn>
</template>

<script>
export default {
  name:       "BrazilVBtn",
  props:      {
    text: {
      type:    Boolean,
      default: false
    },
    ripple: {
      type:    Boolean,
      default: false
    },
    type: {
      type:    String,
      default: "primary"
    },
    disabled: {
      type:    Boolean,
      default: false
    },
    loading: {
      type:    Boolean,
      default: false
    },
    submit: {
      type:    Boolean,
      default: false
    },
    depressed: {
      type:    Boolean,
      default: false
    }
  },
  methods: {
    clicked(event) {
      this.$emit("click", event)
    }
  }
}
</script>

<style scoped lang="scss">
.v-btn {
  background-color: $simplic-yellow !important;
  font: normal normal bold 18px OpenSans;
  border-radius: 8px;
  width: 100%;
}
</style>
