import Vue from "vue"
import Vuex from "vuex"
import notifications, { NotificationsStore } from "@/store/notifications"
import cookie, { CookieStore } from "@/store/cookie"
import session, { SessionStore } from "@/store/session"
import account, { AccountStore } from "@/store/account"
import paymentPlans, { PaymentPlansStore } from "@/store/paymentPlans"
import ebs, { EbsStore } from "@/store/ebs"
import lp, { LPStore } from "@/store/lp"
import accountDetails, { AccountDetailsStore } from "@/store/accountDetails"

Vue.use(Vuex)
// Need to set vue instance variables to show devtools in staging
// vuex is just another vue instance
Vue.config.devtools = process.env.VUE_APP_ENVIRONMENT !== "production"

const state = () => ({})
type RootState = ReturnType<typeof state>
interface State {
  cookie: CookieStore;
  notifications: NotificationsStore;
  session: SessionStore;
  account: AccountStore;
  paymentPlansStore: PaymentPlansStore;
  ebs: EbsStore;
  lp: LPStore;
  accountDetails: AccountDetailsStore;
}

const store = new Vuex.Store<State>({
  modules: {
    cookie,
    notifications,
    session,
    account,
    paymentPlans,
    ebs,
    lp,
    accountDetails
  }
})

export default store
type Store = typeof store

export { State, Store, RootState }

export * from "@/store/cookie"
export * from "@/store/notifications"
export * from "@/store/session"
export * from "@/store/account"
export * from "@/store/paymentPlans"
export * from "@/store/ebs"
export * from "@/store/lp"
export * from "@/store/accountDetails"
