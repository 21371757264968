










































/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent, onMounted, Ref, ref } from "@vue/composition-api"
import { useAccountDetailsStore, useCookieStore, useAccountStore, useEbsStore } from "@/store"
import { useNotificationsStore } from "@/store/notifications"
import tagRecordings from "@/plugins/hotjar/tagRecordings"
import { VueWithHotjar } from "@/types"
import { getBelvoBankConnection, FetchBelvoBankConnectionParams } from "@/api/secure"
import BelvoWidget from "@/components/loan-processing/BelvoWidget.vue"
import { logError } from "@/lib/analytics"

export default defineComponent({
  name: "OpenFinanceCard",
  components: { BelvoWidget },
  setup(_, { root }) {
    const { ebsMetadata } = useAccountDetailsStore()
    const { analyticDataLayer } = useCookieStore()
    const { accountID, fullName, cpf } = useAccountStore()
    const { belvoBankConnection } = useEbsStore()
    const genericError = "Ocorreu um erro enquanto processávamos o seu pedido. Por favor, tente novamente."
    const bankName = analyticDataLayer.value.bank_display_name?.toLowerCase() || ""
    const open_finance_selection: Ref<string> = ref("")
    const klaviUrl = "https://portal-open.klavi.ai/login"
    const belvoProvider = "Belvo"
    const klaviProvider = "Klavi"

    const params = {
      accountID: accountID.value,
      name: encodeURIComponent(fullName.value),
      cpf: cpf.value,
      bank: bankName
    } as FetchBelvoBankConnectionParams
    async function fetchBankConnection(params: FetchBelvoBankConnectionParams) {
      await getBelvoBankConnection(params)
        .then((data) => {
          belvoBankConnection.value = data
        })
        .catch((error) => {
          const { errorMessage } = useNotificationsStore()
          errorMessage(genericError)
          logError(error)
        })
    }

    const hasBelvoEbs = (() => {
      return (ebsMetadata.value && ebsMetadata.value.has_belvo_ebs)
    })
    const hasKlaviEbs = (() => {
      return (ebsMetadata.value && ebsMetadata.value.has_klavi_ebs)
    })
    const hasEbs = (() => {
      return (hasBelvoEbs() || hasKlaviEbs())
    })
    const hasMultipleEbsProviders = (() => {
      return (hasBelvoEbs() && hasKlaviEbs())
    })
    const openBelvoWidget = (async () => {
      await fetchBankConnection(params)
    })
    const openKlaviPortal = (() => {
      window.location.href = klaviUrl
    })
    const goToEbsProvider = (() => {
      if (open_finance_selection.value == belvoProvider) {
        openBelvoWidget()
      } else if (open_finance_selection.value == klaviProvider) {
        openKlaviPortal()
      }
    })

    onMounted(() => {
      if(hasEbs()) {
        tagRecordings(root as VueWithHotjar, ["open_finance_card_displayed"])
        if (hasBelvoEbs()) {
          open_finance_selection.value = belvoProvider
        } else if (hasKlaviEbs()) {
          open_finance_selection.value = klaviProvider
        }
      }

    })

    return {
      ebsMetadata,
      belvoBankConnection,
      bankName,
      accountID,
      fetchBankConnection,
      open_finance_selection,
      hasEbs,
      hasMultipleEbsProviders,
      goToEbsProvider
    }
  }
})
