



























/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from "@vue/composition-api"

export default defineComponent({
  name: "PaymentCard",
  props: {
    date: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    },
    installmentAmount: {
      type: String,
      required: true
    }
  }
})
