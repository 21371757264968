











/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent, ref, Ref, onMounted } from "@vue/composition-api"
import LoanHistoryCard from "@/components/account-home/LoanHistoryCard.vue"
import { useAccountDetailsStore } from "@/store"
import { Loan } from "@/api/secure/account-home-service"
import tagRecordings from "@/plugins/hotjar/tagRecordings"
import { VueWithHotjar } from "@/types"

export default defineComponent({
  name: "LoanHistory",
  components: { LoanHistoryCard },
  setup(_, { root }) {
    const { loans } = useAccountDetailsStore()
    const pastLoans: Ref<Loan[]> = ref([])
    const loaded: Ref<boolean> = ref(false)

    const getPastLoans = () => {
      if (loans != null && loans.value) {
        for(let i = 0; i < loans.value.length; i++) {
          if (loans.value[i].loan_status === "paid_off" || loans.value[i].loan_status === "sold") {
            pastLoans.value.push(loans.value[i])
          }
        }
      }
    }

    onMounted(() => {
      loaded.value = false
      getPastLoans()
      loaded.value = true

      tagRecordings(root as VueWithHotjar, ["loan_history_view_displayed"])
    })

    return {
      pastLoans,
      loaded,
      getPastLoans
    }
  }
})
