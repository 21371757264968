
import { defineComponent, nextTick, onMounted, onUnmounted, ref, Ref } from "@vue/composition-api"
import { useAccountDetailsStore } from "@/store"
import RefinanceQuotes from "@/components/account-home/RefinanceQuotes.vue"
import lodash from "lodash"

export default defineComponent({
  name: "RefinanceDetails",
  components: { RefinanceQuotes },
  props: {
    useV2Layout: {
      default: false,
      type: Boolean
    }
  },
  setup() {
    const { eligibleProducts } = useAccountDetailsStore()
    const productDetails = eligibleProducts.value?.eligible_offers?.top_up_refinance?.eligible_product_details
    const quotes = eligibleProducts.value?.eligible_offers?.top_up_refinance?.quotes
    const sortedQuotes = lodash.orderBy(quotes, ["InstallmentsRequested", ["asc"]])
    const currentInstallment = eligibleProducts.value?.eligible_offers?.top_up_refinance?.eligible_product_details.current_installment_amount

    const windowWidth = ref(window.innerWidth)
    const onResize = function() {
      windowWidth.value = window.innerWidth
    }

    onMounted(() => {
      nextTick(() => {
        window.addEventListener("resize", onResize)
      })
    })

    onUnmounted(() => {
      window.removeEventListener("resize", onResize)
    })

    const isMobile = function() {
      return windowWidth.value < 700
    }

    return {
      productDetails,
      isMobile,
      sortedQuotes,
      currentInstallment
    }
  }
})
