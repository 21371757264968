
import { defineComponent, ref } from "@vue/composition-api"
import { updateWhatsAppConsent } from "@/api/secure"
import { publicUpdateWhatsAppConsent } from "@/api/secure/account-home-service"
import { useNotificationsStore } from "@/store"
import VueI18n from "@/plugins/i18n"

// WhatsAppConsentButton calls account-home-service to update the user consent.
// If `accountId` prop is given, it will call the secure whatsapp consent update endpoint.
// Otherwise, it will use the `token` prop to call the public whatsapp consent update endpoint.
export default defineComponent({
  name: "WhatsAppConsentButton",
  props: {
    accountID: {
      type: Number,
      required: false,
      default: 0
    },
    token: {
      type: String,
      required: false,
      default: ""
    },
    checkbox: {
      type: Boolean,
      required: false,
      default: false
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { emit, root }) {
    const { successMessage, errorMessage } = useNotificationsStore()
    const updateInProgress = ref(false)
    const consentGiven = ref(false)

    const updateConsent = async () => {
      updateInProgress.value = true
      await updateWhatsAppConsent(props.accountID)
        .then(() => {
          consentGiven.value = true
          successMessage(VueI18n.t("whatsappConsentSuccess").toString())
          emit("whatsapp_consent_given", process.env.VUE_APP_FULL_URL + root.$route.path)
        })
        .catch(() => {
          errorMessage(VueI18n.t("whatsappConsentError").toString())
        })
        .finally(() => {
          updateInProgress.value = false
        })
    }

    const updateConsentWithToken = async () => {
      updateInProgress.value = true
      await publicUpdateWhatsAppConsent(props.token)
        .then(() => {
          consentGiven.value = true
          successMessage(VueI18n.t("whatsappConsentSuccess").toString())
          emit("whatsapp_consent_given", process.env.VUE_APP_FULL_URL + root.$route.path)
        })
        .catch(() => {
          errorMessage(VueI18n.t("whatsappConsentError").toString())
        })
        .finally(() => {
          updateInProgress.value = false
        })
    }

    const buttonClass = () => {
      if (props.fullWidth == true) {
        return "button-full-width"
      }
    }

    return {
      updateConsent,
      updateConsentWithToken,
      updateInProgress,
      consentGiven,
      buttonClass
    }
  }
})
