

/* eslint-disable @typescript-eslint/camelcase */
import {
  useAccountDetailsStore,
  useAccountStore,
  useCookieStore,
  useNotificationsStore
} from "@/store"
import { isValidEmail } from "@/lib/validators/email"
import { computed, ref } from "@vue/composition-api"
import { maxLength, required } from "@/lib/validators/common"
import BrazilVBtn from "@/components/account-home/BrazilVBtn.vue"
import BankAccount from "@/views/profile-update/BankAccount.vue"
import BrazilVTextField from "@/components/input/BrazilVTextField.vue"
import { hasLoanApplicationWithStatus, hasLoanWithStatus } from "@/lib/account/helpers"
import { updateProfile, Loan, LoanApplication } from "@/api/secure/account-home-service"
import NumberSelector from "@/components/input/NumberSelector.vue"
import { collectData, isFormValid, Collectable, VForm } from "@/lib/component/helpers"
import VueI18n from "@/plugins/i18n"
import { logError as logErrorInSentry } from "@/lib/analytics"

export default {
  name: "UpdateProfile",
  components: { NumberSelector, BrazilVTextField, BankAccount, BrazilVBtn },
  setup() {
    const { analyticDataLayer, updateAnalyticDataLayer } = useCookieStore()
    const account = useAccountStore()
    const accountDetails = useAccountDetailsStore()
    const notification = useNotificationsStore()

    const formRef = ref<VForm>()
    const bankAccountRef = ref<Collectable>()
    const buttonLoading = ref<boolean>(false)

    const initialEmail = ref<string>("")
    const initialSecondaryPhone = ref<string>("")
    const initialMonthlyIncome = ref<number>(0)
    const initialPaydateOrdinal = ref<string> ("")

    const email = ref<string>("")
    const confirmEmail = ref<string>("")
    const secondaryPhone = ref<string>("")
    const monthlyIncome = ref<number>(0)
    const paydateOrdinal = ref<string>("")

    const emailEdited = ref<boolean>(false)
    const confirmEmailEdited = ref<boolean>(false)
    const secondaryPhoneEdited = ref<boolean>(false)
    const monthlyIncomeEdited = ref<boolean>(false)
    const paydateOrdinalEdited = ref<boolean>(false)

    const loanApplicationDisabledStatus = ["lp", "ready_for_lp", "pending", "approved", "underwriting_approved", "applied", "contract_unsigned"]
    const loanDisabledStatus = ["current"]
    let loans: Loan[] = []
    let loanApplications: LoanApplication[] = []
    const isBankDisabled = ref<boolean>(false)

    const monthlyIncomeMaxLength = 5
    const phoneNumberMaxLength = 11

    const payDateList = []
    for (let i = 0; i < 30; i++) {
      payDateList.push(i.toString())
    }

    const setup = () => {
      initialEmail.value = analyticDataLayer.value.user_email == null ? "" : analyticDataLayer.value.user_email
      initialSecondaryPhone.value = analyticDataLayer.value.user_home_phone == null ? "" : analyticDataLayer.value.user_home_phone
      initialMonthlyIncome.value = parseFloat(analyticDataLayer.value.income == null || analyticDataLayer.value.income == "NaN" ? "0" : analyticDataLayer.value.income)
      initialPaydateOrdinal.value = analyticDataLayer.value.pay_date_ordinal == null ? "" : analyticDataLayer.value.pay_date_ordinal.toString()

      email.value = initialEmail.value
      confirmEmail.value = ""
      secondaryPhone.value = initialSecondaryPhone.value
      monthlyIncome.value = initialMonthlyIncome.value
      paydateOrdinal.value = initialPaydateOrdinal.value

      emailEdited.value = false
      confirmEmailEdited.value = false
      secondaryPhoneEdited.value = false
      monthlyIncomeEdited.value = false
      paydateOrdinalEdited.value = false

      loans = accountDetails.loans.value == null ? [] : accountDetails.loans.value
      loanApplications = accountDetails.loanApplications.value == null ? [] : accountDetails.loanApplications.value
      isBankDisabled.value = hasLoanWithStatus(loans, loanDisabledStatus) || hasLoanApplicationWithStatus(loanApplications, loanApplicationDisabledStatus)
    }
    setup()

    const bankID = accountDetails.bankAccount.value?.bank_identification_number
    const bankBranch = accountDetails.bankAccount.value?.bank_branch
    const accountType = accountDetails.bankAccount.value?.account_type
    const accountNumber = accountDetails.bankAccount.value?.account_number

    const showBankAccount = bankID || bankBranch || accountType || accountNumber

    const secondaryPhoneFormat = (phoneNumber: string): string => {
      // Remove non-numeric characters
      const formattedPhoneNumber = phoneNumber.trim().replace(/\D/g, "")
      let finalNumber: string
      // Format as (XX) XXXX-XXXX for landlines or (XX) 9XXXX-XXXX for mobile phones
      if (formattedPhoneNumber.length >= 2 && formattedPhoneNumber.length < 7) {
        finalNumber = "(" + formattedPhoneNumber.substring(0, 2) + ") " + formattedPhoneNumber.substring(2)
      } else if(formattedPhoneNumber.length > 7) {
        if (formattedPhoneNumber.length == 11) {
          finalNumber = "(" + formattedPhoneNumber.substring(0, 2) + ") " + formattedPhoneNumber.substring(2, 7) + "-" + formattedPhoneNumber.substring(7)
        } else {
          finalNumber = "(" + formattedPhoneNumber.substring(0, 2) + ") " + formattedPhoneNumber.substring(2, 6) + "-" + formattedPhoneNumber.substring(6)
        }
      } else {
        finalNumber = formattedPhoneNumber
      }

      return finalNumber.trim()
    }

    const secondaryPhoneCounter = (phoneNumber: string) => {
      let count = phoneNumber.trim().length

      if (phoneNumber.includes("(")) count--
      if (phoneNumber.includes(")")) count--
      if (phoneNumber.includes("-")) count--
      if (phoneNumber.includes(" ")) count -= (phoneNumber.split(" ").length - 1)

      return count
    }

    const isSecondaryPhoneMaxLength = (value: string): boolean => {
      const count = secondaryPhoneCounter(value.trim())
      return count === (phoneNumberMaxLength - 1) || count === phoneNumberMaxLength
    }

    const onSecondaryPhoneBlur = (value: string, isEdited: boolean) => {
      secondaryPhone.value = value.trim()
      secondaryPhoneEdited.value = isEdited
    }

    const formatMonthlyIncome = (value: string) => {
      if (value == "") return value.trim()

      const valueNum = parseFloat(value.trim().replace(/\./g, "").replace(",", "."))

      const valueString = valueNum.toString()
      // Check the length and format accordingly
      if (valueString.length >= 4) {
        return valueString.replace(/(\d{1})(\d{3})$/, "$1.$2")
      }

      return valueString
    }

    const monthlyIncomeCounter = (value: string) => {
      let count = value.trim().length

      if (value.includes(".")) {
        count--
      }

      return count
    }

    const onMonthlyIncomeBlur = (value: string, isEdited: boolean) => {
      const replacedValue = value.trim().replace(/\./g, "")
      monthlyIncome.value = parseFloat(replacedValue == "" ? "0" : replacedValue)
      monthlyIncomeEdited.value = isEdited
    }

    const onPaydateSelect = (value: string, isEdited: boolean) => {
      paydateOrdinal.value = value.trim()
      paydateOrdinalEdited.value = isEdited
    }

    const daysInThisMonth = computed(() => {
      const today = new Date()
      return new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate()
    })

    const pushChangesToAnalyticDataLayer = () => {
      analyticDataLayer.value.user_email = email.value.trim()
      analyticDataLayer.value.user_home_phone = secondaryPhone.value.trim()
      const paydateParsed = parseFloat(paydateOrdinal.value.trim())
      analyticDataLayer.value.pay_date_ordinal = isNaN(paydateParsed) ? null : paydateParsed
      analyticDataLayer.value.income = monthlyIncome.value.toString().replace(/\./g, "")
      updateAnalyticDataLayer()
    }

    const getErrorMessage = (error: string) => {
      if (error.includes("bank_branch_inactive")) return VueI18n.t("invalidBankBranch").toString()
      if (error.includes("bank_account_info_invalid")) return VueI18n.t("invalidBankAccount").toString()

      return VueI18n.t("profileUpdateFailureGeneric").toString()
    }

    const submitForm = async () => {

      if (isFormValid(formRef)) {
        const bankAccount = collectData(bankAccountRef)

        const formattedMonthlyIncome = monthlyIncome.value.toString().replace(/\./g, "")

        const ahsEmail = emailEdited.value ? email.value.trim() : ""
        const ahsSecondaryPhone = secondaryPhoneEdited.value ? secondaryPhone.value.trim().replace(/\D/g, "") : ""
        const ahsMonthlyIncome = monthlyIncomeEdited.value ? formattedMonthlyIncome.trim() == "0" ? "" : formattedMonthlyIncome.trim() : ""
        let ahsPaydateOrdinal = paydateOrdinalEdited.value ? paydateOrdinal.value.trim() : ""

        if (ahsPaydateOrdinal === daysInThisMonth.value.toString()) {
          ahsPaydateOrdinal = "-1"
        }

        buttonLoading.value = true
        try {
          const origEmail = analyticDataLayer.value.user_email == null ? "" : analyticDataLayer.value.user_email.trim()
          const response = await updateProfile(account.accountID.value.toString(), origEmail,
            ahsEmail, ahsPaydateOrdinal, bankAccount, ahsSecondaryPhone, ahsMonthlyIncome)

          pushChangesToAnalyticDataLayer()
          setup()
          notification.successMessage(response.message)
          buttonLoading.value = false

        } catch (updateProfileResponse) {
          const extras = {
            response: updateProfileResponse,
            accountID: account.accountID.value.toString(),
            originalEmail: analyticDataLayer.value.user_email == null ? "" : analyticDataLayer.value.user_email.trim(),
            newEmail: ahsEmail,
            paydateOrdinal: ahsPaydateOrdinal,
            bankAccount: bankAccount,
            secondaryPhone: ahsSecondaryPhone,
            monthlyIncome: ahsMonthlyIncome
          }
          await logErrorInSentry(extras, "update profile failed")
          notification.errorMessage(getErrorMessage(updateProfileResponse.errors))
          buttonLoading.value = false
        }
      }
    }

    const emailRules = [
      required,
      isValidEmail
    ]

    const confirmEmailRules = [
      required,
      isValidEmail,
      (value: string) => value.trim() == email.value || "e-mail não corresponde"
    ]

    const secondaryPhoneRules = [
      required,
      maxLength(phoneNumberMaxLength - 1,
        "Por favor, preencha um número de telefone valido (DDD) + 9 dígitos - (XX) XXXXX-XXXX",
        isSecondaryPhoneMaxLength)
    ]

    const monthlyIncomeRules = [
      required,
      (value: string) => value != "0" || "Esse campo é obrigatório."
    ]

    const onConfirmEmailBlur = (value: string) => {
      confirmEmail.value = value.trim()
      confirmEmailEdited.value = true
    }

    const onEmailInput = (value: string, isEdited: boolean) => {
      email.value = value.trim()
      emailEdited.value = isEdited

      if (!emailEdited.value) {
        confirmEmail.value = ""
      }
    }

    return {
      initialEmail,
      initialPaydateOrdinal,
      initialSecondaryPhone,
      initialMonthlyIncome,

      email,
      emailEdited,
      emailRules,
      onEmailInput,
      confirmEmail,
      confirmEmailEdited,
      confirmEmailRules,
      onConfirmEmailBlur,
      formRef,
      bankAccountRef,
      secondaryPhone,
      secondaryPhoneEdited,
      phoneNumberMaxLength,
      secondaryPhoneFormat,
      secondaryPhoneCounter,
      onSecondaryPhoneBlur,
      isSecondaryPhoneMaxLength,
      secondaryPhoneRules,
      monthlyIncome,
      onMonthlyIncomeBlur,
      formatMonthlyIncome,
      monthlyIncomeCounter,
      monthlyIncomeRules,
      monthlyIncomeMaxLength,
      monthlyIncomeEdited,
      paydateOrdinal,
      paydateOrdinalEdited,
      daysInThisMonth,
      onPaydateSelect,
      isBankDisabled,
      submitForm,
      buttonLoading,
      showBankAccount,
      getErrorMessage
    }
  }
}
