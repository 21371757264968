




























































/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent, Ref, ref, onMounted } from "@vue/composition-api"
import BrazilVSlider from "@/components/account-home/BrazilVSlider.vue"
import { useAccountDetailsStore } from "@/store"
import * as lodash from "lodash"
import tagRecordings from "@/plugins/hotjar/tagRecordings"
import { VueWithHotjar } from "@/types"
import VueLadda from "vue-ladda"

export default defineComponent({
  name: "QuoteSlider",
  props: {
    canApply: {
      type: Boolean,
      required: false
    }
  },
  components: { BrazilVSlider, VueLadda },
  setup(props, { root }) {
    const { quotes, loanApplications } = useAccountDetailsStore()
    const requestedAmounts: Ref<number[]> = ref([])
    const selectedAmount: Ref<number> = ref(500) // default
    const selectedInstallment: Ref<number> = ref(6) // default
    const allInstallments: Ref<number[]> = ref([3, 6, 9, 12])
    const monthlyInstallmentAmount: Ref<number> = ref(0) // default
    const sliderMin: Ref<number> = ref(0) // default
    const sliderMax: Ref<number> = ref(3000) // default
    const sliderStep: Ref<number> = ref(250) // default
    const applyNowButton: Ref<boolean> = ref(true)
    const laddaLoading: Ref<boolean> = ref(false)

    const allAmounts = () => {
      requestedAmounts.value = lodash.sortedUniq(
        lodash.sortBy(
          lodash.flatMap(
            quotes.value,
            (q) => (q.AmountRequested)
          )
        )
      )
    }

    const availableInstallments = () => {
      const availableQuotes = lodash.filter(
        quotes.value,
        (q) => q.AmountRequested === selectedAmount.value
      )

      return lodash.sortedUniq(
        lodash.flatMap(
          lodash.sortBy(availableQuotes, ["InstallmentsRequested"]),
          (q) => (q.InstallmentsRequested)
        )
      )
    }

    const updateSelectedAmount = () => {
      const firstAmount = requestedAmounts.value[0]
      const lastAmount = requestedAmounts.value[requestedAmounts.value.length - 1]
      if (selectedAmount.value < firstAmount) {
        selectedAmount.value = firstAmount
      } else if (selectedAmount.value > lastAmount) {
        selectedAmount.value = lastAmount
      }
    }

    const updateSelectedInstallment = () => {
      const installments = availableInstallments()
      const firstInstallment = installments[0]
      const lastInstallment = installments[installments.length - 1]
      if (selectedInstallment.value < firstInstallment) {
        selectedInstallment.value = firstInstallment
      } else if (selectedInstallment.value > lastInstallment) {
        selectedInstallment.value = lastInstallment
      }
    }

    const updateMonthlyInstallment = () => {
      const quote = quotes.value?.find(q => q.AmountRequested === selectedAmount.value && q.InstallmentsRequested === selectedInstallment.value)
      if (quote) {
        monthlyInstallmentAmount.value = quote.InstallmentAmount
      }
    }

    const min = () => {
      if (requestedAmounts.value) {
        sliderMin.value = requestedAmounts.value[0]
      }
    }

    const max = () => {
      if (requestedAmounts.value && requestedAmounts.value.length > 1) {
        sliderMax.value = requestedAmounts.value[requestedAmounts.value.length - 1]
      }
    }

    const step = () => {
      if (requestedAmounts.value && requestedAmounts.value.length > 1) {
        sliderStep.value = requestedAmounts.value[1] - requestedAmounts.value[0]
      }
    }

    const installmentExists = (installment: number) => {
      return lodash.includes(availableInstallments(), installment)
    }

    const setSelectedInstallments = (installment: number) => {
      if (!installmentExists(installment)) {
        // get first available installment
        const installments = availableInstallments()
        selectedInstallment.value = installments[0]
        updateMonthlyInstallment()
        return
      }

      selectedInstallment.value = installment
      updateMonthlyInstallment()
    }

    const amountChanged = (amount: number) => {
      selectedAmount.value = amount
      const installments = availableInstallments()

      if (lodash.includes(installments, selectedInstallment.value)) {
        updateMonthlyInstallment()
        return
      }

      setSelectedInstallments(selectedInstallment.value)
    }

    const isThreeInstallments = (installment: number) => {
      return installment === 3

    }

    const isTwelveInstallments = (installment: number) => {
      return installment === 12

    }

    const determineButtonText = () => {
      if (loanApplications && loanApplications.value && loanApplications.value?.length > 0) {
        const lastLoanAppStatus = loanApplications.value[0].status
        if (lastLoanAppStatus == "applied" || lastLoanAppStatus == "contract_unsigned" || lastLoanAppStatus == "underwriting_approved") {
          applyNowButton.value = false
        }
      }
    }

    const enableLaddaLoading = () => {
      laddaLoading.value = true
    }

    const buttonClasses = (): string => {
      return laddaLoading.value ? "button-disabled ladda-button" : "ladda-button"
    }

    const submitQuoteToFrontend = async () => {
      if (selectedAmount.value.toString().length !==0 && monthlyInstallmentAmount.value.toString().length !== 0) {
        enableLaddaLoading()
        const quote = {
          amount: selectedAmount.value,
          installment: selectedInstallment.value,
          installment_amount: monthlyInstallmentAmount.value
        }

        const encodedQuote = encodeURIComponent(JSON.stringify(quote))
        root.$router.push({ name: "submit_quote", query: { quote: encodedQuote } })
      }
    }

    onMounted(() => {
      allAmounts()
      min()
      max()
      step()
      availableInstallments()
      updateSelectedAmount()
      updateSelectedInstallment()
      updateMonthlyInstallment()
      determineButtonText()

      if (props.canApply) {
        tagRecordings(root as VueWithHotjar, ["quote_slider_card_displayed"])
      }
    })

    return {
      sliderMin,
      sliderMax,
      sliderStep,
      amountChanged,
      setSelectedInstallments,
      allInstallments,
      selectedAmount,
      selectedInstallment,
      monthlyInstallmentAmount,
      requestedAmounts,
      installmentExists,
      isThreeInstallments,
      isTwelveInstallments,
      updateSelectedAmount,
      updateSelectedInstallment,
      applyNowButton,
      submitQuoteToFrontend,
      determineButtonText,
      laddaLoading,
      buttonClasses
    }
  }
})
