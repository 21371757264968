














import { getWhatsAppConsentAgreed } from "@/api/secure/whatsapp-consent"
import WhatsAppConsentCard from "@/components/whatsapp/WhatsAppConsentCard.vue"
import { useAccountStore } from "@/store"
import { Ref, defineComponent, ref } from "@vue/composition-api"

export default defineComponent({
  name: "OptionalTasks",
  components: { WhatsAppConsentCard },
  setup() {
    const { accountID } = useAccountStore()
    const displayOptionalTasks: Ref<boolean> = ref(false)
    const displayWhatsApp: Ref<boolean> = ref(false)
    const confirmRegex = new RegExp(".*confirmation$")
    const lpRegex = new RegExp(".*/lp$")

    return {
      displayOptionalTasks,
      displayWhatsApp,
      accountID,
      confirmRegex,
      lpRegex
    }
  },
  watch: {
    async $route (to) {
      if (this.confirmRegex.test(to.path) || this.lpRegex.test(to.path)) {
        const agreed = await getWhatsAppConsentAgreed(this.accountID)
        this.displayWhatsApp = !agreed
        this.displayOptionalTasks = this.displayWhatsApp
      }
    }
  }
})

