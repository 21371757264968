






















































/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent, Ref, ref, onMounted, PropType } from "@vue/composition-api"
import { Obligation, LoanPayment, Loan, LoanHistoryInfo } from "@/api/secure/account-home-service"

export default defineComponent({
  name: "LoanHistoryCard",
  props: {
    loans: {
      type: [Array, Object] as PropType<Loan[]>,
      required: true
    }
  },
  setup(props, { root }) {
    const expand: Ref<boolean[]> = ref([])
    const loansInfo: Ref<LoanHistoryInfo[]> = ref([])

    const convertDate = (input: string) => {
      const date = new Date(input)
      const dateString = date.toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" })
      return dateString.substr(0, 10)
    }

    const getPaidOffDate = (index: number) => {
      const payments: LoanPayment[] = props.loans[index].payments
      payments.sort(function(a, b){
        return +new Date(b.effective_date) - +new Date(a.effective_date)
      })
      return convertDate(payments[0].effective_date)
    }

    const getFinalDueDate = (index: number) => {
      const obligations: Obligation[] = props.loans[index].obligations
      obligations.sort(function(a, b){
        return +new Date(b.due_date) - +new Date(a.due_date)
      })
      return convertDate(obligations[0].due_date)
    }

    const getLoansArray = () => {
      for(let i = 0; i < props.loans.length; i++) {
        const info: LoanHistoryInfo = {
          paid_off_date: getPaidOffDate(i),
          loan_app_id: props.loans[i].loan_agreement.loan_application_id,
          loan_amount: parseFloat(props.loans[i].amount).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
          created_at: convertDate(props.loans[i].created_at),
          final_due_date: getFinalDueDate(i)
        }
        loansInfo.value.push(info)
      }
    }

    const expandClick = (index: number) => {
      if (expand.value[index] === true) {
        expand.value[index] = false
        expand.value = [...expand.value]
        return
      }
      expand.value[index] = true
      expand.value = [...expand.value]
    }

    onMounted(() => {
      getLoansArray()
      for(let i = 0; i < props.loans.length; i++) {
        expand.value.push(false)
      }
    })

    return {
      expandClick,
      expand,
      loansInfo
    }
  }
})
