



























import { defineComponent, computed } from "@vue/composition-api"
import { useCookieStore, useLPStore } from "@/store"
import DocumentUpload from "@/components/loan-processing/DocumentUpload.vue"

export default defineComponent({
  name: "ConfirmIncome",
  components: { DocumentUpload },
  setup () {
    const { activities, getActivities } = useLPStore()
    const { analyticDataLayer } = useCookieStore()
    const loanNumber = analyticDataLayer.value.loanNumber

    const activity = computed(() => {
      for (const activity of activities.value) {
        if (activity.name != "income_verification") {
          continue
        }

        return activity
      }
    })

    const status = computed(() => {
      return activity.value?.status
    })

    const reason = computed(() => {
      return activity.value?.reason
    })

    const uploaded = async () => {
      if (loanNumber) {
        await getActivities(loanNumber, {})
      }
    }

    return { status, reason, uploaded }
  }
})
