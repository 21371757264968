import { client } from "@/api/secure-service"

export interface ExpressIFrameResponse {
  src: string;
  checking_document_uuid: string;
  code: number;
}

interface EBSEventPayload {
  source: string;
  account_id: number;
  loan_application_id: string;
  status: string;
  checking_document_uuid: string;
}

export interface EBSEventResponse {
  source: string;
  status: string;
  loan_application_id: number;
}

export interface FetchBankConnectionParams {
  accountID: number;
  loanNumber: string;
  loanApplicationID: string;
  name: string;
  cpf: number;
  bank: string;
  forcePlatform?: string;
}

export interface FetchBelvoBankConnectionParams {
  accountID: number;
  name: string;
  cpf: number;
  bank: string;
}

export interface BelvoAccessTokens {
  access: string;
  refresh: string;
}

export interface FetchBankConnectionResponse {
  belvo_access_tokens: BelvoAccessTokens | null;
  simplic_express_iframe: ExpressIFrameResponse | null;
  klavi_consent_url: string | null;
}

export interface FetchBelvoBankConnectionResponse {
  belvo_access_tokens: BelvoAccessTokens;
}

export async function getExpressIFrame (accountId: number, loanNumber: string, loanApplicationId: string): Promise<ExpressIFrameResponse> {
  try {
    const response = await client().get<ExpressIFrameResponse>(`/bank-reports/simplic-express-iframe?accountID=${accountId}&loanNumber=${loanNumber}&loanApplicationID=${loanApplicationId}`)
    return response as ExpressIFrameResponse
  } catch(err) {
    return Promise.reject(err)
  }
}

export async function postEBSEvent (source: string, accountID: number, loanApplicationID: string, status: string, checkingDocumentUUID: string): Promise<EBSEventResponse> {
  const payload: EBSEventPayload = { "source": source, "account_id": accountID, "loan_application_id": loanApplicationID, "status": status, "checking_document_uuid": checkingDocumentUUID }

  try {
    const response = await client().post("/bank-reports/report-ebs-events", payload)
    return response as EBSEventResponse
  } catch(err) {
    return Promise.reject(err)
  }
}

// getBankConnection retrieves either belvo or klavi bank connection from simplic-bank-reports.
export async function getBankConnection (params: FetchBankConnectionParams): Promise<FetchBankConnectionResponse> {
  try {
    const query = `?accountID=${params.accountID}&loanNumber=${params.loanNumber}&loanApplicationID=${params.loanApplicationID}&name=${params.name}&cpf=${params.cpf}&bank=${params.bank}&forcePlatform=${params.forcePlatform || ""}`
    const response = await client().get<FetchBankConnectionResponse>("/bank-reports/fetch-bank-connection" + query)
    return response as FetchBankConnectionResponse
  } catch(err) {
    return Promise.reject(err)
  }
}

// getBelvoBankConnection retrieves belvo bank connection from simplic-bank-reports.
export async function getBelvoBankConnection (params: FetchBelvoBankConnectionParams): Promise<FetchBelvoBankConnectionResponse> {
  try {
    const query = `?accountID=${params.accountID}&name=${params.name}&cpf=${params.cpf}&bank=${params.bank}`
    const response = await client().get<FetchBelvoBankConnectionResponse>("/bank-reports/fetch-belvo-bank-connection" + query)
    return response as FetchBelvoBankConnectionResponse
  } catch(err) {
    return Promise.reject(err)
  }
}
