






import AccountHomeLayout from "@/components/layouts/AccountHomeLayout.vue"
import { defineComponent, watch, ref } from "@vue/composition-api"
import { useSessionStore } from "@/store"
export default defineComponent({
  name: "RouteLayoutSelector",
  setup(_, { root }) {
    const layout = ref(AccountHomeLayout)
    const { accountHomeHeadline } = useSessionStore()
    watch(
      () => root.$route.meta,
      async meta => {
        const metaLayoutComponentName = meta.layoutComponentName
        /* istanbul ignore next */
        if (metaLayoutComponentName === "AccountHomeLayout") {
          accountHomeHeadline.value = meta.accountHomeHeadline
        }
        /* istanbul ignore next */
        if (metaLayoutComponentName && metaLayoutComponentName != layout.value.name) {
          const component = await import(`@/components/layouts/${ meta.layoutComponentName }.vue`)
          layout.value = component?.default
        }
      },
      { immediate: true }
    )
    return { layout }
  }
})
