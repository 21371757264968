export function interestRate(value: number, postfix: string): string {
  const rounded = (Math.round(value * 100) / 100).toFixed(2)
  return `${rounded.replace(".", ",")}% ` + postfix
}

export function brazilDateFormat(value: any): string {
  const parts = value.split("-")
  const date = new Date(parts[0], parts[1] - 1, parts[2])
  const dateTimeFormat = new Intl.DateTimeFormat("pt-BR", { year: "numeric", month: "short", day: "2-digit" })
  const formatToParts = dateTimeFormat.formatToParts(date)
  const [{ value: day }, , { value: month }, , { value: year }] = formatToParts
  const formattedMonth =  month.toUpperCase()
  const formatDelimiter = "-"
  return `${day}${formatDelimiter}${formattedMonth.replace(".", "")}${formatDelimiter}${year}`
}
