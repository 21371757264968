import { client } from "@/api/secure-service"

export interface AccountDetails {
	quotes: null|Quote[];
	loans: null|Loan[];
	loan_applications: null|LoanApplication[];
	payment_data: null|PaymentData;
	eligible_products: null|ProductEligibility;
	activities: null|LPActivityData[];
	nearest_business_day: null|string;
	next_business_day: null|string;
	ebs_metadata: null|EbsMetadata;
  bank_account: null|BankAccount;
}

export interface BankAccount {
  routing_number: null|string;
  account_number: null|string;
  account_type: null|string;
  current: null|boolean;
  payment_gateway_uri: null|string;
  options: null|Uint32Array;
  stats: null|Uint32Array;
  latest_bank_history_update: null|string;
  bank_identification_number: null|string;
  bank_branch: null|string;
}

export interface EbsMetadata {
	has_belvo_ebs: null|boolean;
  has_klavi_ebs: null|boolean;
}

export interface WhatsAppConsent {
  consent_agreement_id: string;
  consent_id: string;
  identity_account_id: number;
  consent_ip: string;
  created_at: string;
  updated_at: string;
  agreed: boolean;
}

export interface LPActivityData {
  name: string;
  status: string;
  reason: null|string;
}

export interface Quote {
  InstallmentAmount: number;
  FirstInstallmentDueDate: string;
  LastInstallmentDueDate: string;
  InstallmentsRequested: number;
  APR: number;
  CET: number;
  IOF: number;
  InterestRate: number;
  GrossAmount: number;
  APRate: number;
  TableCode: string;
  AmountRequested: number;
  Fee: number;
  StartDate: string;
	MonthlyCET: number;
}
export interface Balances {
	outstanding: string;
	current: string;
	past_due: string;
	called_due: string;
	due: string;
	charged_off: string;
	principal_current: string;
}
export interface InterestRateHistory {
	interest_rate: string;
	effective_start_date: string;
}
export interface BorrowerData {
	full_name: string;
	address_line_1: string;
	address_line_2: string;
	city: string;
	postal_code: string;
}
export interface Contract {
	contract_id: number;
	loan_agreement_id: number;
	created_at: string;
	signature_date: string;
	signed: boolean;
	borrower_data: BorrowerData;

}
export interface InstallmentRate {
	rate_type: string;
	rate_value: string;
}
export interface LoanStatusReason {
	// the documentation for this type is nebulous
	reason: any;
}
export interface LoanStatusHistory {
	reason: LoanStatusReason;
	created_at: string;
	loan_status_history_id: number;
	from_status: string;
	to_status: string;
}
export interface LegalEntity {
	legal_entity_id: number;
	legal_entity: string;
	external: boolean;
}
export interface ParticipationPurchase {
	loan_id: number;
	percentage: string;
	effective_date: string;
	from_entity: LegalEntity;
	to_entity: LegalEntity;
	payment_id: number;
	cost: string;
	created_at: string;
	updated_at: string;
}
export interface OwnershipTransfer {
	cost: string;
	loan_id: number;
	percentage: string;
	effective_date: string;
	from_entity: string;
	to_entity: string;
	payment_id: number;
	created_at: string;
	updated_at: string;
}
export interface Product {
	brand: string;
	product: string;
	product_type: string;
}
export interface Country {
	countryId: number;
	country: string;
	isoCode: string;
}
export interface Region {
	regionId: number;
	region: string;
	abbr: string;
	country: Map<string, Country>;
}
export interface ArchivalInfo {
	archived_at: string;
	reason: string;
}
export interface Obligation {
	obligation_id: number;
	obligation_uuid: string;
	loan_id: number;
	created_at: string;
	updated_at: string;
	original_amount: string;
	amount: string;
	original_due_date: string;
	due_date: string;
	paid_off_amount: string|null;
	paid_off_date: string;
	archival_info: ArchivalInfo|null;
}
export interface RefinanceHistory {
	refinance_history_id: number;
	base_loan_id: number;
	refinance_from_loan_id: number;
	refinance_to_loan_id: number;
	refinance_type: string;
	created_at: string;
}
export interface ScheduledPayment {
	scheduled_payment_id: number;
	loan_id: number;
	created_at: string;
	updated_at: string;
	generated_by_system: boolean;
	amount: string;
	original_amount: string;
	payment_method: string;
	archival_info: ArchivalInfo;
	re_presentment_of_id: number;
	original_due_date: string;
	due_date: string;
	target_date: string;
	obligation_id: number;
}
export interface AssessedFee {
	fee_type: string;
	amount: string;
	scheduled_payment_id: number;
	assessed_for_scheduled_payment_id: number;
	target_date: string;
}
export interface LoanOffer {
	loan_offer_id: number;
	created_at: string;
	updated_at: string;
	starts_at: string;
	expires_at: string;
	product: Product;
	origination_fee_percent: string;
	draw_fee_percent: number;
	finance_charge: string;
	max_amount: boolean;
	min_amount: string;
	target_amount: string;
	total_repayment_amount: string;
	interest_multiplier: string;
	interest_rate: string;
	is_consolation: boolean;
	suitable: boolean;
	reason_unsuitable: string;
	loan_length: number;
	num_installments: number;
	tax_amount: string;
	adjusted_interest_rate: string;
	annualized_nominal_interest_rate: string;
	annualized_adjusted_interest_rate: string;
	installment_amount: string;
	fee_amount: string;
	first_installment_date: string;
}
export interface LoanAgreement {
	loan_agreement_id: number;
	loan_number: string;
	account_id: number;
	created_at: string;
	updated_at: string;
	servicer: string;
	amount: string;
	calculated_recurring_amount: string;
	disbursement_amount: string;
	refinance_amount: string;
	installment_rate: InstallmentRate;
	funding_method: string;
	repayment_method: string;
	lender: string;
	loan_offer: LoanOffer;
	basis_period: number;
	statement_day_of_month: number;
	unit_period: number;
	loan_application_id: number;
	contract: Contract;
}
export interface Loan {
	loan_id: number;
	loan_number: string;
	account_id: number;
	created_at: string;
	updated_at: string;
	funding_method: string;
	lender: string;
	loan_status: string;
	loan_status_history: LoanStatusHistory[];
	selected_repayment_schedule_id: number;
	region: Region;
	servicer: string;
	amount: string;
	disbursement_amount: string;
	interest_cap: string;
	interest_rate: string;
	interest_rate_history: InterestRateHistory[];
	longest_representment_streak: number;
	disbursement_date: string;
	finance_charge_start_date: string;
	is_open: boolean;
	is_purchased: boolean;
	refinance_history: RefinanceHistory;
	payments: LoanPayment[];
	product: Product;
	repayment_method: string;
	loan_agreement: LoanAgreement;
	obligations: Obligation[];
	scheduled_payments: ScheduledPayment[];
	assessed_fees: AssessedFee[];
	ownership_transfers: OwnershipTransfer[];
	participation_purchases: ParticipationPurchase[];
	balances: Balances;
	payment_plans: PaymentPlan[];
	draw_locks: DrawLock[];
	accounting_activities: AccountingActivity[];
	draw_ineligibility_reason: string;
	last_called_due_date: string;
}
export interface PaymentPlan {
	payment_plan_id: number;
	loan_id: number;
	total_repayment_amount: string;
	recurring_payment_amount: string;
	number_of_payments: number;
	payment_method: string;
	schedule_frequency: string;
	payment_plan_type: string;
	start_date: string;
	end_date: string;
	is_settlement: boolean;
	payment_arrangement_status: string;
	created_at: string;
	updated_at: string;
	created_for_scheduled_payment_id: number;
	payment_plan_uuid: string;
}
export interface DrawLock {
	draw_lock_id: number;
	loan_id: number;
	start_date: string;
	end_date: string;
	created_at: string;
	updated_at: string;
}
export interface AccountingActivity {
	activity_id: number;
	activity_type: string;
	cancels_activity_id: number;
	payment_id: number;
	accounting_date: string;
	effective_date: string;
	cancelled_date: string;
	activity_amount: string;
}
export interface LoanPurpose {
	loan_purpose_id: number;
	loan_purpose: string;
	updated_by: null|string;
}
export interface ModelResult {
	answer: string;
	created_at: string;
	updated_at: string;
	loan_application_id: number;
	product: Map<string, Product>;
	model_result_id: number;
	model_name: string;
	model_run_source: string;
	model_run_uuid: string;
	all_results: JSON;
	inputs: JSON;
}
export interface Preapproval {
	loan_offer_id: number;
	created_at: string;
	updated_at: string;
	starts_at: string;
	expires_at: string;
	product: Product;
	origination_fee_percent: string;
	draw_fee_percent: string;
	finance_charge: string;
	max_amount: string;
	min_amount: string;
	target_amount: string;
	total_repayment_amount: string;
	interest_multiplier: string;
	interest_rate: string;
	is_consolation: boolean;
	suitable: boolean;
	reason_unsuitable: string;
	loan_length: number;
	num_installments: number;
	tax_amount: string;
	adjusted_interest_rate: string;
	annualized_nominal_interest_rate: string;
	annualized_adjusted_interest_rate: string;
	installment_amount: string;
	fee_amount: string;
	first_installment_date: string;
}
export interface LoanApplicationStateTransition {
	event: string;
	created_at: string;
	from_status: string;
	to_statis: string;
}
export interface UnderwritingDecision {
	underwriting_decision_id: number;
	loan_application_id: number;
	underwriting_decision_status: string;
	underwriter_name: string;
	created_at: string;
	updated_at: string;
	earliest_disbursement_date: string;
	latest_disbursement_date: string;
	finance_charge_start_date: string;
	model_results: ModelResult[];
	product: Product;
	reasons: string[];
	superseded_at: string;
}
export interface LoanApplication {
	loan_application_id: number;
	loan_number: string;
	account_id: number;
	created_at: string;
	updated_at: string;
	selected_repayment_schedule_id: number;
	brand: string;
	channel: string;
	loan_purpose: LoanPurpose;
	underwriting_decisions: UnderwritingDecision[];
	refinance_for_loan_id: number;
	refinance_type: string;
	status: string;
	intent: string;
	source: string;
	requested_loan_amount: number;
	requested_loan_duration: number;
	loan_offers: LoanOffer[];
	preapprovals: Preapproval[];
	visit_id: number;
	loan_application_state_transitions: LoanApplicationStateTransition[];
	loan_agreement_loan_offer_id: number;
}
// loan.payments payment type
export interface LoanPayment {
	payment_id: number;
	loan_id: number;
	direction: string;
	amount: string;
	payment_method: string;
	payment_status: string;
	payment_purpose: string;
	reimbursment_reason: string|null;
	obligation_id: number;
	effective_date: string;
	settlement_date: string|null;
	created_at: string;
	updated_at: string;
	payment_uuid: string;
}
// sorcerer payment  type
export interface Payment {
	payment_id: number;
	created_by_payments_archive_id: number;
	created_at: string;
	updated_by_payments_archive_id: number;
	updated_at: string;
	operation_number: string;
	installment_number: string;
	control_number: string;
	payment_amount: string;
	principal_amount: string;
	interest_amount: string;
	valor_apropriar: string;
	post_default_interest: string;
	post_default_extra_interest_and_fee: string;
	post_default_extra_tax: string;
	expenses_amount: string;
	interest_rate: string;
	total_installment_amount_accrued: string;
	interest_curve: string;
	payment_kind: string;
	installment_due_date: string;
	installment_amount: string;
	discount_amount: string;
	remaining_balance: string;
	amount_paid: string;
	accounting_code: string;
	recipient_location_code: string;
	transaction_user_code: string;
	transaction_user_name: string;
	payment_date: string;
	payment_type: string;
	current_installment_status: string;
	days_in_default: number;
	has_payment_plan: string;
	payment_plan_type: null|string;
	outstanding_balance: string;
	discount_rate: string;
	payments_pull_id: number;
	loan_application_id: number;
	payment_type_description: string;
	recipient_location_description: string;
}
export interface PaymentData {
	paid_installments: Payment[];
	unpaid_installments: Payment[];
	due_installments: Payment[];
	unpaid_due_installments: Payment[];
	loan_balance: string;
	amount_paid: string;
	num_installments: number;
	most_recent_payment_date: string;
	acceptable_payments_count: number;
	max_days_in_default: number;
	early_payoff_installment_number: null|number;
	num_of_d10_ontime_paid_installments: number;
	num_of_d30_ontime_paid_installments: number;
	installment_amount: string;
}
export interface ProductEligibility {
	eligible_products: string[];
	future_eligible_products: string[];
  eligible_offers?: EligibleOffers;
}

export interface EligibleOffers {
  lower_my_payment?: EligibleOffer | null;
  top_up_refinance?: EligibleOffer | null;
}

export interface EligibleOffer {
  eligible_product_details: EligibleProductDetails;
  quotes: Quote[];
}

export interface EligibleProductDetails {
  loan_balance: number;
  current_installment_amount: number;
  paid_amount: number;
}

export interface ResourceInformation {
	title: null|string;
	body: null|string;
	link: null|string;
}
export interface PaymentCardDetails {
	date: null|string;
	status: null|string;
	installment_amount: null|string;
}
export interface InstallmentInformation {
	payment: Payment;
	status: null|string;
}

export interface LoanHistoryInfo {
	paid_off_date: string|null;
	loan_app_id: number|null;
	loan_amount: string|null;
	created_at: string|null;
	final_due_date: string|null;
}

export interface UpdateEmailRequest {
  current_email_address: string;
  new_email_address: string;
}

export interface UpdateEmailResponse {
  status: string;
  message: string;
}

export interface UpdateProfileResponse {
  status: string;
  message: string;
  errors: string;
}

export interface RefinanceRequest {
  quote: Quote;
  direct_debit_opt_in: boolean;
  overdraft_opt_in: boolean;
}

export interface WhatsAppConsentResponse {
  consent_agreement: WhatsAppConsent;
}

export interface UpdateWhatsAppConsentResponse {
  status: number;
}

export interface WhatsAppConsentRequest {
  consent_text: string;
}

export interface WhatsAppPublicUpdateRequest {
  token: string;
}

export interface WhatsAppDisplayDetailsResponse {
	partial_phone: string;
	partial_cpf: string;
}

export interface BankAccountFields {
  account_number: string;
  bank_name: string;
  bank_branch: string;
  account_type: string;
  caixa_account_type: string;
}

export interface UpdateProfileRequest {
  pay_date?:         string;
  secondary_phone?: string;
  monthly_income?:  string;
  current_email:    string;
  new_email?:       string;
  bank_account?: BankAccountFields;
}

export async function getAccountDetails (accountId: string): Promise<AccountDetails> {
  try {
    const response = await client().get<AccountDetails>(`/account-home/account/${accountId}/details`)
    return response as AccountDetails
  } catch(err) {
    return Promise.reject(err)
  }
}

export async function updateProfile(accountID: string, currentEmail: string, newEmail: string, paydate: string,
  bankAccount: BankAccountFields, secondaryPhone: string, monthlyIncome: string) {
  const payload: UpdateProfileRequest = {
    "current_email": currentEmail,
    "new_email": newEmail,
    "pay_date": paydate,
    "bank_account": bankAccount,
    "secondary_phone": secondaryPhone,
    "monthly_income": monthlyIncome
  }
  try {
    const response = await client().put(`/account-home/account/${accountID}/update_profile`, payload)
    return response as UpdateProfileResponse
  } catch(e) {
    return Promise.reject(e.response.data as UpdateProfileResponse)
  }
}

export async function createLowerMyPaymentLoanAgreement(accountID: string, quote: Quote, debitOptIn: boolean, overdraftOptIn: boolean): Promise<LoanAgreement> {
  const payload: RefinanceRequest = { "quote": quote, "direct_debit_opt_in": debitOptIn, "overdraft_opt_in": overdraftOptIn }
  try {
    const response = await client().post(`/account-home/account/${accountID}/lower_my_payment`, payload)
    return response as LoanAgreement
  } catch(e) {
    return Promise.reject(e)
  }
}

export async function createTopUpRefiLoanAgreement(accountID: string, quote: Quote, debitOptIn: boolean, overdraftOptIn: boolean): Promise<LoanAgreement> {
  const payload: RefinanceRequest = { "quote": quote, "direct_debit_opt_in": debitOptIn, "overdraft_opt_in": overdraftOptIn }
  try {
    const response = await client().post(`/account-home/account/${accountID}/top_up_refinance`, payload)
    return response as LoanAgreement
  } catch(e) {
    return Promise.reject(e)
  }
}

export async function updateWhatsAppConsent (accountID: number): Promise<UpdateWhatsAppConsentResponse> {
  try {
    const response = await client().put(`/account-home/account/${accountID}/whatsapp/update_consent`, null)
    return response as UpdateWhatsAppConsentResponse
  } catch(err) {
    return Promise.reject(err)
  }
}

export async function getWhatsAppConsent (accountId: string): Promise<WhatsAppConsent> {
  try {
    const response: WhatsAppConsentResponse = await client().get<WhatsAppConsentResponse>(`/account-home/account/${accountId}/whatsapp/get_consent`)

    return response.consent_agreement
  } catch (err) {
    return Promise.reject(err)
  }
}

export async function publicUpdateWhatsAppConsent (token: string): Promise<UpdateWhatsAppConsentResponse> {
  const payload: WhatsAppPublicUpdateRequest = { "token": token }
  try {
    const response = await client().put("/account-home/public/account/whatsapp/update_consent", payload)
    return response as UpdateWhatsAppConsentResponse
  } catch(e) {
    return Promise.reject(e)
  }
}

export async function getWhatsAppDisplayDetails (token: string): Promise<WhatsAppDisplayDetailsResponse> {
  try {
    const response = await client().get(`/account-home/public/account/${token}/whatsapp/display_details`)
    return response as WhatsAppDisplayDetailsResponse
  } catch (err) {
    return Promise.reject(err)
  }
}
