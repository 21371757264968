











































/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent, Ref, ref, onMounted } from "@vue/composition-api"
import tagRecordings from "@/plugins/hotjar/tagRecordings"
import { VueWithHotjar } from "@/types"
import { useAccountDetailsStore } from "@/store"

export default defineComponent({
  name: "PastLoanDetailsCard",
  props: {
    previousLoan: {
      type: Object,
      required: false
    }
  },
  setup(props, { root }) {
    const { loans } = useAccountDetailsStore()
    const loanHistoryPath: Ref<string> = ref("/loan-history")
    const displayButton: Ref<boolean> = ref(false)

    const convertDate = (input: string) => {
      const date = new Date(input)
      const dateString = date.toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" })
      return dateString.substr(0, 10)
    }

    const displayButtonLogic = () => {
      if (loans != null && loans.value) {
        for(let i = 0; i < loans.value.length; i++) {
          if ((loans.value[i].loan_status === "paid_off" || loans.value[i].loan_status === "sold") && (loans.value[i].payments == null || loans.value[i].obligations == null || loans.value[i].payments.length < 1 || loans.value[i].obligations.length < 1)) {
            displayButton.value = false
            return
          }
        }
      }
      displayButton.value = true
    }

    onMounted(() => {
      displayButtonLogic()
      if (props.previousLoan) {
        tagRecordings(root as VueWithHotjar, ["past_loan_details_card_displayed"])
      }
    })

    return {
      loanHistoryPath,
      convertDate,
      displayButton
    }
  }
})
