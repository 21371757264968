import { buildHelpers } from "@/store/composition-helpers"
import store, { State } from "@/store"
import { WritableComputedRef } from "@vue/composition-api"
import { updateField, getField } from "vuex-map-fields"
import { Module } from "vuex"
import { logError } from "@/plugins/sentry"
import {
  AccountDetails,
  Quote,
  Loan,
  LoanApplication,
  PaymentData,
  getAccountDetails,
  ProductEligibility,
  LPActivityData, WhatsAppConsent, getWhatsAppConsent, EbsMetadata, BankAccount
} from "@/api/secure/account-home-service"

const { mapGettersAndSettersToComputed } = buildHelpers<AccountDetailsStore>(
  () => store, "accountDetails"
)
interface AccountDetailsStore {
  quotes: null|Quote[];
  loans: null|Loan[];
  loanApplications: null|LoanApplication[];
  paymentData: null|PaymentData;
  eligibleProducts: null|ProductEligibility;
  activities: null|LPActivityData[];
  nearestBusinessDay: null|string;
  nextBusinessDay: null|string;
  whatsAppConsent: null|WhatsAppConsent;
  ebsMetadata: null|EbsMetadata;
  bankAccount: null|BankAccount;
}

export const newAccountDetailsState = (): AccountDetailsStore => {
  return {
    quotes: null,
    loans: null,
    loanApplications: null,
    paymentData: null,
    eligibleProducts: null,
    activities: null,
    nearestBusinessDay: null,
    nextBusinessDay: null,
    whatsAppConsent: null,
    ebsMetadata: null,
    bankAccount: null
  }
}

const accountDetailsState: AccountDetailsStore = newAccountDetailsState()

interface AccountDetailsAccessors {
  quotes: WritableComputedRef<null|Quote[]>;
  loans: WritableComputedRef<null|Loan[]>;
  loanApplications: WritableComputedRef<null|LoanApplication[]>;
  paymentData: WritableComputedRef<null|PaymentData>;
  eligibleProducts: WritableComputedRef<null|ProductEligibility>;
  activities: WritableComputedRef<null|LPActivityData[]>;
  nearestBusinessDay: WritableComputedRef<null|string>;
  nextBusinessDay: WritableComputedRef<null|string>;
  whatsAppConsent: WritableComputedRef<null|WhatsAppConsent>;
  ebsMetadata: WritableComputedRef<null|EbsMetadata>;
  bankAccount: WritableComputedRef<null|BankAccount>;
  getDetails: (accountId: string) => Promise<void>;
  isEmpty: () => boolean;
  hasWhatsAppConsent: (accountId: string) => Promise<boolean>;
}

function useAccountDetailsStore(): AccountDetailsAccessors {
  const mapFields = mapGettersAndSettersToComputed(Object.keys(accountDetailsState) as Array<keyof AccountDetailsStore>)
  const { quotes, loans, loanApplications, paymentData, eligibleProducts, activities, nearestBusinessDay, nextBusinessDay, whatsAppConsent, ebsMetadata, bankAccount } = mapFields

  const hasWhatsAppConsent = async (accountId: string): Promise<boolean> => {
    try {
      if (!whatsAppConsent.value) {
        whatsAppConsent.value = await getWhatsAppConsent(accountId)
      }

      return whatsAppConsent.value.agreed
    } catch (err) {
      logError(`Error fetching whatsapp consent form AHS - ${err}`)
      return false
    }
  }

  const getDetails = async (accountId: string) => {
    try {
      const details: AccountDetails = await getAccountDetails(accountId)
      quotes.value = details.quotes
      loans.value = details.loans
      loanApplications.value = details.loan_applications
      paymentData.value = details.payment_data
      eligibleProducts.value = details.eligible_products
      activities.value = details.activities
      nearestBusinessDay.value = details.nearest_business_day
      nextBusinessDay.value = details.next_business_day
      ebsMetadata.value = details.ebs_metadata
      bankAccount.value = details.bank_account
      return details
    } catch(e) {
      logError(`Error fetching account details from AHS - ${e}`)
      return
    }
  }

  const isEmpty = () => {
    return !(quotes.value || loans.value || loanApplications.value || paymentData.value || eligibleProducts.value || activities.value || nearestBusinessDay.value || nextBusinessDay.value)
  }

  return {
    ...mapFields,
    getDetails,
    isEmpty,
    hasWhatsAppConsent
  } as AccountDetailsAccessors
}

const accountDetails: Module<AccountDetailsStore, State> = {
  namespaced: true,
  state: accountDetailsState,
  getters: { getField },
  mutations: { updateField }
}

export default accountDetails
export { accountDetailsState, useAccountDetailsStore, AccountDetailsStore, AccountDetailsAccessors }
