















/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from "@vue/composition-api"

export default defineComponent({
  name: "ResourceCard",
  props: {
    title: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    }
  }
})
