






























































/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from "@vue/composition-api"
import PaymentPlanOffersTooltip from "@/components/payment-plans/OffersTooltip.vue"
import { useAccountStore, usePaymentPlansStore } from "@/store"
import { PaymentPlanOffer } from "@/api/secure/sysopen-gateway-service"
import { postUniversalMetricsEvent } from "@/api/secure"
export default defineComponent({
  name: "PaymentPlanOffersCard",
  props: {
    offer: {
      type: Object,
      required: true
    },
    singleOffer: {
      type: Boolean,
      required: true
    }
  },
  components: { PaymentPlanOffersTooltip },
  setup(_, { emit }) {
    const { accountID } = useAccountStore()
    const { withDownPayment } = usePaymentPlansStore()

    const onSelectOffer = (offer: PaymentPlanOffer) => {
      emit("offer-selected", offer)
      postUniversalMetricsEvent(accountID.value, "selected_an_offer")
    }
    const onMouseover = () => {
      emit("mouseover")
    }
    const onMouseleave = () => {
      emit("mouseleave")
    }
    const downPaymentAmount = (offer: PaymentPlanOffer) => {
      return offer.installment_amount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
    }
    const installmentAmount = (offer: PaymentPlanOffer) => {
      if (!withDownPayment.value) {
        return offer.installment_amount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
      }
      if (offer.number_of_installments === 1) {
        return offer.installment_amount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
      }
      const remainingPaymentsAmount = (offer.total_amount - offer.installment_amount) / (offer.number_of_installments - 1)
      if (Math.abs(remainingPaymentsAmount - offer.installment_amount) > 0.1) {
        return remainingPaymentsAmount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
      }
      return offer.installment_amount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
    }

    return {
      onSelectOffer,
      onMouseover,
      onMouseleave,
      downPaymentAmount,
      installmentAmount
    }
  }
})
