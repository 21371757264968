<template>
  <brazil-v-card
    :outlined="active"
    :raised="active"
    :active="active">
    <v-container>
      <v-row class="text-center">
        <v-col cols="12">
          <div class="details-text">{{ $t("installments") }}</div>
          <div class="details-data">{{ quote.InstallmentsRequested }} {{ $t("installment") }} </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="hr-padding">
          <hr />
        </v-col>
      </v-row>
      <v-row class="text-center">
        <v-col cols="12">
          <div class="details-text">{{ $t("firstDueDate") }}</div>
          <div :id="getId('date')" class="date details-data">
            {{ formattedFirstDueDate() }}
          </div>
        </v-col>
      </v-row>
      <v-row class="text-center">
        <v-col cols="12">
          <div>
            <button
              :id="getId('button')"
              :disabled="controlsDisabled"
              type="button"
              @click="buttonClicked">
              <span id="continue-text-span">{{ $t('quoteDetails') }}</span>
              <span id="arrow-img-span"><img aria-labelledby="continue-text-span" :alt="$t('arrowIcon')" src="~@/assets/arrow-back-gray.svg" /></span>
              <v-dialog
                v-model="showQuoteDetails"
                activator="parent"
                max-width="600px">
                <v-card>
                  <lower-my-payment-quote-details :quote="quote" :product-details="productDetails" />
                </v-card>
              </v-dialog>
            </button>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </brazil-v-card>
</template>

<script>
import { defineComponent, ref } from "@vue/composition-api"
import BrazilVCard from "@/components/account-home/BrazilVCard.vue"
import BrazilVBtn from "@/components/account-home/BrazilVBtn.vue"
import { EligibleProductDetails, Quote } from "@/api/secure/account-home-service"
import LowerMyPaymentQuoteDetails from "@/components/account-home/LowerMyPaymentQuoteDetails.vue"

export default defineComponent({
  components: {
    LowerMyPaymentQuoteDetails,
    BrazilVBtn, BrazilVCard
  },
  props: {
    productDetails: {
      type:     EligibleProductDetails,
      required: true
    },
    quote: {
      type:     Quote,
      required: true
    },
    active: {
      type:    Boolean,
      default: false
    },
    controlsDisabled: {
      type:    Boolean,
      default: false
    }
  },
  setup() {
    const showQuoteDetails = ref(false)
    const buttonClicked = function() {
      showQuoteDetails.value = true
      this.$emit("buttonClicked", this.quote)
    }

    // Formats the date into a dd-MMM-yyyy format. E.g. 01-MAR-2023. The expected input format
    // is yyyy-MM-dd, E.g. 2023-03-01.
    const brazilDateFormat = function(value, options = null) {
      const delimiter = options?.hasOwnProperty("delimiter") && !options.delimiter?.isEmpty() ? options.delimiter : "-"

      const parts = value.split("-")
      const date = new Date(parts[0], parts[1] - 1, parts[2])
      const dateTimeFormat = new Intl.DateTimeFormat("pt-BR", {
        year: "numeric",
        month: "short",
        day: "2-digit"
      })

      const formatToParts = dateTimeFormat.formatToParts(date)
      const [{ value: day }, , { value: month }, , { value: year }] = formatToParts
      const formattedMonth = month.toUpperCase()

      return `${day}${delimiter}${formattedMonth.replace(".", "")}${delimiter}${year}`
    }

    const formattedFirstDueDate = function() {
      return brazilDateFormat(this.quote.FirstInstallmentDueDate)
    }

    const getId = function(element) {
      return "quote-details-" + element + "-" + this.quote.InstallmentsRequested
    }

    return {
      formattedFirstDueDate,
      buttonClicked,
      getId,
      showQuoteDetails
    }
  }
})
</script>
<style lang="scss" scoped>
hr {
  margin-top: 2px;
  margin-bottom: 2px;
  border: 1px solid $simplic-outline-gray;
}
.hr-padding {
  padding-top: 0;
  padding-bottom: 0;
}
.brazil-card .installments {
  padding-left: 12px;
}
.brazil-card .container {
  padding: 12px;
}
.installments, .amount {
  color: $simplic-green;
  font-size: 2.4em;
}
.details-text {
  font: normal normal normal 14px Open Sans;
  color: $simplic-dark-text;
}
.details-data {
  font: normal normal bold 24px Open Sans;
  color: $simplic-dark-text;
}
.col-12 {
  padding: 4px 12px;
}
.no-bottom-padding .col {
  padding-bottom: 0;
}
button {
  background-color: $simplic-yellow;
  padding: 10px;
  margin: 20px auto;
  width: 90%;
  border-radius: 8px;

  #continue-text-span {
    font: normal normal bold 16px Open Sans;
    float: left;
    color: $simplic-dark-text;
  }

  #arrow-img-span {
    float: right;
    padding-top: 3px;
  }
}
@media screen and (max-width: 700px) {
  .amount {
    font-size: 1.5em;
  }
  .installments {
    font-size: 2em;
  }
}
</style>
<i18n>
{
  "pt": {
    "installments": "Quantidade de parcelas atualizadas",
    "installment": "parcelas",
    "quoteDetails": "Contratar Agora",
    "firstDueDate": "Próxima Data de Vencimento",
    "arrowIcon": "Contratar"
  }
}
</i18n>
