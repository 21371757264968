/* eslint-disable @typescript-eslint/camelcase */

import { JWTStorage } from "@/api/types/index"
import { useCookieStore } from "@/store"

class CookieJWTStorage implements JWTStorage {
  public getAccessToken(): string {
    const { accessTokensData } = useCookieStore()
    return accessTokensData.value.access_token ?? ""
  }

  public getRefreshToken(): string {
    const { accessTokensData } = useCookieStore()
    return accessTokensData.value.refresh_token ?? ""
  }

  public saveTokens(accessToken: string, refreshToken: string): void {
    const { accessTokensData, updateAccessTokens } = useCookieStore()

    accessTokensData.value.refresh_token = refreshToken
    accessTokensData.value.access_token = accessToken
    updateAccessTokens()
  }

  public expire(): void {
    useCookieStore().expireAccessTokens()
  }
}

export default new CookieJWTStorage()
