













import { computed, defineComponent, ref } from "@vue/composition-api"
import { Datepicker } from "@enova-ui/components"
import moment from "moment"
import { InputValidationRules } from "vuetify"
import VueI18n from "@/plugins/i18n"

export default defineComponent({
  name: "SimplicDatepicker",
  props: {
    value: {
      type: String,
      required: true
    },
    minDaysFromNow: {
      type: Number,
      required: false
    },
    maxDaysFromNow: {
      type: Number,
      required: false
    },
    id: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    weekdaysOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: { Datepicker },
  setup(props, { emit }) {
    const model = props.value
    const weekdaysOnly = props.weekdaysOnly

    const allowedDates = (date: string) => {
      if (!weekdaysOnly) {
        return true
      }

      const d = moment(date)

      return ![0, 6].includes(d.weekday())
    }


    const validate = (isoDate: string) => {
      const date = moment(isoDate)
      const today = moment.now()
      const daysFromToday = date.diff(today, "days")

      if (props.minDaysFromNow !== undefined) {
        if (daysFromToday < props.minDaysFromNow!) {
          return false
        }
      }

      if (props.maxDaysFromNow !== undefined) {
        if (daysFromToday > props.maxDaysFromNow!) {
          return false
        }
      }

      return true
    }

    const valid = ref(validate(model))
    const onInput = (newVal: string) => {
      valid.value = validate(newVal)
      emit("input", newVal)
    }

    const datePickerRules = computed(() => {
      const rules: InputValidationRules = []

      if(props.minDaysFromNow !== undefined) {
        rules.push((v: string) => {
          const date = moment(v)
          const today = moment.now()
          const days = date.diff(today, "days")

          if(days < props.minDaysFromNow!) {
            return VueI18n.t("tooEarly").toString()
          } else {
            return true
          }
        })
      }

      if(props.maxDaysFromNow !== undefined) {
        rules.push((v: string) => {
          const date = moment(v)
          const today = moment.now()
          const days = date.diff(today, "days")

          if(days > props.maxDaysFromNow!) {
            return VueI18n.t("tooLate", {
              n: props.maxDaysFromNow
            }).toString()
          } else {
            return true
          }
        })
      }

      return rules
    })

    return {
      valid,
      model,
      onInput,
      datePickerRules,
      allowedDates
    }
  }
})
