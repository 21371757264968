


















































/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent, onMounted, Ref, ref } from "@vue/composition-api"
import { useLPStore, useAccountDetailsStore } from "@/store"

export default defineComponent({
  name: "LoanAppStatusDetailsCard",
  props: {
    loanAppCardView: {
      type: String,
      required: true
    },
    showLoanAppCard: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const { loanApplications, activities } = useAccountDetailsStore()
    const loanAppCreatedAt: Ref<string> = ref("")

    const getLoanAppCreatedAt = () => {
      if (loanApplications && loanApplications.value) {
        const date = loanApplications.value[0].created_at
        const formatDate = new Date(date)
        const dateString = formatDate.toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" })
        loanAppCreatedAt.value = dateString.substr(0, 10)
      }
    }

    onMounted(() => {
      getLoanAppCreatedAt()
    })

    return {
      loanAppCreatedAt,
      activities
    }
  }
})
