














import { defineComponent, ref } from "@vue/composition-api"
export default defineComponent({
  name: "Legal",
  setup() {
    const open = ref(false)

    const toggle = () => {
      open.value = !open.value
    }

    return {
      open,
      toggle
    }
  }
})
