








import MobileFooter from "./MobileFooter.vue"
import DesktopFooter from "./DesktopFooter.vue"
import { defineComponent } from "@vue/composition-api"
import TagManagerFooter from "@/components/footer/TagManagerFooter.vue"

export default defineComponent({
  name: "SimplicFooter",
  components: { TagManagerFooter, MobileFooter, DesktopFooter }
})
