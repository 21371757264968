
import { computed, defineComponent, ref, watch } from "@vue/composition-api"
import BrazilVTextField from "@/components/input/BrazilVTextField.vue"

export default defineComponent({
  name: "NumberSelector",
  components: { BrazilVTextField },
  emits: ["select"],
  props: {
    start: {
      type: Number,
      required: true
    },
    end: {
      type: Number,
      required: true
    },
    edited: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: String,
      required: false,
      default: ""
    },
    label: {
      type: String,
      required: false,
      default: "Pick a number"
    },
    rules: {
      type: Array,
      required: false,
      default: () => []
    },
    prependIcon: {
      type: String,
      required: false,
      default: ""
    },
    prependIconEdit: {
      type: String,
      required: false,
      default: ""
    }
  },
  setup(props, { emit }) {
    const initialValue = ref<string>(props.value)
    const currentSelection = ref<string>(props.value)
    const isEdited = ref<boolean>(false)
    const items = []

    for (let i = props.start; i <= props.end; i++) {
      items.push({ name: i, value: i.toString() })
    }

    watch(() => props.edited, (newValue) => {
      isEdited.value = newValue
      if (!newValue) initialValue.value = currentSelection.value
    })

    const onChange = (value: string) => {
      isEdited.value = value !== initialValue.value
      currentSelection.value = value
      emit("select", value, isEdited.value)
    }

    const prependIconValue = computed(() => {
      if (props.prependIconEdit !== "") {
        return isEdited.value ? props.prependIconEdit : props.prependIcon
      }

      return props.prependIcon
    })

    return {
      items,
      onChange,
      prependIconValue,
      currentSelection
    }
  }
})
