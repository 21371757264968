import { BaseValidator, ValidatorArguments } from "./base"

export interface PhoneMinLengthValidatorArguments extends ValidatorArguments {
  length: number;
}

export class PhoneMinLengthValidator extends BaseValidator {
  static validate(args: PhoneMinLengthValidatorArguments): boolean | string {
    if (!args.value) {
      return false
    }
    const number = args.value.toString().replace(/\D/g, "")
    return (
      !PhoneMinLengthValidator.required(number) ||
      PhoneMinLengthValidator.len(number) >= args.length ||
      args.message
    )
  }
}