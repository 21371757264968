































import { defineComponent } from "@vue/composition-api"
import { useSessionStore } from "@/store"

export default defineComponent({
  name: "DesktopHeaderUnAuth",
  setup() {
    const { logIn } = useSessionStore()

    return {
      logIn
    }
  }
})
