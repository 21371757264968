













/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent, onMounted, Ref, ref, watch } from "@vue/composition-api"

export default defineComponent({
  name: "BrazilVSlider",
  props: {
    step: {
      type:     Number,
      required: true
    },
    min: {
      type:     Number,
      required: true
    },
    max: {
      type:     Number,
      required: true
    },
    value: {
      type:     Number,
      required: true
    }
  },
  setup(props, context) {
    const sliderValue: Ref<number> = ref(0)

    const valueChanged = (v: any) => {
      context.emit("sliderChanged", v)
    }

    watch(() => sliderValue.value, (newVal) => {
      valueChanged(newVal)
    })

    onMounted(() => {
      sliderValue.value = props.value
    })

    return {
      sliderValue,
      valueChanged
    }
  }
})
