







































/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent, Ref, ref, onMounted } from "@vue/composition-api"
import { useAccountDetailsStore } from "@/store"
import tagRecordings from "@/plugins/hotjar/tagRecordings"
import { VueWithHotjar } from "@/types"

export default defineComponent({
  name: "RefinanceEligibleCard",
  setup(_, { root }) {
    const { eligibleProducts } = useAccountDetailsStore()
    const ineligible: Ref<boolean> = ref(false)
    const eligible: Ref<boolean | null> = ref(null) // eligible = true, future eligible = false
    const expand: Ref<boolean> = ref(false)
    const topUpRefiPath: Ref<string> = ref("/top-up-refinance")

    const checkEligibility = () => {
      if (eligibleProducts.value && eligibleProducts.value.eligible_products.find(e => e === "top_up_refinance")) {
        eligible.value = true
      } else {
        ineligible.value = true
      }
    }

    onMounted(() => {
      checkEligibility()

      if (!ineligible.value && eligible.value) {
        tagRecordings(root as VueWithHotjar, ["refi_eligible_card_displayed"])
      }
    })

    return {
      expand,
      eligible,
      ineligible,
      topUpRefiPath
    }
  }
})
