






import { defineComponent } from "@vue/composition-api"
export default defineComponent({
  name: "BackNavFooter",
  props: ["path"],
  setup() {
    //add
  }
})
