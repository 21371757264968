


























import { computed, defineComponent, onMounted, ref, Ref } from "@vue/composition-api"
import { useLPStore } from "@/store"
import { RetrievedDocument } from "@/api/secure"

export default defineComponent({
  name: "DocumentUpload",
  props: {
    documentType: {
      type: String,
      required: true
    }
  },
  setup (props, { emit }) {
    const { documents, addDocumentToActivity } = useLPStore()
    const files: Ref<RetrievedDocument[] | null> = ref(null)
    const dragActive = ref(false)
    const loading = ref(false)

    onMounted(() => {
      files.value = documents.value.get(props.documentType) as RetrievedDocument[]
    })

    const dragover = () => {
      dragActive.value = true
    }

    const dragleave = () => {
      dragActive.value = false
    }

    const drop = async (e: DragEvent) => {
      loading.value = true
      if (e != null) {
        const filesToUpload = e.dataTransfer?.files
        if(filesToUpload != null && filesToUpload.length > 0) {
          for (const file of filesToUpload) {
            await addDocumentToActivity(file, props.documentType)
            emit("document-uploaded", file)
          }
        }
      }
      dragActive.value = false
      loading.value = false
    }

    const uploadFile = async (e: Event) => {
      e.preventDefault
      loading.value = true
      const filesToUpload = (e.target as HTMLInputElement).files
      if(filesToUpload != null && filesToUpload.length > 0) {
        for (const file of filesToUpload) {
          await addDocumentToActivity(file, props.documentType)
          emit("document-uploaded", file)
        }
      }
      loading.value = false
    }

    return { dragover, dragleave, drop, uploadFile, dragActive, loading,
      files: computed(() => documents.value.get(props.documentType) as RetrievedDocument[])
    }
  }
})
