import { Module } from "vuex"
import store, { State } from "@/store"
import { buildHelpers } from "@/store/composition-helpers"
import { WritableComputedRef } from "@vue/composition-api"
import { updateField, getField } from "vuex-map-fields"
import { useCookieStore } from "@/store"

const { mapGettersAndSettersToComputed } = buildHelpers<AccountStore>(
  () => store, "account"
)

interface AccountStore {
  accountID: number;
  fullName: string;
  email: string;
  cpf: number;
  phoneNumber: number;
  loanApplicationStatus: string | null;
}

export const newAccountStoreState = (): AccountStore => {
  return {
    accountID: 0,
    fullName: "",
    email: "",
    cpf: 0,
    phoneNumber: 0,
    loanApplicationStatus: ""
  }
}
const accountState: AccountStore = newAccountStoreState()

interface AccountStoreAccessors {
  accountID: WritableComputedRef<number>;
  fullName: WritableComputedRef<string>;
  email: WritableComputedRef<string>;
  cpf: WritableComputedRef<number>;
  phoneNumber: WritableComputedRef<number>;
  loanApplicationStatus: WritableComputedRef<string>;
}

function useAccountStore(): AccountStoreAccessors {
  const mapFields = mapGettersAndSettersToComputed(Object.keys(accountState) as Array<keyof AccountStore>)

  mapFields.accountID.value = useCookieStore().accessTokensData.value.account_id
  mapFields.email.value = useCookieStore().accessTokensData.value.email
  mapFields.fullName.value = decodeURI(useCookieStore().accessTokensData.value.full_name)
  mapFields.cpf.value = useCookieStore().accessTokensData.value.cpf
  mapFields.phoneNumber.value = useCookieStore().accessTokensData.value.phone_number
  mapFields.loanApplicationStatus.value = useCookieStore().analyticDataLayer.value.last_loan_application_state

  return {
    ...mapFields
  } as AccountStoreAccessors
}

const account: Module<AccountStore, State> = {
  namespaced: true,
  state: accountState,
  getters: { getField },
  mutations: { updateField }
}

export default account
export { accountState, useAccountStore, AccountStore, AccountStoreAccessors }
