
export interface BankAccountInfo {
  format: BankFormatInfo;
}

export interface BankInfo {
  [key: string]: BankAccountInfo;
}

export interface BankFormatInfo {
  accountNumberLengths: number[];
}

export default {
  "1": {
    format: {
      accountNumberLengths: [9]
    }
  },
  "237": {
    format: {
      accountNumberLengths: [8]
    }
  },
  "104": {
    format: {
      accountNumberLengths: [9, 13]
    }
  },
  "341": {
    format: {
      accountNumberLengths: [6]
    }
  },
  "33": {
    format: {
      accountNumberLengths: [9]
    }
  }
} as BankInfo
