










import MobileHeaderAuth from "./MobileHeaderAuth.vue"
import MobileHeaderUnAuth from "./MobileHeaderUnAuth.vue"
import DesktopHeaderAuth from "./DesktopHeaderAuth.vue"
import DesktopHeaderUnAuth from "./DesktopHeaderUnAuth.vue"
import { defineComponent } from "@vue/composition-api"
import { useSessionStore } from "@/store"

export default defineComponent({
  name: "SimplicHeader",
  components: { MobileHeaderAuth, MobileHeaderUnAuth, DesktopHeaderAuth, DesktopHeaderUnAuth },
  setup() {
    const { isLoggedIn } = useSessionStore()

    return {
      isLoggedIn
    }
  }
})
