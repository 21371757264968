<template>
  <brazil-v-card
    :outlined="active"
    :raised="active"
    :active="active">
    <v-container>
      <v-row class="text-center">
        <v-col cols="12">
          <div class="details-text">{{ $t("installments") }}</div>
          <div class="details-data">{{ quote.InstallmentsRequested }} {{ $t("installment") }} </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="hr-padding">
          <hr />
        </v-col>
      </v-row>
      <v-row class="text-center">
        <v-col cols="12">
          <div class="details-text">{{ $t("additionalAmount") }}</div>
          <div :id="getId('date')" class="date details-data">
            {{ parseFloat(additionalAmount).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) }}
          </div>
        </v-col>
      </v-row>
      <v-row class="text-center">
        <v-col cols="12">
          <div>
            <button
              :id="getId('button')"
              type="button"
              @click="buttonClicked">
              <span id="continue-text-span">{{ $t('quoteDetails') }}</span>
              <span id="arrow-img-span"><img aria-labelledby="continue-text-span" :alt="$t('arrowIcon')" src="~@/assets/arrow-back-gray.svg" /></span>
              <v-dialog
                v-model="showQuoteDetails"
                activator="parent"
                max-width="600px">
                <v-card>
                  <refinance-quote-details :quote="quote" :product-details="productDetails" />
                </v-card>
              </v-dialog>
            </button>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </brazil-v-card>
</template>

<script>
import { defineComponent, ref } from "@vue/composition-api"
import BrazilVCard from "@/components/account-home/BrazilVCard.vue"
import BrazilVBtn from "@/components/account-home/BrazilVBtn.vue"
import { EligibleProductDetails, Quote } from "@/api/secure/account-home-service"
import RefinanceQuoteDetails from "@/components/account-home/RefinanceQuoteDetails.vue"

export default defineComponent({
  components: {
    RefinanceQuoteDetails,
    BrazilVBtn, BrazilVCard
  },
  props: {
    productDetails: {
      type:     EligibleProductDetails,
      required: true
    },
    quote: {
      type:     Quote,
      required: true
    },
    active: {
      type:    Boolean,
      default: false
    }
  },
  setup(props) {
    const additionalAmount = props.quote.AmountRequested - props.productDetails.loan_balance
    const showQuoteDetails = ref(false)
    const buttonClicked = function() {
      showQuoteDetails.value = true
      this.$emit("buttonClicked", this.quote)
    }

    const getId = function(element) {
      return "quote-details-" + element + "-" + this.quote.InstallmentsRequested
    }

    return {
      additionalAmount,
      buttonClicked,
      getId,
      showQuoteDetails
    }
  }
})
</script>
<style lang="scss" scoped>
hr {
  margin-top: 2px;
  margin-bottom: 2px;
  border: 1px solid #d8d8d8;
}
.hr-padding {
  padding-top: 0;
  padding-bottom: 0;
}
.details-text {
  font: normal normal normal 14px Open Sans;
  color: $simplic-dark-text;
}
.details-data {
  font: normal normal bold 24px Open Sans;
  color: $simplic-dark-text;
}
button {
  background-color: $simplic-yellow;
  padding: 10px;
  margin: 20px auto;
  width: 90%;
  border-radius: 8px;

  #continue-text-span {
    font: normal normal bold 16px Open Sans;
    float: left;
    color: $simplic-dark-text;
  }

  #arrow-img-span {
    float: right;
    padding-top: 3px;
  }
}
</style>
<i18n>
{
  "pt": {
    "installments": "Quantidade de parcelas atualizadas",
    "installment": "parcelas",
    "quoteDetails": "Contratar Agora",
    "additionalAmount": "Crédito Adicional Disponível:",
    "arrowIcon": "Contratar"
  }
}
</i18n>
