import {
  UnpaidInstallment
} from "@/api/secure/sysopen-gateway-service"
import { Loan, LoanApplication } from "@/api/secure/account-home-service"

export function defaultedInstallmentBalance(unpaidInstallments: UnpaidInstallment[]): number {
  const gracePeriod = 5
  const filteredInstallments = unpaidInstallments.filter((installment) => {
    if (installment.status !== "defaulted") {
      return false
    }
    const today = new Date()
    const dueDate = new Date(installment.due_date)
    dueDate.setDate(dueDate.getDate() + gracePeriod)
    return today > dueDate
  })
  return filteredInstallments.reduce((partialSum, a) => partialSum + a.outstanding_amount, 0)
}

export function calculateDownPaymentOptions(balance: number): string[] {
  let options = [150, 200, 300, 400, 500, 750, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000]
  options = options.filter((option) => { return option <= balance/2 })
  options = options.sort((a, b) => {  return b - a  })

  const staticOptions = [100]
  options.push(...staticOptions)
  return options.map(String)
}

export function nextHighestNumberPast(target: number, list: number[]): number | null {
  const sortedList = list.sort()
  for (let index = 0; index < sortedList.length; index++) {
    if (sortedList[index] > target) {
      return sortedList[index]
    }
  }
  return null
}

// customerType determines whether the customer is a new or existing customer.
export function customerType(loans: Loan[]): string {
  for (let i = 0; i < loans.length; i++) {
    if (loans[i].loan_status == "paid_off") {
      return "existing"
    }
  }
  return "new"
}

/**
 * hasLoanWithStatus determines if, in the provided Loan list, there is AT LEAST one Loan with
 * the provided status(es).
 *
 * @param loans The list of Loan(s)
 * @param status The status(es) of interest
 *
 * @return True if there is AT LEAST one Loan with the provided status(es), false otherwise
 */
export function hasLoanWithStatus(loans: Loan[], status: string[]): boolean {
  for (const loan of loans) {
    if (status.includes(loan.loan_status)) {
      return true
    }
  }

  return false
}

/**
 * hasLoanApplicationWithStatus determines if, in the provided {@link LoanApplication} list, there is
 * AT LEAST one {@link LoanApplication} with the provided status(es).
 *
 * @param loanApplications The list of LoanApplication(s)
 * @param status The status(es) of interest
 *
 * @return True if there is AT LEAST one {@link LoanApplication} with the provided status(es), false otherwise.
 */
export function hasLoanApplicationWithStatus(loanApplications: LoanApplication[], status: string[]): boolean {
  for (const loanApplication of loanApplications) {
    if (status.includes(loanApplication.status)) {
      return true
    }
  }

  return false
}
