import { frontendClient } from "@/api/secure-service"

export interface StatusMessages {
  [index: string]: string;
  pending: string;
  complete: string;
  default: string;
}

export interface CodeCheckPayload {
  code: string | undefined;
  which_phone: string;
  main_phone_verification_1?: string;
  main_phone_verification_2?: string;
  main_phone_verification_3?: string;
  main_phone_verification_4?: string;
}

export interface CodeRequestPayload {
  which_phone: string;
}

export interface CodeRequestResponse {
	status: string;
	message: string;
}

export interface EditPhonePayload {
  account_id: number;
  main_phone: number;
}

export interface EditPhoneResponse {
  status: string;
  message: string;
}

export interface SubmitQuoteResponse {
  path: string;
}

export async function requestCode(payload: CodeRequestPayload): Promise<CodeRequestResponse> {
  try {
    const response = await frontendClient().post("/api/secure/phone_verification/request_code", payload)
    return response as CodeRequestResponse
  } catch(e) {
    return Promise.reject(e)
  }
}

export async function checkCode(payload: CodeCheckPayload): Promise<CodeRequestResponse> {
  try {
    const response = await frontendClient().post("/api/secure/phone_verification/check_code", payload)
    return response as CodeRequestResponse
  } catch(e) {
    return Promise.reject(e)
  }
}

export async function editPhone(payload: EditPhonePayload): Promise<EditPhoneResponse> {
  try {
    const response = await frontendClient().post("/api/secure/update_phone", payload)
    return response as EditPhoneResponse
  } catch(e) {
    return Promise.reject(e)
  }
}
