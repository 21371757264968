
import { defineComponent, onMounted, ref } from "@vue/composition-api"
import WhatsAppConsentInfo from "@/components/whatsapp/WhatsAppConsentInfo.vue"
import WhatsAppConsentCheckbox from "@/components/whatsapp/WhatsAppConsentCheckbox.vue"
import WhatsAppConsentButton from "@/components/whatsapp/WhatsAppConsentButton.vue"
import { useAccountStore } from "@/store"
import { getWhatsAppDisplayDetails } from "@/api/secure/account-home-service"
export default defineComponent({
  name: "WhatsAppConsent",
  components: { WhatsAppConsentInfo, WhatsAppConsentCheckbox, WhatsAppConsentButton },
  methods: {
    consentCheckboxClicked(checkbox: boolean){
      this.consentCheckbox = checkbox
    }
  },
  setup(props, { root }) {
    const { accountID } = useAccountStore()
    const partialPhone = ref("")
    const partialCPFFirst = ref("")
    const partialCPFSecond = ref("")
    const consentCheckbox = ref(false)
    onMounted(async () => {
      if (!accountID.value) {
        await getWhatsAppDisplayDetails(root.$route.params.token).then((response) => {
          partialPhone.value = response.partial_phone
          const cpf = response.partial_cpf
          partialCPFFirst.value = cpf.substring(0, 2)
          partialCPFSecond.value = cpf.substring(2, 4)
        })
      }
    })
    return {
      accountID,
      partialPhone,
      partialCPFFirst,
      partialCPFSecond,
      consentCheckbox
    }
  }
})
