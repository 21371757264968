
import { computed, defineComponent, PropType, ref, watch } from "@vue/composition-api"

export default defineComponent({
  name: "BrazilVTextField",
  emits: ["blur", "input"],
  props: {
    value: {
      type: String,
      required: false,
      default: ""
    },
    edited: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String,
      required: false,
      default: "text"
    },
    rules: {
      type: Array,
      required: false,
      default: () => []
    },
    label: {
      type: String,
      required: false,
      default: ""
    },
    format: {
      type: Function as PropType<(value: string) => string>,
      required: false,
      default: (value: string) => value
    },
    maxLength: {
      type: Number,
      required: false
    },
    isMaxLength: {
      type: Function as PropType<(value: string) => boolean>,
      required: false,
      default: () => false
    },
    counterValue: {
      type: Function as PropType<(value: string) => number>,
      required: false,
      default: () => null
    },
    counter: {
      type: Number,
      required: false
    },
    prependIconInner: {
      type: String,
      required: false,
      default: ""
    },
    prependIcon: {
      type: String,
      required: false,
      default: ""
    },
    prependIconEdit: {
      type: String,
      required: false,
      default: ""
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { emit }) {
    const propFormat = props.format
    const initialValue = ref<string>(propFormat(props.value))
    const formattedValue = ref<string>(initialValue.value)
    const isEdited = ref<boolean>(false)

    watch(() => props.value, (newValue) => {
      formattedValue.value = propFormat(newValue)
      isEdited.value = initialValue.value != formattedValue.value
    })

    watch(() => props.edited, (newValue) => {
      isEdited.value = newValue
      if (!newValue) initialValue.value = propFormat(formattedValue.value)
    })

    const onInput = (value: string) => {
      if (props.isMaxLength(value)) return

      formattedValue.value = propFormat(value)
      isEdited.value = initialValue.value !== formattedValue.value

      emit("input", formattedValue.value, isEdited.value)
    }

    const prependIconValue = computed(() => {
      if (props.prependIconEdit !== "") {
        return isEdited.value ? props.prependIconEdit : props.prependIcon
      }

      return props.prependIcon
    })

    const onBlur = (event: Event) => {
      event.preventDefault()

      const target = event.target as HTMLInputElement
      isEdited.value = initialValue.value !== target.value

      emit("blur", target.value, isEdited.value)
    }

    const onKeyDown = (event: KeyboardEvent) => {
      if (props.type === "number" && (event.key.length === 1 && event.key.match(/[a-zA-Z!@#$%^&*()_+{}[\]:;"'<>,.?/\\|~]/))) event.preventDefault()
    }

    const onPaste = (event: ClipboardEvent) => {
      if (props.type === "number" && event.clipboardData?.getData("text").match(/[a-zA-Z!@#$%^&*()_+{}[\]:;"'<>,.?/\\|~]/)) event.preventDefault()
    }

    return {
      onInput,
      onBlur,
      onKeyDown,
      onPaste,
      formattedValue,
      prependIconValue
    }
  }
})

