
import { defineComponent, ref, computed } from "@vue/composition-api"
import BankList from "@/views/profile-update/BankList.vue"
import { useAccountDetailsStore } from "@/store"
import { BankAccountFields } from "@/api/secure/account-home-service"
import bankInfo from "@/config/bankInfo"
import AccountTypeList from "@/views/profile-update/AccountTypeList.vue"
import BrazilVTextField from "@/components/input/BrazilVTextField.vue"
import { required } from "@/lib/validators/common"
import { VForm, resetFormValidation } from "@/lib/component/helpers"

export default defineComponent({
  name: "BankAccount",
  components: { BrazilVTextField, AccountTypeList, BankList },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup() {
    const accountDetails = useAccountDetailsStore()
    const bankID = accountDetails.bankAccount.value?.bank_identification_number
    const bankBranch = accountDetails.bankAccount.value?.bank_branch
    const accountType = accountDetails.bankAccount.value?.account_type
    const accountNumber = accountDetails.bankAccount.value?.account_number

    const initialBankID = bankID == null ? "" : bankID
    const initialBankBranch = bankBranch == null ? "" : bankBranch
    const initialAccountType = accountType == null ? "" : accountType
    const initialAccountNumber = accountNumber == null ? "" : accountNumber

    const bankIDRef = ref<string>(initialBankID)
    const bankBranchRef = ref<string>(initialBankBranch)
    const accountTypeRef = ref<string>(initialAccountType)
    const accountTypeSelection = ref<string>("")
    const accountNumberRef = ref<string>("")

    const isCaixaSavingsAccount = (accountType: string): boolean => {
      // Caixa savings account types are 013, 1288
      return accountType == "013" || accountType == "1288"
    }

    const isLongCaixaAccountType = (accountType: string): boolean => {
      return accountType == "1288" || accountType == "3701"
    }

    if (bankIDRef.value == "104") {
      const firstPart = initialAccountNumber.substring(0, 4)
      accountNumberRef.value = isLongCaixaAccountType(firstPart) ? initialAccountNumber.substring(4) : initialAccountNumber.substring(3)
      accountTypeSelection.value = isLongCaixaAccountType(firstPart) ? initialAccountNumber.substring(0, 4) : initialAccountNumber.substring(0, 3)
    } else {
      accountNumberRef.value = initialAccountNumber
    }

    const initialSeperatedAccountNumber = accountNumberRef.value

    const formRef = ref<VForm>()

    const bankBranchMaxLength = 4

    const clearValues = () => {
      bankBranchRef.value = ""
      accountTypeRef.value = ""
      accountNumberRef.value = ""

      resetFormValidation(formRef)
    }

    const loadValues = () => {
      bankBranchRef.value = initialBankBranch
      accountTypeRef.value = initialAccountType
      accountNumberRef.value = initialAccountNumber
    }

    const isOriginalBank = (newValue: string): boolean => {
      return newValue == initialBankID
    }

    const onBankSelect = (value: string) => {
      bankIDRef.value = value
      isOriginalBank(bankIDRef.value) ? loadValues() : clearValues()
    }

    const bankBranchCounter = (value: string) => {
      return value.length
    }

    const onBankBranchBlur = (value: string) => {
      bankBranchRef.value = value
    }

    const bankBranchLength = (value: string) => {
      return (value.length === 4) || "Número da agência deve ter 4 digitos."
    }

    const accountNumberMaxLength = computed(() => {
      if (bankIDRef.value == "") return 0

      const bankInfoFormat = bankInfo[bankIDRef.value].format
      // Newer Caixa accounts have longer account numbers
      if (bankIDRef.value == "104" && isLongCaixaAccountType(accountTypeSelection.value)) {
        return bankInfoFormat.accountNumberLengths[1]
      }

      return bankInfoFormat.accountNumberLengths[0]
    })

    const accountNumberFormat = (value: string) => {
      if (accountNumberMaxLength.value == 0) return ""

      const unformatted = value.replace(/\D/g, "").trim()
      if (unformatted.length > accountNumberMaxLength.value - 1) {
        return unformatted.substring(0, unformatted.length - 1) + "-" + unformatted.substring(unformatted.length - 1)
      }

      return unformatted
    }

    const accountNumberCounter = (value: string) => {
      let count = value.length

      if (value.includes("-")) {
        count--
      }

      return count
    }

    const onAccountNumberBlur = (value: string) => {
      const bankInfoFormat = bankInfo[bankIDRef.value].format
      // most banks have 1 account number length
      let expectedLength = bankInfoFormat.accountNumberLengths[0]
      // Newer Caixa accounts have longer account numbers
      if (bankIDRef.value == "104" && isLongCaixaAccountType(accountTypeSelection.value)) {
        expectedLength = bankInfoFormat.accountNumberLengths[1]
      }

      accountNumberRef.value = value.length == 0 ? value : value.padStart(expectedLength, "0")
    }

    const isOriginalAccountType = (newValue: string): boolean => {
      return newValue == initialAccountType
    }

    const onAccountTypeSelect = (value: string) => {
      accountTypeSelection.value = value
      if (bankIDRef.value == "237") {
        accountTypeRef.value = value == "corrente" ? "checking" : "savings"
      } else if (bankIDRef.value == "104") {
        accountTypeRef.value = isCaixaSavingsAccount(value) ? "savings" : "checking"
      } else {
        accountTypeRef.value = "checking"
      }

      if (isOriginalBank(bankIDRef.value) && isOriginalAccountType(accountTypeRef.value)) {
        accountNumberRef.value = initialAccountNumber
      } else {
        accountNumberRef.value = ""
      }
    }

    const bankIDRules = [
      required
    ]

    const bankBranchRules = [
      required,
      bankBranchLength
    ]

    const accountTypeRules = [
      required
    ]

    const accountNumberRules = [
      required
    ]

    return {
      initialBankID,
      initialBankBranch,
      initialAccountType,
      initialAccountNumber,
      initialSeperatedAccountNumber,

      bankIDRules,
      bankBranchRules,
      accountTypeRules,
      accountNumberRules,

      bankIDRef,
      onBankSelect,
      bankBranchRef,
      bankBranchCounter,
      bankBranchMaxLength,
      onBankBranchBlur,
      accountTypeRef,
      accountTypeSelection,
      onAccountTypeSelect,
      accountNumberRef,
      accountNumberFormat,
      accountNumberCounter,
      onAccountNumberBlur,
      accountNumberMaxLength,
      formRef
    }
  },
  methods: {
    collectData(): BankAccountFields {
      return {
        "bank_name": this.collectBankName(),
        "bank_branch": this.collectBankBranch(),
        "account_number": this.collectAccountNumber(),
        "account_type": this.collectAccountType(),
        "caixa_account_type": this.collectCaixaAccountType()
      } as BankAccountFields
    },
    collectBankName(): string {
      if (this.initialBankID == "") return this.bankIDRef
      return this.initialBankID == this.bankIDRef ? "" : this.bankIDRef
    },
    collectBankBranch(): string {
      if (this.initialBankBranch == "") return this.bankBranchRef
      return this.initialBankBranch == this.bankBranchRef ? "" : this.bankBranchRef
    },
    collectAccountNumber(): string {
      const unformattedAccountNumber = this.accountNumberRef.replace(/\D/g, "").trim()
      if (this.initialSeperatedAccountNumber == "") return unformattedAccountNumber
      return this.initialSeperatedAccountNumber == this.accountNumberRef ? "" : unformattedAccountNumber
    },
    collectAccountType(): string {
      if (this.initialAccountType == "") return this.accountTypeRef
      return (this.initialAccountType == this.accountTypeRef && this.initialBankID == this.bankIDRef) ? "" : this.accountTypeRef
    },
    collectCaixaAccountType(): string {
      if (this.bankIDRef == "104" && this.initialSeperatedAccountNumber != this.accountNumberRef) {
        return this.accountTypeSelection
      }

      return ""
    }
  }
})
