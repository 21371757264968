/* eslint-disable @typescript-eslint/camelcase */

import { ApiPublicService } from "@/api/public-service"
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from "axios"

import {
  JWTService,
  RequestConfig,
  RefreshTokenPayload,
  RefreshTokenResponse
} from "@/api/types"

export class ApiSecureService extends ApiPublicService {
  private readonly jwtService: JWTService

  public constructor(config: JWTService, instance?: AxiosInstance) {
    super(config, instance)

    this.jwtService = config

    this.instance.interceptors.request.use(
      this.addAuthorizationInterceptor.bind(this)
    )
    this.instance.interceptors.response.use(
      undefined,
      this.errorResponseInterceptor.bind(this)
    )
  }

  protected addAuthorizationInterceptor(config: AxiosRequestConfig): AxiosRequestConfig {
    if (this.isAuthDisabled(config)) {
      return config
    }

    if (config.headers.Authorization) {
      return config
    }

    const token = this.jwtService.tokenStorage.getAccessToken()
    if (!token)
      return config

    config.headers.Authorization = `Bearer ${token}`
    return config
  }

  protected errorResponseInterceptor(error: AxiosError): Promise<AxiosResponse> {
    const refreshToken = this.jwtService.tokenStorage.getRefreshToken()

    if (!this.shouldRefreshAndRetry(error, refreshToken)) {
      return Promise.reject(error)
    }

    const requestConfig = error.config as RequestConfig
    requestConfig.retrying = true

    return axios.
      post<RefreshTokenPayload, AxiosResponse<RefreshTokenResponse>>(
        this.jwtService.refreshTokenURL,
        {
          client_id: this.jwtService.loginServerClientID,
          grant_type: "refresh_token",
          refresh_token: refreshToken
        }
      )
      .then((response: AxiosResponse<RefreshTokenResponse>) => {
        if (response && response.status === 200) {
          this.jwtService.tokenStorage.saveTokens(
            response.data.access_token,
            response.data.refresh_token
          )
          //Token refreshed, try request again
          requestConfig.headers.Authorization = ""
          return this.instance.request(requestConfig)
        }
        return Promise.reject(error)
      })
      .catch((error) => {
        window.location.href = "/logout"
        if(error.response?.status === 401) {
          this.jwtService.tokenStorage.expire()
          return Promise.reject(undefined)
        }
        return Promise.reject(error)
      })
  }

  private shouldRefreshAndRetry(error: AxiosError, refreshToken: string): boolean {
    const requestConfig = error.config as RequestConfig
    if (this.isAuthDisabled(requestConfig)) {
      return false
    }
    if (requestConfig.url === this.jwtService.refreshTokenURL) {
      return false
    }
    if (!error.response || error.response.status !== 401) {
      return false
    }
    if (this.isRetrying(requestConfig)) {
      return false
    }

    return !!refreshToken
  }

  private isAuthDisabled(config = {} as RequestConfig): boolean {
    return config.ignoreAuth != null && config.ignoreAuth
  }

  private isRetrying(config = {} as RequestConfig): boolean {
    return config.retrying != null && config.retrying
  }
}

let singleton: ApiSecureService
let frontendSingleton: ApiSecureService

export function setSingleton(s: ApiSecureService): void{
  singleton = s
}

export function setFrontendSingleton(s: ApiSecureService): void{
  frontendSingleton = s
}

export function client(): ApiPublicService {
  return singleton
}

export function frontendClient(): ApiSecureService {
  return frontendSingleton
}
