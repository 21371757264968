<template>
  <div id="offers-loading-skeleton-container">
    <div v-if="$vuetify.breakpoint.mobile">
      <offers-loading-skeleton-mobile />
    </div>
    <div v-else>
      <offers-loading-skeleton-desktop />
    </div>
  </div>
</template>
<script>
import { defineComponent } from "@vue/composition-api"
import OffersLoadingSkeletonMobile from "@/components/skeleton-loaders/payment-plans/OffersLoadingSkeletonMobile.vue"
import OffersLoadingSkeletonDesktop from "@/components/skeleton-loaders/payment-plans/OffersLoadingSkeletonDesktop.vue"

export default defineComponent({
  components: { OffersLoadingSkeletonMobile, OffersLoadingSkeletonDesktop }
})
</script>

