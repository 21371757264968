






import { defineComponent, onMounted } from "@vue/composition-api"
import { useLPStore, useEbsStore, useCookieStore } from "@/store"
import { postEBSEvent } from "@/api/secure"

export default defineComponent({
  name: "BelvoWidget",
  props: {
    accountId: {
      type: String,
      required: false
    },
    bankName: {
      type: String,
      required: true
    },
    accessToken: {
      type: String,
      required: true
    }
  },
  setup(props, { root }) {
    const { activities } = useLPStore()
    const { belvoBankConnection } = useEbsStore()
    const { analyticDataLayer, accessTokensData } = useCookieStore()

    async function createWidget() {
      const successCallbackFunction = (link: string, institution: string) => {
        activities.value.forEach(activityData => {
          if(activityData.name == "income_verification") {
            activityData.status = "in_review"
          }
        })

        if (root.$route?.path?.includes("/lp")) {
          const accountID = accessTokensData.value.account_id
          const loanApplicationID = analyticDataLayer.value.loan_application_id
          if (loanApplicationID) {
            postEBSEvent("belvo_widget", accountID, loanApplicationID, "belvo_widget_success", "")
          }
        }
      }

      const resetBelvoBankConnection = () => {
        belvoBankConnection.value = null
      }

      const allowedInstitution = "of" + props.bankName + "_br_retail"

      const config = {
        "access_mode": "single",
        "external_id": props.accountId?.toString() || "",
        "country_codes": ["BR"],
        "institutions": [allowedInstitution],
        "integration_type": "openfinance",
        "resources": ["TRANSACTIONS"],
        "locale": "pt",
        "show_close_dialog": false,
        "show_abandon_survey": false,

        callback: (link: string, institution: string) => successCallbackFunction(link, institution),
        onExit: () => resetBelvoBankConnection()
      }

      /* @ts-ignore - needed since custom object on window is not known until script is loaded*/
      window.belvoSDK.createWidget(props.accessToken, config).build()
    }

    function loadScript(src: string) {
      const node = document.createElement("script")
      node.src = src
      node.type = "text/javascript"
      node.async = true
      node.onload = createWidget
      document.body.appendChild(node)

      if (root.$route?.path?.includes("/lp")) {
        const accountID = accessTokensData.value.account_id
        const loanApplicationID = analyticDataLayer.value.loan_application_id
        if (loanApplicationID) {
          postEBSEvent("belvo_widget", accountID, loanApplicationID, "belvo_widget_landed", "")
        }
      }
    }

    onMounted(() => {
      // Executing the function to load the widget after mount, since we need
      // the template to be loaded
      loadScript("https://cdn.belvo.io/belvo-widget-1-stable.js")
    })

    return { createWidget }
  }
})
