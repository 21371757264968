


















import { defineComponent, onMounted } from "@vue/composition-api"
export default defineComponent({
  name: "WhatsAppConsentInfo",
  props: {
    cpfFirstTwo: {
      type: String,
      required: false,
      default: ""
    },
    cpfSecondTwo: {
      type: String,
      required: false,
      default: ""
    },
    partialPhone: {
      type: String,
      required: false,
      default: ""
    }
  },
  setup(props, { emit, root }) {
    onMounted(() => {
      if (root && root.$route) {
        emit("whatsapp_consent_shown", process.env.VUE_APP_FULL_URL + root.$route.path)
      }
    })
  }
})
