






















import { defineComponent } from "@vue/composition-api"
import { usePaymentPlansStore } from "@/store"
import { PaymentPlanOffer } from "@/api/secure/sysopen-gateway-service"

export default defineComponent({
  setup() {
    const { selectedOffer, withDownPayment } = usePaymentPlansStore()

    const downPaymentAmount = (offer: PaymentPlanOffer) => {
      return offer.installment_amount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
    }

    const installmentAmount = (offer: PaymentPlanOffer) => {
      if (!withDownPayment.value) {
        return offer.installment_amount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
      }
      if (offer.number_of_installments == 1) {
        return offer.installment_amount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
      }
      const remainingPaymentsAmount = (offer.total_amount - offer.installment_amount) / (offer.number_of_installments - 1)
      if (Math.abs(remainingPaymentsAmount - offer.installment_amount) > 0.1) {
        return remainingPaymentsAmount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
      }
      return offer.installment_amount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
    }

    return {
      selectedOffer,
      downPaymentAmount,
      installmentAmount
    }
  }
})
