import { render, staticRenderFns } from "./OffersLoadingSkeletonMobile.vue?vue&type=template&id=46b162ba&scoped=true&"
import script from "./OffersLoadingSkeletonMobile.vue?vue&type=script&lang=js&"
export * from "./OffersLoadingSkeletonMobile.vue?vue&type=script&lang=js&"
import style0 from "./OffersLoadingSkeletonMobile.vue?vue&type=style&index=0&id=46b162ba&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46b162ba",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCarouselItem,VSkeletonLoader})
