





















































/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent, onMounted, Ref, ref } from "@vue/composition-api"
import { useAccountDetailsStore } from "@/store"
import LoanAppStatusDetailsCard from "@/components/account-home/LoanAppStatusDetailsCard.vue"
import tagRecordings from "@/plugins/hotjar/tagRecordings"
import { VueWithHotjar } from "@/types"

export default defineComponent({
  name: "LoanAppStatusCard",
  components: { LoanAppStatusDetailsCard },
  props: {
    loanAppCardView: {
      type: String,
      required: false
    },
    showLoanAppCard: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { root }) {
    const { loans, nearestBusinessDay, nextBusinessDay, loanApplications } = useAccountDetailsStore()
    const buttonPath: Ref<string> = ref("/lp")
    const loanAppStatus: Ref<string> = ref("")
    const fundingDate: Ref<string> = ref("")
    const declineDate: Ref<string> = ref("")
    const loaded: Ref<boolean> = ref(false)

    const getFundingDate = () => {
      if (loans && loans.value) {
        const loan = loans.value[0]
        const hour = new Date(loan.created_at).getUTCHours() - 3 // brazil
        if (hour < 16 && nearestBusinessDay.value) { // 4 pm brazil time = 7 pm UTC time
          const formatDate = new Date(nearestBusinessDay.value)
          const dateString = formatDate.toLocaleString("pt-BR", { timeZone: "UTC" })
          fundingDate.value = dateString.substr(0, 10)
        } else if (nextBusinessDay.value) {
          const formatDate = new Date(nextBusinessDay.value)
          const dateString = formatDate.toLocaleString("pt-BR", { timeZone: "UTC" })
          fundingDate.value = dateString.substr(0, 10)
        }
      }
    }

    const getDateShownOnDecline = () => {
      // loan app applied date + 31 days
      if (loanApplications && loanApplications.value) {
        const createdAt = new Date(loanApplications.value[0].created_at)
        const futureDate = new Date(createdAt)
        futureDate.setDate(futureDate.getDate() + 31)
        const dateString = futureDate.toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" })
        declineDate.value = dateString.substr(0, 10)
      }
    }

    onMounted(() => {
      loaded.value = false
      if (props.loanAppCardView == "funded") {
        getFundingDate()
      }
      if (props.loanAppCardView == "declined") {
        getDateShownOnDecline()
      }
      loaded.value = true

      if (props.showLoanAppCard && loaded.value) {
        tagRecordings(root as VueWithHotjar, ["loan_app_status_card_displayed"])
      }
    })

    return {
      buttonPath,
      loanAppStatus,
      fundingDate,
      declineDate,
      loaded
    }
  }
})
