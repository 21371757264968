



























import { split } from "lodash"
import { getSerasaQuestionnaire, SerasaQuestionnaire, SerasaQuestionnaireUploadRequest, postSerasaQuestionnaire, SerasaScoreRange } from "@/api/secure/lp-service"
import { defineComponent, onMounted, ref } from "@vue/composition-api"
import { useNotificationsStore } from "@/store"
import VueI18n from "@/plugins/i18n"

export default defineComponent({
  // components: { SerasaQuestion },
  name: "SerasaQuestionnaireForm",
  props: {
    cpf: {
      type: Number,
      required: true
    },
    accountID: {
      type: Number,
      required: true
    }
  },
  setup(props, { emit }) {
    const questionnaire = ref<SerasaQuestionnaire>()
    const checkedQuestions = ref<string[]>([])
    const score = ref<SerasaScoreRange>()
    const { errorMessage } = useNotificationsStore()

    const markCheckedQuestionsTrue = () => {
      checkedQuestions.value.forEach(element => {
        const ids = split(element, "_")
        questionnaire.value?.questions.forEach(q => {
          if (ids[1] == q.id) {
            q.answers.forEach(a => {
              if (ids[2] == a.id) {
                a.selected = true
              }
            })
          }
        })
      })
    }

    const performQSubmit = async (req: SerasaQuestionnaireUploadRequest) => {
      await postSerasaQuestionnaire(req)
        .then((resp) => {
          score.value = resp
          emit("submit-score-range", score.value)
        })
        .catch(() => {
          errorMessage(VueI18n.t("serasaSubmitError").toString())
        })
    }

    const submitQuestionnaire = async () => {
      markCheckedQuestionsTrue()
      const req: SerasaQuestionnaireUploadRequest = {
        questionnaire: questionnaire.value as SerasaQuestionnaire,
        accountID: props.accountID.toString()
      }
      await performQSubmit(req)
    }

    onMounted(async () => {
      await getSerasaQuestionnaire(props.cpf.toString())
        .then((resp) => {
          questionnaire.value = resp
        })
        .catch(() => {
          errorMessage(VueI18n.t("serasaSubmitError").toString())
        })
    })

    return {
      questionnaire,
      checkedQuestions,
      submitQuestionnaire
    }
  }


})
