/* eslint-disable @typescript-eslint/camelcase */
import { GetterTree } from "vuex"
import { RootState } from "@/store"
import { paymentPlansState } from "@/store/paymentPlans"
import { PaymentPlanOffer } from "@/api/secure/sysopen-gateway-service"
import { logError } from "@/plugins/sentry"

export type PaymentPlansState = typeof paymentPlansState

const defaultPaymentMethod = "Boleto"

interface PaymentPlanGetters extends GetterTree<PaymentPlansState, RootState> {
  availablePaymentMethods(state: PaymentPlansState): Array<string>;
  offersForDisplay(state: PaymentPlansState): PaymentPlanOffer[];
  matchingOfferByPaymentMethod(state: PaymentPlansState): PaymentPlanOffer | null;
}
const paymentPlanGetters: PaymentPlanGetters = {

  availablePaymentMethods(state: PaymentPlansState): Array<string> {
    const methods: Array<string> = []
    state.offers?.forEach((offer) => {
      if (!methods.includes(offer.payment_method)) {
        if (state.paymentMethodVariation === "pix" && offer.payment_method == "Pix") {
          methods.unshift(offer.payment_method)
        } else if (state.paymentMethodVariation === "boleto" && offer.payment_method == "Boleto") {
          methods.unshift(offer.payment_method)
        }  else {
          methods.push(offer.payment_method)
        }
      }
    })
    return methods
  },
  offersForDisplay(state: PaymentPlansState): PaymentPlanOffer[] {
    if (!state.offers) {
      return []
    }

    const paymentMethod = state.currentPaymentMethod || defaultPaymentMethod
    return state.offers.filter((offer) => {
      return offer.payment_method === paymentMethod
    })
  },
  matchingOfferByPaymentMethod(state: PaymentPlansState): PaymentPlanOffer | null {
    try {
      if (!state.offers || !state.selectedOffer) {
        return null
      }

      const paymentMethod = state.currentPaymentMethod || defaultPaymentMethod
      const matchingOffers = state.offers.filter((offer) => {
        return offer.payment_method === paymentMethod &&
        offer.number_of_installments === state.selectedOffer?.number_of_installments
      })

      if (matchingOffers.length > 1) {
        throw new Error("More than one matching offer for the given selected offer & payment method")
      }

      return matchingOffers[0] || null
    } catch (e) {
      const extras = {
        selected_offer: state.selectedOffer,
        payment_method: state.selectedOffer?.payment_method
      }
      logError(e, extras)
      return null
    }
  }
}

export { PaymentPlanGetters, paymentPlanGetters }
