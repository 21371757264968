<template>
  <v-card
    :outlined="outlined"
    :raised="raised"
    :class="{ active: active, 'reg-outlined': regOutlined}"
    :flat="flat"
    class="brazil-card">
    <slot />
  </v-card>
</template>

<script>

export default {
  name:       "BrazilVCard",
  props:      {
    outlined: {
      type:    Boolean,
      default: false
    },
    regOutlined: {
      type:    Boolean,
      default: false
    },
    raised: {
      type:    Boolean,
      default: false
    },
    active: {
      type:    Boolean,
      default: false
    },
    flat: {
      type:    Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .brazil-card.v-card {
    background-color: $simplic-background;
    font-weight: bold;
    color: $simplic-dark-text;
    font-size: 14px;
    margin: 6px;
    transition: all .2s ease-in-out;
    padding: 2px 12px;
    &.v-card--raised {
      box-shadow: 0px 3px 3px 1px rgba(0, 0, 0, 0.2);
    }
  }
  .brazil-card.v-card.active {
    background-color: $simplic-white;
    border: 2px solid $simplic-yellow;
    font-size: 16px;
    margin: 6px;
  }
  .brazil-card.v-card.reg-outlined {
    font-size: 16px;
    margin: 0 4px;
    border: 0.2rem solid rgba(0, 0, 0, 0.12);
    border-radius: 10px;
  }
  .brazil-card.v-card.reg-outlined.active {
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.3);
    border: 0.2rem solid $simplic-yellow;
  }

  @media screen and (max-width: 700px) {
    .brazil-card.v-card.reg-outlined {
      margin-bottom: 6px;
    }
    .brazil-card.v-card.reg-outlined.active {
      box-shadow: 0 3px 3px 1px rgba(0, 0, 0, 0.2);
    }
  }

  @media screen and (max-width: 500px) {
    .brazil-card.v-card {
      font-size: 12px;
      padding: 2px 4px;
    }
    .brazil-card.v-card.active {
      font-size: 14px;
    }
  }
</style>
