/* eslint-disable @typescript-eslint/camelcase */
import Vue from "vue"
import * as Sentry from "@sentry/browser"
import { Severity } from "@sentry/types"
import { Vue as VueIntegration } from "@sentry/integrations"
import { v4 as uuidv4 } from "uuid"
import { useAccountStore, useCookieStore } from "@/store"


const useSentry = process.env.VUE_APP_SENTRY_DSN
  && (process.env.VUE_APP_ENVIRONMENT === "production" || process.env.VUE_APP_ENVIRONMENT === "staging")

const UUID = uuidv4()

if (useSentry) {
  const sessionId = UUID

  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    release: `simplic-web@${process.env.PACKAGE_VERSION}`,
    environment: process.env.ENV,
    integrations: [
      new VueIntegration({
        Vue,
        attachProps: true,
        logErrors: true
      })
    ]
  })
  Sentry.setTag("session_trace_uuid", sessionId)
}

function sendErrorToSentry(error: unknown, extras?: Record<string, unknown>, level: Sentry.Severity = Severity.Error) {
  const { accountID, email } = useAccountStore()
  const { analyticDataLayer } = useCookieStore()
  const loanApplicationId = analyticDataLayer.value.loan_application_id
  const loanNumber = analyticDataLayer.value.loanNumber
  let identifier: string

  if (accountID.value) {
    identifier = String(accountID.value)
  } else {
    identifier = UUID
  }

  Sentry.withScope((scope) => {
    scope.setLevel(level || Sentry.Severity.Info)
    const allExtras = {
      loan_application_id: loanApplicationId,
      account_id: accountID,
      loan_number: loanNumber,
      ...extras
    }
    scope.setExtras(allExtras)
    scope.setUser({ id: identifier, email: email.value })
    if (typeof error === "string") {
      Sentry.captureMessage(error, scope)
    } else {
      Sentry.captureException(error, scope)
    }
  })
}

function internalLogError(error: unknown, extras?: Record<string, unknown>, level: Sentry.Severity = Severity.Error): void {
  if (useSentry) {
    sendErrorToSentry(error, extras, level)
  } else {
    switch (level) {
    case Severity.Warning:
      console.warn(error)
      break
    default:
      console.error(error)
      break
    }
  }
}

// eslint-disable-next-line
export function logError(error: unknown, extras?: Record<string, unknown>): void {
  internalLogError(error, extras)
}

// eslint-disable-next-line
export function logWarning(warning: Error, extras?: Record<string, unknown>): void {
  internalLogError(warning, extras, Severity.Warning)
}

export function logAndReject(error: Error): Promise<never> {
  const internalError = internalLogError(error)
  return Promise.reject(internalError)
}
