export default {
  phoneCity: {
    url: "tel:+55-3004-7242",
    display: "3004-7242"
  },
  phoneOther: {
    url: "tel:+55-0800-200-7242",
    display: "0800-200-7242"
  },
  email: {
    url: "mailto:contato@simplic.com.br",
    display: "contato@simplic.com.br"
  },
  whatsApp: {
    url: "https://wa.me/551133352976",
    display: "(11) 3335-2976"
  }
}
