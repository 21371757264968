import { Module } from "vuex"
import store, { State } from "@/store"
import { buildHelpers } from "@/store/composition-helpers"
import { WritableComputedRef } from "@vue/composition-api"
import { updateField, getField } from "vuex-map-fields"

const { mapGettersAndSettersToComputed } = buildHelpers<NotificationsStore>(
  () => store, "notifications"
)

interface NotificationsStore {
  snackbarMessage: string;
  snackbarType: string;
  showSnackbar: boolean;
}

export const newNotificationsState = (): NotificationsStore => {
  return {
    snackbarMessage: "",
    snackbarType: "",
    showSnackbar: false
  }
}
const notificationsState: NotificationsStore = newNotificationsState()

interface NotificationsStoreAccessors {
  snackbarMessage: WritableComputedRef<string>;
  snackbarType: WritableComputedRef<string>;
  showSnackbar: WritableComputedRef<boolean>;
  successMessage: Function;
  errorMessage: Function;
  warnMessage: Function;
}

function useNotificationsStore(): NotificationsStoreAccessors {
  const mapFields = mapGettersAndSettersToComputed(Object.keys(notificationsState) as Array<keyof NotificationsStore>)

  const { showSnackbar, snackbarMessage, snackbarType } = mapFields

  function successMessage(message: string) {
    snackbarMessage.value = message
    snackbarType.value = "success"
    showSnackbar.value = true

  }
  function errorMessage(message: string) {
    snackbarMessage.value = message
    snackbarType.value = "error"
    showSnackbar.value = true
  }
  function warnMessage(message: string) {
    snackbarMessage.value = message
    snackbarType.value = "warn"
    showSnackbar.value = true

  }

  return {
    ...mapFields,
    successMessage,
    errorMessage,
    warnMessage
  } as NotificationsStoreAccessors
}

const notifications: Module<NotificationsStore, State> = {
  namespaced: true,
  state: notificationsState,
  getters: { getField },
  mutations: { updateField }
}

export default notifications
export { notificationsState, useNotificationsStore, NotificationsStore, NotificationsStoreAccessors }
