












































/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent, onMounted, Ref, ref } from "@vue/composition-api"
import { useAccountDetailsStore } from "@/store"
import tagRecordings from "@/plugins/hotjar/tagRecordings"
import { VueWithHotjar } from "@/types"

export default defineComponent({
  name: "MakeAPaymentCard",
  props: {
    mostRecentLoan: {
      type: Object,
      required: false
    }
  },
  setup(props, { root }) {
    const { paymentData } = useAccountDetailsStore()
    const buttonPath: Ref<string> = ref("")
    const paymentAmount: Ref<number> = ref(0)
    const nextDueDate: Ref<string> = ref("")
    const inDefault: Ref<boolean> = ref(false)
    const highPayment: Ref<boolean> = ref(false)

    const convertDate = (input: string) => {
      const date = new Date(input)
      const dateString = date.toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" })
      return dateString.substr(0, 10)
    }

    const formatDate = (date: string) => {
      const dateOnly = date.substr(0, 10)
      const split = dateOnly.split("-") // year-month-day
      let month = ""
      if (split[1] === "01") {
        month = "janeiro"
      } else if (split[1] === "02") {
        month = "fevereiro"
      } else if (split[1] === "03") {
        month = "março"
      } else if (split[1] === "04") {
        month = "abril"
      } else if (split[1] === "05") {
        month = "maio"
      } else if (split[1] === "06") {
        month = "junho"
      } else if (split[1] === "07") {
        month = "julho"
      } else if (split[1] === "08") {
        month = "agosto"
      } else if (split[1] === "09") {
        month = "setembro"
      } else if (split[1] === "10") {
        month = "outubro"
      } else if (split[1] === "11") {
        month = "novembro"
      } else {
        month = "dezembro"
      }
      return (parseInt(split[2], 10) + " de " + month + " de " + split[0])
    }

    const calculateNextPaymentDate = () => {
      const today = new Date()
      const tomorrow = new Date(today)
      tomorrow.setDate(tomorrow.getDate() + 1)
      let paymentDate = tomorrow
      if (!inDefault.value && paymentData.value?.installment_amount != null && paymentData.value?.installment_amount != "" && paymentData.value.unpaid_installments.length > 0) {
        const installments = paymentData.value.unpaid_installments
        installments.sort((a, b) => {
          return +new Date(a.installment_due_date) - +new Date(b.installment_due_date)
        })
        if (installments[0].installment_due_date) {
          paymentDate = new Date(installments[0].installment_due_date)
        }
      }
      nextDueDate.value = formatDate(paymentDate.toISOString())
    }

    const calculatePaymentAmount = () => {
      if (!inDefault.value && paymentData.value?.installment_amount != null && paymentData.value?.installment_amount != "") {
        paymentAmount.value = parseFloat(paymentData.value.installment_amount)
        return
      }
      let amount = 0
      if(paymentData.value?.installment_amount != null && paymentData.value?.installment_amount != "" && paymentData.value.unpaid_due_installments.length > 0) {
        for(let i = 0; i < paymentData.value.unpaid_due_installments.length; i++) {
          amount += parseFloat(paymentData.value.unpaid_due_installments[i].outstanding_balance)
        }
      }
      paymentAmount.value = amount
    }

    const isInDefault = () => {
      inDefault.value = false

      if (paymentData && paymentData.value?.installment_amount != null && paymentData.value?.installment_amount != "" && paymentData.value.unpaid_due_installments.length > 0) {
        inDefault.value = true
        return
      }
    }

    const checkAndUpdatePaymentAmount = () => {
      calculatePaymentAmount()
      if(paymentAmount.value > 3000) {
        highPayment.value = true
      } else {
        highPayment.value = false
      }
    }

    onMounted(() => {
      isInDefault()
      calculateNextPaymentDate()
      checkAndUpdatePaymentAmount()
      if(inDefault.value){
        buttonPath.value = "/loan-status"
      } else {
        buttonPath.value = "/current-loan"
      }

      if (props.mostRecentLoan && props.mostRecentLoan.is_open && paymentData && paymentData.value && paymentData.value.installment_amount != "") {
        tagRecordings(root as VueWithHotjar, ["make_a_payment_card_displayed"])
      }
    })

    return {
      convertDate,
      formatDate,
      isInDefault,
      paymentData,
      nextDueDate,
      paymentAmount,
      buttonPath,
      inDefault,
      highPayment
    }
  }
})
