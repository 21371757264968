










import NavigationDrawer from "@/components/header/NavigationDrawerAuth.vue"
import { defineComponent } from "@vue/composition-api"
import { useSessionStore } from "@/store"

export default defineComponent({
  name: "MobileHeaderAuth",
  components: { NavigationDrawer },
  setup() {
    const { navDrawerOpen } = useSessionStore()
    const toggleNagivationDrawer = () => {
      navDrawerOpen.value = !navDrawerOpen.value
    }
    return {
      navDrawerOpen,
      toggleNagivationDrawer
    }
  }
})
