






















/* eslint-disable @typescript-eslint/camelcase */
import { computed, defineComponent, onMounted } from "@vue/composition-api"
import PaymentPlanOffersLastCard from "@/components/payment-plans/OffersLastCard.vue"
import PaymentPlanOffersCard from "@/components/payment-plans/OffersCard.vue"
import OffersLoadingSkeletonMobile from "@/components/skeleton-loaders/payment-plans/OffersLoadingSkeletonMobile.vue"
import SimplicCarousel from "@/components/SimplicCarousel.vue"
import { useAccountStore } from "@/store"
import { PaymentPlanOffer } from "@/api/secure/sysopen-gateway-service"
import { postUniversalMetricsEvent } from "@/api/secure"
import store from "@/store"

export default defineComponent({
  name: "PaymentPlanOffersCarousel",
  props: {
    id: {
      type: String,
      required: false,
      default: ""
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: { SimplicCarousel, PaymentPlanOffersLastCard, PaymentPlanOffersCard, OffersLoadingSkeletonMobile },
  setup(_, { emit }) {
    const { accountID } = useAccountStore()

    const onSelectOffer = (offer: PaymentPlanOffer) => {
      emit("offer-selected", offer)
    }

    const offers = computed(() => store.getters["paymentPlans/offersForDisplay"])

    onMounted(() => {
      postUniversalMetricsEvent(accountID.value, "saw_payment_plan_offers")
    })

    return {
      onSelectOffer,
      offers
    }
  }
})
