import { Route } from "vue-router"
import { useCookieStore } from "@/store/cookie"
import { AppRouteConfig } from "@/router/meta"

type buildUrlPayload = {
  suffix?: string;
  query?: Record<string, string>;
}

function buildUrl(args?: buildUrlPayload): string {
  let url = process.env.VUE_APP_SIMPLIC_BASE_URL ?? ""

  if (!args) {
    return url
  }

  url = args?.suffix ? `${url}/${args.suffix}` : url

  if (args.query) {
    url = `${url}?${new URLSearchParams(args.query).toString()}`
  }
  return url
}

const addQueryParams = (to: Route, url: string): string => {
  url += "?"
  for (const key in to.query) {
    url += key + "=" + to.query[key] + "&"
  }

  return url.slice(0, url.length - 1)
}

const links: Record<string, string> = {
  "": buildUrl(),
  "account_home": buildUrl({ suffix: "account_home" }),
  "contract": buildUrl({ suffix: "contract/raw" }),
  "contract-new": buildUrl({ suffix: "contract/new" }),
  "chat": buildUrl({ suffix: "chat" }),
  "loan-status": buildUrl({ suffix: "loan-status" }),
  "logout": buildUrl({ suffix: "logout" }),
  "login": buildUrl({ suffix: "login" }),
  "como_funciona": buildUrl({ suffix: "como_funciona" }),
  "como-funciona": buildUrl({ suffix: "como-funciona" }),
  "quem_somos": buildUrl({ suffix: "quem_somos" }),
  "faq": buildUrl({ suffix: "faq" }),
  "emprestimento_pessoal": buildUrl({ suffix: "emprestimento_pessoal" }),
  "mapadosite": buildUrl({ suffix: "mapadosite" }),
  "mapa-do-site": buildUrl({ suffix: "mapa-do-site" }),
  "blog": buildUrl({ suffix: "blog" }),
  "termos": buildUrl({ suffix: "termos" }),
  "frontend_top_up_refinance": buildUrl({ suffix: "top_up_refinance" }),
  "loan_applications": buildUrl({ suffix: "loan_applications" }),
  "opt_in": buildUrl({ suffix: "loan_application/opt_in" }),
  "redefinir_senha": buildUrl({ suffix: "redefinir_senha" }),
  "pessoal": buildUrl({ suffix: "v/pessoal" }),
  "loan_offers": buildUrl({ suffix: "loan_applications/loan_offers" }),
  "submit_quote": buildUrl({ suffix: "api/secure/account-home/submission" })
}

// build account home menu urls from the cookie
const { accountHomeMenu } = useCookieStore()
for (const link of Object.values(accountHomeMenu.value)) {
  link.path = link.path.replace("/", "")
  links[link.path] = buildUrl({ suffix: link.path, query: link.query })
}

const externalRoutes: Record<string, AppRouteConfig> = {}
const ExternalRouteLinks: Record<string, string> = {}

Object.keys(links).forEach((key) => {
  ExternalRouteLinks[key] = key
  externalRoutes[key] = {
    path: `/${key}`,
    name: key,
    beforeEnter: (to) => {
      let url: string = links[key]

      if (Object.keys(to.query).length > 0) {
        url = addQueryParams(to, url)
      }

      window.location.href = url
    }
  }
})

export default {}
export { externalRoutes, ExternalRouteLinks }
