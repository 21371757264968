















/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent, ref, Ref, onMounted } from "@vue/composition-api"
import CurrentLoanDetailsCard from "@/components/account-home/CurrentLoanDetailsCard.vue"
import { useAccountDetailsStore } from "@/store"
import { Loan } from "@/api/secure/account-home-service"
import tagRecordings from "@/plugins/hotjar/tagRecordings"
import { VueWithHotjar } from "@/types"

export default defineComponent({
  name: "CurrentLoanDetails",
  components: { CurrentLoanDetailsCard },
  setup(_, { root }) {
    const { loans, paymentData } = useAccountDetailsStore()
    const currentLoan: Ref<Loan|null> = ref(null)
    const loaded: Ref<boolean> = ref(false)

    const getCurrentLoan = () => {
      if (loans != null && loans.value) {
        for(let i = 0; i < loans.value.length; i++) {
          if (loans.value[i].loan_status === "current") {
            currentLoan.value = loans.value[i]
            return
          }
        }
      }
    }

    onMounted(() => {
      loaded.value = false
      getCurrentLoan()
      loaded.value = true

      if (currentLoan.value && paymentData && paymentData.value && paymentData.value.installment_amount != "") {
        tagRecordings(root as VueWithHotjar, ["current_loan_view_displayed"])
      }
    })

    return {
      currentLoan,
      paymentData,
      loaded
    }
  }
})
