












import SimplicHeader from "@/components/header/SimplicHeader.vue"
import SimplicFooter from "@/components/footer/SimplicFooter.vue"
import { defineComponent } from "@vue/composition-api"
import RouteLayoutSelector from "@/components/layouts/RouteLayoutSelector.vue"
import { defaultMeta } from "@/plugins/meta-tags"

export default defineComponent({
  name: "App",
  components: { SimplicHeader, RouteLayoutSelector, SimplicFooter },
  metaInfo() {
    return defaultMeta
  }
})
