import { AnalyticsPlugin } from "analytics"
import { Config, EventCallback } from "@/lib/analytics/analytics-types"
import { datadogRum } from "@datadog/browser-rum"
import { buildAnalyticsCallback } from "@/lib/analytics/helpers"

function allConfigurationsSet(): boolean{
  return !!process.env.VUE_APP_DATADOG_APPLICATION_ID  && !!process.env.VUE_APP_DATADOG_CLIENT_TOKEN && !!process.env.VUE_APP_DATADOG_SERVICE
}

let isLoaded = false

const impl = {
  initialize: () => {
    datadogRum.init({
      applicationId: process.env.VUE_APP_DATADOG_APPLICATION_ID || "",
      clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN || "",
      site: "datadoghq.com",
      service: process.env.VUE_APP_DATADOG_SERVICE,
      version: process.env.PACKAGE_VERSION,
      env: process.env.VUE_APP_ENVIRONMENT,
      sampleRate: 10,
      resourceSampleRate: 10,
      trackInteractions: true //The trackInteractions initialization parameter enables the collection of user clicks in your application. Sensitive and private data contained on your pages may be included to identify the elements interacted with.
    })
    isLoaded = true
  },
  identify: buildAnalyticsCallback(({ payload }: EventCallback) => {
    datadogRum.addRumGlobalContext("customer.account_id", payload.userId)
    for (const key in payload.traits) {
      datadogRum.addRumGlobalContext(`customer.${key}`, payload.traits[key])
    }
  }),
  track: buildAnalyticsCallback(({ payload }: EventCallback) => {
    const { error, ...otherProps } = payload.properties
    if(error){
      datadogRum.addError(error, otherProps as Record<string, unknown>)
    }
    else {
      datadogRum.addUserAction(payload.event, payload.properties as Record<string, unknown>)
    }
  }),
  loaded:() => isLoaded
}

export default function datadogPlugin(config: Config): AnalyticsPlugin & typeof impl {
  if(config.enabled && !allConfigurationsSet()){
    console.error("datadog not configured")
    config.enabled = false
  }
  return {
    name: "datadog",
    config: { ...config },
    ...impl
  }
}

