














import { defineComponent, computed } from "@vue/composition-api"
import { useNotificationsStore } from "@/store"

interface SnackbarTypeConfig {
  [key: string]: string;
}
interface SnackbarConfig {
  [key: string]: SnackbarTypeConfig;
}
const config: SnackbarConfig = {
  warn: {
    closeColor: "#674D00",
    color: "#FCF8E3"
  },
  error: {
    closeColor: "#DB2435",
    color: "#FDF4F5"
  },
  success: {
    closeColor: "#3E753F",
    color: "#DFF0D9"
  }
}
export default defineComponent({
  name: "Snackbar",
  setup() {
    const { snackbarMessage, snackbarType, showSnackbar } = useNotificationsStore()
    const icon = computed(() => {
      return config[snackbarType.value]?.icon
    })
    const color = computed(() => {
      return config[snackbarType.value]?.color || "#777"
    })
    const closeColor = computed(() => {
      return config[snackbarType.value]?.closeColor || "#777"
    })

    return {
      showSnackbar,
      snackbarMessage,
      snackbarType,
      closeColor,
      color,
      icon
    }
  }
})
