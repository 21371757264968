







import { defineComponent, onMounted, Ref, ref } from "@vue/composition-api"
import analytics from "@/lib/analytics"
import MasonryWall from "@yeger/vue2-masonry-wall"
import PastLoanDetailsCard from "@/components/account-home/PastLoanDetailsCard.vue"
import Resources from "@/components/account-home/Resources.vue"
import PaymentScheduleCard from "@/components/account-home/PaymentScheduleCard.vue"
import RefinanceEligibleCard from "@/components/account-home/RefinanceEligibleCard.vue"
import RefinanceFutureEligibleCard from "@/components/account-home/RefinanceFutureEligibleCard.vue"
import LowerMyPaymentCard from "@/components/account-home/LowerMyPaymentCard.vue"
import CurrentLoanDetailsCard from "@/components/account-home/CurrentLoanDetailsCard.vue"
import QuoteSlider from "@/components/account-home/QuoteSliderCard.vue"
import MakeAPaymentCard from "@/components/account-home/MakeAPaymentCard.vue"
import LoanAppStatusCard from "@/components/account-home/LoanAppStatusCard.vue"
import OpenFinanceCard from "@/components/account-home/OpenFinanceCard.vue"
import { useAccountDetailsStore, useAccountStore, usePaymentPlansStore } from "@/store"
import { Loan, LoanApplication, ProductEligibility } from "@/api/secure/account-home-service"
import { PaymentPlan } from "@/api/secure/sysopen-gateway-service"
import WhatsAppConsentCard from "@/components/whatsapp/WhatsAppConsentCard.vue"

export default defineComponent({
  name: "AccountHome",
  components: { MasonryWall, CurrentLoanDetailsCard, PastLoanDetailsCard, PaymentScheduleCard, Resources, RefinanceEligibleCard, RefinanceFutureEligibleCard, LowerMyPaymentCard, QuoteSlider, MakeAPaymentCard, LoanAppStatusCard, OpenFinanceCard, WhatsAppConsentCard },
  setup() {
    const { loans, loanApplications, paymentData, eligibleProducts } = useAccountDetailsStore()
    const { accountID } = useAccountStore()
    const { paymentPlans } = usePaymentPlansStore()
    const previousLoan: Ref<Loan | null> = ref(null)
    const mostRecentLoan: Ref<Loan | null> = ref(null)
    const mostRecentLoanApp: Ref<LoanApplication | null> = ref(null)
    const canApply: Ref<boolean> = ref(true)
    const showLoanAppStatusCard: Ref<boolean> = ref(false)
    const whichLoanAppStatusCard: Ref<string> = ref("")

    const showLowerMyPayment = (eligProds: ProductEligibility | null, pPlans: PaymentPlan[]) => {
      const hasLowerMyPaymentProduct = (eligProds && eligProds.eligible_products && eligProds.eligible_products.includes("lower_my_payment"))
      const hasActivePaymentPlans = pPlans && pPlans.length
      return hasLowerMyPaymentProduct && !hasActivePaymentPlans && analytics.plugins.optimizely.isFeatureEnabled("lower_my_payment")
    }

    // cards is explicitly ordered by business requirements
    const cards = [
      { component: QuoteSlider,                 show: true },
      { component: LowerMyPaymentCard,          show: showLowerMyPayment(eligibleProducts.value, paymentPlans.value) },
      { component: LoanAppStatusCard,           show: true },
      { component: RefinanceEligibleCard,       show: true },
      { component: MakeAPaymentCard,            show: true },
      { component: WhatsAppConsentCard,         show: true },
      { component: PaymentScheduleCard,         show: true },
      { component: PastLoanDetailsCard,         show: true },
      { component: RefinanceFutureEligibleCard, show: true },
      { component: Resources,                   show: true },
      { component: OpenFinanceCard,             show: true }
    ]

    const getPastLoanDetails = () => {
      if (loans != null && loans.value?.length) {
        for(let i = 0; i < loans.value.length; i++) {
          if (loans.value[i].loan_status === "paid_off" || loans.value[i].loan_status === "sold") {
            previousLoan.value = loans.value[i]
            break
          }
        }
      }
    }

    const getMostRecent = () => {
      if (loans != null && loans.value?.length) {
        mostRecentLoan.value = loans.value[0]
      }
      if (loanApplications != null && loanApplications.value?.length) {
        mostRecentLoanApp.value = loanApplications.value[0]
      }
    }

    const ableToApply = () => {
      const currentLoanStatuses = ["new", "current", "pending_transfer", "sold"]
      const falseLoanAppStatuses = ["contract_signed", "ready_for_lp", "lp", "pending", "pending_approval", "approved"]
      const trueLoanAppStatuses = ["contract_unsigned", "ebs_declined", "lp_declined", "underwriting_approved", "underwriting_declined", "withdrawn", "applied", "issued"]
      const hasCurrentLoan = () => loans?.value?.some(l => {
        return currentLoanStatuses.includes(l.loan_status)
      })

      if (hasCurrentLoan()) {
        canApply.value = false
        return
      }

      if (mostRecentLoanApp.value && falseLoanAppStatuses.includes(mostRecentLoanApp.value.status)) {
        canApply.value = false
        return
      }

      if (mostRecentLoanApp.value && trueLoanAppStatuses.includes(mostRecentLoanApp.value.status)) {
        canApply.value = true
        return
      }

      if (mostRecentLoan.value == null && mostRecentLoanApp.value == null) {
        canApply.value = true
        return
      }

      canApply.value = false
    }

    const within30Days = (date: string) => {
      const today = new Date()
      const inputDate = new Date(date)
      const diff = Math.abs(today.getTime() - inputDate.getTime())
      const daysBetween =  diff / (1000 * 60 * 60 * 24)
      if (daysBetween < 31) {
        return true
      }
      return false
    }

    const loanAppStatusCardLogic = () => {
      if (mostRecentLoanApp && mostRecentLoanApp.value) {
        if ((mostRecentLoanApp.value.status == "lp_declined" || mostRecentLoanApp.value.status == "ebs_declined") && within30Days(mostRecentLoanApp.value.updated_at)) {
          showLoanAppStatusCard.value = true
          whichLoanAppStatusCard.value = "declined"
          return
        } else if (mostRecentLoanApp.value.status == "withdrawn" && within30Days(mostRecentLoanApp.value.updated_at)) {
          showLoanAppStatusCard.value = true
          whichLoanAppStatusCard.value = "withdrawn"
          return
        } else if (mostRecentLoanApp.value.status == "lp" || mostRecentLoanApp.value.status == "ready_for_lp") {
          showLoanAppStatusCard.value = true
          whichLoanAppStatusCard.value = "lp"
          return
        } else if (mostRecentLoanApp.value.status == "approved" || mostRecentLoanApp.value.status == "pending") {
          showLoanAppStatusCard.value = true
          whichLoanAppStatusCard.value = "approved"
          return
        } else if (mostRecentLoan && mostRecentLoan.value && mostRecentLoan.value.loan_status == "current" && paymentData.value?.installment_amount == "") {
          showLoanAppStatusCard.value = true
          whichLoanAppStatusCard.value = "funded"
          return
        }
      }

      showLoanAppStatusCard.value = false
    }

    onMounted(async () => {
      analytics.track("account_home_viewed")
      getPastLoanDetails()
      getMostRecent()
      ableToApply()
      loanAppStatusCardLogic()
    })

    return {
      cards,
      previousLoan,
      mostRecentLoan,
      canApply,
      paymentData,
      showLoanAppStatusCard,
      whichLoanAppStatusCard,
      accountID
    }
  },
  methods: {
  }
})
