import { Ref } from "@vue/composition-api"

type Collectable = {
  collectData(): any;
}

type VForm = Vue & {
  validate: () => boolean;
  resetValidation: () => boolean;
  reset: () => void;
};

/**
 * isFormValid provides a wrapper for the Ref<VForm> to allow mocking
 * @param formRef The Ref<VForm> being mocked
 *
 * @return True if valid, false otherwise
 */
const isFormValid = (formRef: Ref<VForm|undefined>) => {
  if (formRef.value == undefined) return false

  return formRef.value.validate()
}

/**
 * resetFormValidation provides a wrappfer for the Ref<VForm> to allow mocking
 * @param formRef the Ref<VForm> being mocked
 */
const resetFormValidation = (formRef: Ref<VForm|undefined>) => {
  if (formRef.value === undefined) return

  formRef.value.resetValidation()
}

/**
 * collectData provides a wrapper for the Ref<Collectable> to allow mocking
 *
 * @param collectible The Ref<Collectable> being mocked
 *
 * @return The collected data
 */
const collectData = (collectible: Ref<Collectable|undefined>) => {
  if (collectible.value == undefined) return {}

  return collectible.value.collectData()
}

export { isFormValid, resetFormValidation, collectData, Collectable, VForm }
