








































/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent, onMounted, Ref, ref } from "@vue/composition-api"
import { useAccountDetailsStore } from "@/store"

export default defineComponent({
  name: "CurrentLoanDetailsCard",
  props: {
    mostRecentLoan: {
      type: Object,
      required: false
    }
  },
  setup() {
    const { paymentData } = useAccountDetailsStore()
    const lastDueDate: Ref<string> = ref("")
    const expand: Ref<boolean> = ref(false)

    const seeMoreClick = () => {
      expand.value = !expand.value
    }

    const convertDate = (date: string) => {
      const dateOnly = date.substr(0, 10)
      const split = dateOnly.split("-") // year-month-day
      const newDate = split[2] + "/" + split[1] + "/" + split[0]
      return newDate
    }

    const translateStatus = (status: string) => {
      if(status.includes("paid")) {
        return "Quitado"
      } else if(status.includes("current")) {
        return "Atual"
      } else if(status.includes("approved") || status.includes("issued")) {
        return "Aprovada"
      } else if(status.includes("applied")) {
        return "Solicitação iniciada"
      } else if(status.includes("progress")) {
        return "Aguardando aprovação"
      } else if(status.includes("withdrawn")) {
        return "Cancelada"
      } else if(status.includes("decline")) {
        return "Rejeitada"
      } else {
        return status
      }
    }

    const calculateLastPaymentDate = () => {
      if (paymentData.value && paymentData.value.installment_amount != "") {
        const installments = paymentData.value.unpaid_installments
        installments.sort((a, b) => {
          return +new Date(a.installment_due_date) - +new Date(b.installment_due_date)
        })

        const paymentDate = new Date(installments[installments.length - 1].installment_due_date)

        lastDueDate.value = paymentDate.toISOString()
      }
    }

    onMounted(() => {
      calculateLastPaymentDate()
    })

    return {
      seeMoreClick,
      expand,
      convertDate,
      lastDueDate,
      paymentData,
      translateStatus
    }
  }
})
