import Analytics from "analytics"
import { Severity } from "@sentry/types"
import sentryPlugin from "@/lib/analytics/plugins/sentryPlugin"
import consolePlugin from "@/lib/analytics/plugins/consolePlugin"
import { AnalyticsInstance } from "@/lib/analytics/analytics-types"
import adobePlugin from "@/lib/analytics/plugins/adobePlugin"
import optimizelyPlugin from "@/lib/analytics/plugins/optimizelyPlugin"
import datadogPlugin from "@/lib/analytics/plugins/datadogPlugin"

import {
  buildPreventDuplicateErrors,
  buildErrorHandlers
} from "@/lib/analytics/helpers"

declare let window: { analytics: unknown } & Window

const isDeployedMode = process.env.VUE_APP_ENVIRONMENT === "production" || process.env.VUE_APP_ENVIRONMENT === "staging"

// Used when local manual testing / debugging required
const IS_ALL_PLUGINS_ENABLED = process.env.VUE_APP_ANALYTICS_IS_ALL_PLUGINS_ENABLED === "true"


// See Demo for API: https://analytics-demo.netlify.app/
const analytics = Analytics({
  app: process.env.VUE_APP_CLIENT_ID,
  debug: !isDeployedMode,
  plugins: [
    sentryPlugin({ enabled: IS_ALL_PLUGINS_ENABLED || isDeployedMode }),
    consolePlugin({ enabled: IS_ALL_PLUGINS_ENABLED || !isDeployedMode, maxSeverity: Severity.Info }),
    adobePlugin({ enabled: IS_ALL_PLUGINS_ENABLED || isDeployedMode }),
    optimizelyPlugin({ enabled: true }),
    datadogPlugin({ enabled: IS_ALL_PLUGINS_ENABLED || isDeployedMode })
  ]
}) as AnalyticsInstance

// Sentry / error specific configuration

analytics.on("trackStart", buildPreventDuplicateErrors(150, 5))

const errorHandlers = buildErrorHandlers(analytics)
export const logError = errorHandlers.logError
export const logWarning = errorHandlers.logWarning

// Useful for debug purposes
window.analytics = analytics

export default analytics
