
import { Ref, defineComponent, ref, onMounted } from "@vue/composition-api"
import WhatsAppConsentBanner from "@/components/whatsapp/WhatsAppConsentBanner.vue"
import WhatsAppConsentInfo from "@/components/whatsapp/WhatsAppConsentInfo.vue"
import WhatsAppConsentCheckbox from "@/components/whatsapp/WhatsAppConsentCheckbox.vue"
import WhatsAppConsentButton from "@/components/whatsapp/WhatsAppConsentButton.vue"
import { getWhatsAppConsentAgreed } from "@/api/secure/whatsapp-consent"

export default defineComponent({
  name: "WhatsAppConsentCard",
  props: {
    accountHome: {
      type: Boolean,
      default: false,
      required: false
    },
    accountID: {
      type: Number,
      required: false
    },
    whatsAppConsentAgreed: {
      type: Boolean,
      default: false
    }
  },
  components: { WhatsAppConsentInfo, WhatsAppConsentCheckbox, WhatsAppConsentButton, WhatsAppConsentBanner },
  setup(props) {
    const consentCheckbox: Ref<boolean> = ref(false)
    const accountHomeDisplayCard: Ref<boolean> = ref(false)
    const whatsAppConsentAgreed: Ref<boolean> = ref(false)
    const consentCheckboxClicked = ((checkbox: boolean) => {
      consentCheckbox.value = checkbox
    })

    const whatsAppConsentAccountHomeDisplayLogic = async () => {
      if (props.accountID && props.accountHome) {
        whatsAppConsentAgreed.value = await getWhatsAppConsentAgreed(props.accountID)
        accountHomeDisplayCard.value = !whatsAppConsentAgreed.value
      }
    }

    onMounted(async () => {
      await whatsAppConsentAccountHomeDisplayLogic()
    })

    return {
      consentCheckbox,
      consentCheckboxClicked,
      accountHomeDisplayCard
    }
  }
})
