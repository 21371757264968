






























import { defineComponent } from "@vue/composition-api"
import contact from "@/config/contact"
export default defineComponent({
  name: "Contact",
  setup() {
    return {
      contact
    }
  }
})
