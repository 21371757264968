import { JWTService } from "@/api/types"
import CookieJWTStorage from "@/api/cookie-jwt-storage"
import * as publicService from "@/api/public-service"
import * as secureService from "@/api/secure-service"

const baseApiURL = process.env.VUE_APP_API_BASE_URL || ""
const frontendBaseApiURL = process.env.VUE_APP_FRONTEND_CLIENT_BASE_URL || ""
const clientID = process.env.VUE_APP_CLIENT_ID || ""
const loginServerClientID = process.env.VUE_APP_LOGIN_SERVER_CLIENT_ID || ""
const refreshTokenURL = process.env.VUE_APP_TOKEN_REFRESH_URL || ""

const config: JWTService = {
  baseURL: baseApiURL,
  headers: {
    ClientID: clientID,
    "X-APP-NAME": "simplic-web"
  },
  timeout: 20000,
  loginServerClientID: loginServerClientID,
  refreshTokenURL: refreshTokenURL,
  tokenStorage: CookieJWTStorage
}

const frontendConfig: JWTService = {
  baseURL: frontendBaseApiURL,
  headers: {
    ClientID: clientID,
    "X-APP-NAME": "simplic-web"
  },
  timeout: 20000,
  loginServerClientID: loginServerClientID,
  refreshTokenURL: refreshTokenURL,
  tokenStorage: CookieJWTStorage
}

publicService.setSingleton(new publicService.ApiPublicService(config))
secureService.setSingleton(new secureService.ApiSecureService(config))
secureService.setFrontendSingleton(new secureService.ApiSecureService(frontendConfig))
