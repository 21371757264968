
































































import contact from "@/config/contact"
import { defineComponent } from "@vue/composition-api"
import { useCookieStore, useSessionStore } from "@/store"
import LogoutDialog from "@/components/dialogs/LogoutDialog.vue"

interface MenuSubitem {
  title: string;
  path: string;
  hide?: boolean;
}

interface MenuItem {
  action: string;
  title: string;
  active?: boolean;
  hide?: boolean;
  subitems?: MenuSubitem[];
  path?: string;
  component?: string;
}

export default defineComponent({
  name: "NavigationDrawerAuth",
  components: { LogoutDialog },
  setup: (_, { emit, root }) => {
    const { accountHomeMenu } = useCookieStore()
    const displayRefiLink = accountHomeMenu.value?.top_up_refi?.display
    const onLPPage = root.$route?.path?.includes("/lp")
    const items = [
      {
        action: "mdi-view-dashboard",
        title: "Início",
        path: "/loan-status",
        subitems: []
      },
      {
        action: "mdi-text-box-multiple",
        title: "Meus Empréstimos",
        active: true,
        subitems: [
          {
            title: "Meu Empréstimo Atual",
            path: "/loan-status",
            hide: onLPPage
          },
          {
            title: "Refinanciamento",
            path: "/top-up-refinance",
            hide: !displayRefiLink
          },
          {
            title: "Histórico Empréstimos",
            path: "/loan_applications"
          }
        ]
      },
      {
        action: "mdi-account-question",
        title: "Sobre Simplic",
        subitems: [
          {
            title: "Como Funciona",
            path: "/como-funciona"
          },
          {
            title: "Quem Somos",
            path: "/quem_somos"
          },
          {
            title: "Ajuda",
            path: "/faq"
          },
          {
            title: "Blog",
            path: "/blog"
          }
        ]
      },
      {
        action: "mdi-account-edit",
        title: "Atualizar Dados",
        path: "/atualizar_dados",
        subitems: []
      },
      {
        action: "mdi-lock-reset",
        title: "Redefinir Senha",
        subitems: [],
        path: "/redefinir_senha"
      }
    ] as MenuItem[]

    const itemsToDisplay = [] as MenuItem[]
    items.forEach(item => {
      if (!item.hide) {
        const itemCopy = Object.assign({}, item)
        itemCopy.subitems = itemCopy.subitems?.filter(subitem => {
          return !subitem.hide
        })
        itemsToDisplay.push(itemCopy)
      }
    })

    const updateDrawer = (payload: boolean) => {
      emit("input", payload)
    }
    const { navDrawerOpen } = useSessionStore()
    return {
      updateDrawer,
      contact,
      itemsToDisplay,
      navDrawerOpen
    }
  }
})
