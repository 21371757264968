


















import { defineComponent } from "@vue/composition-api"
import AccountHomeBanner from "@/components/AccountHomeHeader.vue"
import Snackbar from "../Snackbar.vue"
import BackNavFooter from "@/components/footer/BackNavFooter.vue"
import OptionalTasks from "./OptionalTasks.vue"

export default defineComponent({
  name: "AccountHomeLayout",
  components: { AccountHomeBanner, Snackbar, BackNavFooter, OptionalTasks },
  setup(_, { root }) {
    const showBackButton = () => {
      const split = root.$route.path.replace(/^\//, "").split("/")
      if(split.length > 1) {
        return true
      } else if(split.includes("current-loan") || split.includes("loan-history") || split.includes("top-up-refinance") || split.includes("lower-my-payment")) {
        return true
      }
      return false
    }

    const backButtonPath = () => {
      const split = root.$route.path.replace(/^\//, "").split("/")
      if(split.includes("current-loan") || split.includes("loan-history") || split.includes("payment-plans") || split.includes("top-up-refinance") || split.includes("lower-my-payment")) {
        return "/"
      }
      return "/" + root.$route.path.replace(/^\//, "").split("/")[0]
    }

    return {
      showBackButton,
      backButtonPath
    }
  }
})
