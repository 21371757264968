import { AnalyticsInstance, EventCallback, Payload } from "@/lib/analytics/analytics-types"
import { eventNames } from "@/lib/analytics/event-types"
import { Severity } from "@sentry/types"

/* This method prevents accidental circular event notifications */
export function buildPreventDuplicateErrors(maxAllowedErrorCount: number, maxAllowedCountPerError: number): (p: EventCallback) => void {
  const observedErrors = new Map<string, number>()
  let totalErrorCount = 0

  return ({ payload }: EventCallback) => {
    if (payload.properties.error) {
      if (totalErrorCount > maxAllowedErrorCount) {
        // It will still get reported to sentry via global listener
        throw `Too many errors, > ${maxAllowedErrorCount}, disabling graceful error reporting`
      }
      const errorMessage = (payload.properties.error as Error).message
      const errorCount = observedErrors.get(errorMessage) || 0
      if (errorCount > maxAllowedCountPerError) {
        throw payload.properties.error
      }
      observedErrors.set(errorMessage, errorCount + 1)
      totalErrorCount += 1
    }
  }
}

// This "decorator" function is required to allow waiting for any asynchronous events to finish
// before executing callback
export function buildAnalyticsCallback<T extends EventCallback>(cb: (event: T) => void): (p: T) => void {
  return async (event: T) => {
    await event.payload.thenable
    cb(event)
  }
}


export type ErrorHandler = (error: unknown, message?: string) => Promise<void>;

export function buildErrorHandlers(instance: AnalyticsInstance): {
  logError: ErrorHandler;
  logWarning: ErrorHandler;
} {
  async function logError(error: unknown, message?: string): Promise<void> {
    if (typeof error === "string") {
      error = new Error(error)
    }
    return instance
      .track(
        eventNames.FATAL_ERROR,
        { error, severity: Severity.Error, message },
        { plugins: { all: false, sentry: true, console: true, datadog: true } }
      )
  }

  async function logWarning(error: unknown, message?: string): Promise<void> {
    if (typeof error === "string") {
      error = new Error(error)
    }
    return instance
      .track(
        eventNames.WARN_ERROR,
        { error, severity: Severity.Warning, message },
        { plugins: { all: false, sentry: true, console: true, datadog: true } }
      )
  }

  return { logError, logWarning }
}

function getCustomerId(instance: Payload | AnalyticsInstance): (string | unknown) {
  if ("user" in instance) {
    return instance.user("traits").customerId
  } else if ("traits" in instance && instance.traits.customerId) {
    return instance.traits.customerId
  } else {
    return null
  }
}

export function buildId(instance: Payload | AnalyticsInstance): string {
  const customerId = getCustomerId(instance)
  if (customerId) {
    return String(customerId)
  }
  if ("user" in instance) {
    return instance.user("anonymousId")
  } else {
    return instance.anonymousId
  }
}
