



























































import { logError } from "@/plugins/sentry"
import { defineComponent, onMounted, Ref, ref } from "@vue/composition-api"
import { PhoneMinLengthValidator } from "@/lib/validators/phone-min-length"
import { useAccountStore, useCookieStore } from "@/store"
import { CodeRequestPayload, CodeRequestResponse, EditPhonePayload, EditPhoneResponse } from "@/api/secure/frontend-service"
import { requestCode, editPhone } from "@/api/secure"

export default defineComponent({
  name: "PhoneNumberForm",
  setup (_, { emit }) {
    const { phoneNumber, accountID } = useAccountStore()
    const { accessTokensData, updateAccessTokens } = useCookieStore()
    const showPhoneNumberValid: Ref<boolean>  = ref(false)
    const showPhoneNumberInvalid: Ref<boolean>  = ref(false)
    const editingPhone: Ref<boolean> = ref(false)
    const loading: Ref<boolean> = ref(false)

    const validNumber = (numberString: string | undefined) => {
      if (!numberString) {
        return false
      }
      const number = numberString.replace(/\D/g, "")
      return number.length >= 10
    }

    const sendCode = async () => {
      loading.value = true
      if (!phoneNumber.value) {
        return
      }
      if (!validNumber(phoneNumber.value.toString())) {
        return
      }

      // eslint-disable-next-line @typescript-eslint/camelcase
      const pData: CodeRequestPayload = { which_phone: "main_phone" }
      /* eslint-enable @typescript-eslint/camelcase */
      const response: CodeRequestResponse = await requestCode(pData)
      if (!["success", "active_code_request"].includes(response.status)) {
        const requestCodeMessage = response.message
        logError(requestCodeMessage)
      }
      loading.value = false
      emit("sent-code")
    }

    const blurNumber = () => {
      if (phoneNumber.value && validNumber(phoneNumber.value.toString())) {
        showPhoneNumberInvalid.value = false
        showPhoneNumberValid.value = true
      } else {
        showPhoneNumberInvalid.value = true
        showPhoneNumberValid.value = false
      }
    }

    const updateNumber = async () => {
      loading.value = true
      // eslint-disable-next-line @typescript-eslint/camelcase
      const pData: EditPhonePayload = { account_id: accountID.value, main_phone: phoneNumber.value }
      const response: EditPhoneResponse = await editPhone(pData)
      if (response.status != "success") {
        const editPhoneMessage = response.message
        logError(editPhoneMessage)
      }
      // eslint-disable-next-line @typescript-eslint/camelcase
      accessTokensData.value.phone_number = phoneNumber.value
      updateAccessTokens()
      loading.value = false
      editingPhone.value = false
      emit("phone-edited")
    }

    const editNumber = () => {
      // change Request Code button to Save Phone button
      editingPhone.value = true
    }

    return {
      blurNumber,
      editNumber,
      updateNumber,
      sendCode,
      validNumber,
      phoneNumber,
      showPhoneNumberValid,
      showPhoneNumberInvalid,
      editingPhone,
      loading,
      rules: {
        minPhoneLength: PhoneMinLengthValidator
      }
    }
  }
})
