



















/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent, onMounted, Ref, ref } from "@vue/composition-api"
import { Questionnaire } from "@enova-ui/questionnaire-component"
import { useAccountStore, usePaymentPlansStore, useSessionStore, useCookieStore } from "@/store"
import scrollToTop from "@/lib/scrollToTop"
import { getQuestionnaireConfig, postQuestionnaireAnswers  } from "@/api/secure"
import { logError } from "@/plugins/sentry"
import type CreatePaymentPlanQuestionnaire from "@/types/CreatePaymentPlanQuestionnaire"
import type CreatePaymentPlanWithDownPaymentQuestionnaire from "@/types/CreatePaymentPlanWithDownPaymentQuestionnaire"
import analytics from "@/lib/analytics"
import OffersLoadingSkeleton from "@/components/skeleton-loaders/payment-plans/OffersLoadingSkeleton.vue"

const getIsoDueDate = (questionnaire: CreatePaymentPlanQuestionnaire | CreatePaymentPlanWithDownPaymentQuestionnaire): string | undefined => {
  const firstPageAnswer = questionnaire.pages[0].questions[0].answer
  let dueDateAnswer: string | undefined
  switch(firstPageAnswer) {
  case("nok_due_date"):
    dueDateAnswer = questionnaire.pages[1].questions[0].answer
    break
  case("income"):
    dueDateAnswer = questionnaire.pages[2].questions[2].answer
    break
  case("onetime_prob"):
    dueDateAnswer = questionnaire.pages[3].questions[2].answer
    break
  case("direct_debit"):
    dueDateAnswer = questionnaire.pages[4].questions[2].answer
    break
  case("others"):
    dueDateAnswer = questionnaire.pages[5].questions[1].answer
    break
  default:
    dueDateAnswer = undefined
    break
  }

  if(dueDateAnswer === undefined) {
    return undefined
  }

  return `${dueDateAnswer}T00:00:00Z`
}

const getDownPaymentAmount = (questionnaire: CreatePaymentPlanQuestionnaire | CreatePaymentPlanWithDownPaymentQuestionnaire): string => {
  const firstPageAnswer = questionnaire.pages[0].questions[0].answer
  let downPaymentAmountAnswer: string | undefined
  switch(firstPageAnswer) {
  case("nok_due_date"):
    downPaymentAmountAnswer = questionnaire.pages[1].questions[1]?.answer
    break
  case("income"):
    downPaymentAmountAnswer = questionnaire.pages[2].questions[3]?.answer
    break
  case("onetime_prob"):
    downPaymentAmountAnswer = questionnaire.pages[3].questions[3]?.answer
    break
  case("direct_debit"):
    downPaymentAmountAnswer = questionnaire.pages[4].questions[3]?.answer
    break
  case("others"):
    downPaymentAmountAnswer = questionnaire.pages[5].questions[2]?.answer
    break
  }
  if(downPaymentAmountAnswer === undefined) {
    return "000"
  }
  return downPaymentAmountAnswer
}

export default defineComponent({
  name: "PaymentPlanQuestionnaire",
  components: { Questionnaire, OffersLoadingSkeleton },
  setup(props, { root }) {
    const questionnaire: Ref<CreatePaymentPlanQuestionnaire | CreatePaymentPlanWithDownPaymentQuestionnaire | null> = ref(null)
    const { accountHomeHeadline } = useSessionStore()
    const { firstDueDate, downPaymentAmount, withDownPayment, showGenericError, fetchOffers } = usePaymentPlansStore()
    const { analyticDataLayer } = useCookieStore()
    const submitting = ref(false)
    const questionnaireLoading = ref(false)
    const onPageChange = (title: string) => {
      accountHomeHeadline.value = title
      scrollToTop()
    }

    const minDaysInDefaultForDownPayment = 31
    let maxDaysInDefault = 0
    if (analyticDataLayer.value.maxDaysInDefault !== null) {
      maxDaysInDefault = analyticDataLayer.value.maxDaysInDefault
    }

    const maxDaysFromNow = 10
    const createPaymentPlanDefault = "create-payment-plan"
    const createPaymentPlanVariation = "create-payment-plan-with-down-payment"
    let downPaymentVariation: string = createPaymentPlanDefault
    if (withDownPayment.value) {
      downPaymentVariation = createPaymentPlanVariation
    }
    if (maxDaysInDefault > minDaysInDefaultForDownPayment && !withDownPayment.value) {
      const variation = analytics.plugins.optimizely.activate("customer_preferred_down_payment")
      if (typeof(variation) === "string") {
        downPaymentVariation = variation
      }
    }
    const downPaymentDefault = "100"

    const onSubmit = async () => {
      submitting.value = true
      const { accountID } = useAccountStore()
      try {
        const postQuestionnaireAnswersPayload = {
          ...questionnaire.value,
          references: [
            {
              type: "identity_account",
              id: accountID.value?.toString()
            },
            {
              type: "loan_number",
              id: analyticDataLayer.value.loanNumber?.toString()
            },
            {
              type: "loan_application_id",
              id: analyticDataLayer.value.loan_application_id?.toString()
            }
          ]
        } as CreatePaymentPlanWithDownPaymentQuestionnaire
        await postQuestionnaireAnswers(postQuestionnaireAnswersPayload)

        const IsoFirstDueDate = getIsoDueDate(postQuestionnaireAnswersPayload)
        if (!IsoFirstDueDate) {
          throw new Error("failed to determine firstDueDate. Cannot fetch offers.")
        }
        firstDueDate.value = IsoFirstDueDate
        downPaymentAmount.value = getDownPaymentAmount(postQuestionnaireAnswersPayload)

        await fetchOffers(firstDueDate.value, downPaymentAmount.value)
        root.$router.push({
          name: "PaymentPlanOffers",
          params: {
            maxDueDays: maxDaysFromNow.toString()
          }
        })
      } catch(e) {
        logError(e)
        showGenericError()
      } finally {
        submitting.value = false
      }
    }

    const previousOnFirstPage = () => {
      root.$router.push("/loan-status")
    }

    // Add loader or load data before route change
    onMounted(async () => {
      try {
        questionnaireLoading.value = true
        let config
        if (downPaymentVariation === createPaymentPlanVariation) {
          withDownPayment.value = true
          config = await getQuestionnaireConfig("simplic", createPaymentPlanVariation, {}) as CreatePaymentPlanWithDownPaymentQuestionnaire
        } else {
          config = await getQuestionnaireConfig("simplic", createPaymentPlanDefault, {}) as CreatePaymentPlanQuestionnaire
        }

        config.meta.too_late_message = `Intervalo permitido dentro de ${maxDaysFromNow} dias`
        questionnaire.value = config
      } catch(e) {
        showGenericError()
        logError(e)
        return
      } finally {
        questionnaireLoading.value = false
      }

      const today = new Date()
      const date = today.toISOString().substring(0, 10)

      questionnaire.value.pages.forEach((page: any) => {
        page.questions.forEach((question: { type: string; answer?: string }) => {
          if(question.type === "datetime" && question.answer === undefined) {
            question.answer = date
          }
        })
      })
      accountHomeHeadline.value = questionnaire.value?.pages[0]?.title
    })

    return {
      onPageChange,
      previousOnFirstPage,
      onSubmit,
      questionnaire,
      submitting,
      questionnaireLoading
    }
  }
})
