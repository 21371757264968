






import { defineComponent } from "@vue/composition-api"
import BorderEbs from "@/components/loan-processing/BorderEbs.vue"
export default defineComponent({
  name: "ConfirmEBS",
  components: { BorderEbs }
})
