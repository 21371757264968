



















/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent, computed, onMounted } from "@vue/composition-api"
import PaymentPlanOffersLastCard from "@/components/payment-plans/OffersLastCard.vue"
import PaymentPlanOffersCard from "@/components/payment-plans/OffersCard.vue"
import OffersLoadingSkeletonDesktop from "@/components/skeleton-loaders/payment-plans/OffersLoadingSkeletonDesktop.vue"
import { PaymentPlanOffer } from "@/api/secure/sysopen-gateway-service"
import store, { useAccountStore } from "@/store"
import { postUniversalMetricsEvent } from "@/api/secure"
export default defineComponent({
  name: "PaymentPlanOffersCards",
  props: {
    id: {
      type: String,
      required: false,
      default: ""
    },
    loading: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  components: { PaymentPlanOffersLastCard, PaymentPlanOffersCard, OffersLoadingSkeletonDesktop },
  setup(props, { emit }) {
    interface PresentableOffer {
      offer: PaymentPlanOffer;
      hovered: boolean;
      selected: boolean;
    }

    const { accountID } = useAccountStore()
    const offers = computed(() => store.getters["paymentPlans/offersForDisplay"])
    const selectedOffer = computed(() => store.getters["paymentPlans/matchingOfferByPaymentMethod"])
    const presentableOffers = computed(() => offers.value.map((el: PaymentPlanOffer) => ({ offer: el, hovered: false, selected: false })))

    const anyCardsHovered = computed(() => {
      return presentableOffers.value.some((el: PresentableOffer) => el.hovered)
    })

    const onSelectOffer = (presentableOffer: PresentableOffer) => {
      emit("offer-selected", presentableOffer.offer)
    }

    const onHover = (presentableOffer: PresentableOffer, v: boolean) => {
      presentableOffer.hovered = v
    }

    onMounted(() => {
      postUniversalMetricsEvent(accountID.value, "saw_payment_plan_offers")
    })

    return {
      onSelectOffer,
      presentableOffers,
      onHover,
      anyCardsHovered,
      selectedOffer,
      offers
    }
  }
})
