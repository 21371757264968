












import { defineComponent } from "@vue/composition-api"

export default defineComponent({
  name: "Activity",
  components: { },
  props: ["status", "name", "showLink", "header"],
  setup() {
    // not empty anymore
  }

})
