





import { defineComponent, ref, Ref } from "@vue/composition-api"
import VueI18n from "@/plugins/i18n"
export default defineComponent({
  name: "Breadcrumbs",
  setup() {
    const showBreadcrumb: Ref<boolean> = ref(false)
    const prevPage: Ref<string> = ref("")
    const currPage: Ref<string> = ref("")
    const linkPath: Ref<string> = ref("")

    const getPrevPath = (path: string) => {
      const split = path.replace(/^\//, "").split("/")
      if(split.length == 2 && split[0] == "lp") {
        return "/lp"
      }
      return "/"
    }

    const getPageTranslation = (path: string) => {
      let name = ""
      switch(path) {
      case "/make-a-payment-plan":
        name = VueI18n.t("makeAPaymentPlanPage").toString()
        break
      case "/payment-plans/offers":
        name = VueI18n.t("offersPage").toString()
        break
      case "/payment-plans/confirm-payment":
        name = VueI18n.t("confirmPaymentPage").toString()
        break
      case "/payment-plans/pix-confirmation":
        name = VueI18n.t("pixConfirmationPage").toString()
        break
      case "/payment-plans/boleto-confirmation":
        name = VueI18n.t("boletoConfirmationPage").toString()
        break
      case "/lp":
        name = VueI18n.t("lpPage").toString()
        break
      case "/lp/ebs":
        name = VueI18n.t("lpEBSPage").toString()
        break
      case "/lp/identity":
        name = VueI18n.t("lpIdentityPage").toString()
        break
      case "/lp/income":
        name = VueI18n.t("lpIncomePage").toString()
        break
      case "/lp/telefone":
        name = VueI18n.t("lpTelefonePage").toString()
        break
      case "/lp/doc":
        name = VueI18n.t("lpDocPage").toString()
        break
      case "/loan-history":
        name = VueI18n.t("loanHistoryPage").toString()
        break
      case "/current-loan":
        name = VueI18n.t("currentLoanPage").toString()
        break
      case "/top-up-refinance":
        name = VueI18n.t("topUpRefiPage").toString()
        break
      case "/lower-my-payment":
        name = VueI18n.t("lowerMyPaymentPage").toString()
        break
      case "/":
        name = VueI18n.t("homePage").toString()
        break
      }
      return name
    }

    return {
      showBreadcrumb,
      getPageTranslation,
      getPrevPath,
      prevPage,
      currPage,
      linkPath
    }
  },
  watch: {
    "$route" (to, from) {
      if (to.path != "/" && !to.path.includes("payment-plans")) {
        this.showBreadcrumb = true
        const prevPath = this.getPrevPath(to.path)
        const prevName = this.getPageTranslation(prevPath)
        const currentName = this.getPageTranslation(to.path)
        this.linkPath = prevPath
        this.prevPage = prevName
        this.currPage = currentName
      } else {
        this.showBreadcrumb = false
      }
    }
  }
})
