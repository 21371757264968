






import { defineComponent } from "@vue/composition-api"
import { useEbsStore, useCookieStore } from "@/store"
import { postEBSEvent } from "@/api/secure"

export default defineComponent({
  name: "ExpressiFrame",
  setup () {
    const { src, checkingDocumentUUID } = useEbsStore()
    const { analyticDataLayer, accessTokensData } = useCookieStore()

    const eventCodes = new Map<number, string>([
      [-999, "credigo_iframe_lp"],
      [200, "customer_landed"],
      [210, "credigo_iframe_loaded"],
      [402, "invalid_login"],
      [406, "invalid_login"],
      [403, "process_error"]
    ])

    const getEventCodeStatus = (eventCode: number): string => {
      const status: string|undefined = eventCodes.get(eventCode)
      if (status && status !== "") {
        return status
      }

      return "unknown_ebs_error"
    }

    const logEBSEvent = (event: MessageEvent) => {
      let data
      try {
        data = JSON.parse(event.data)
      } catch (e) {
        return
      }

      if(data.code) {
        const status = getEventCodeStatus(data.code)
        const accountID = accessTokensData.value.account_id
        const loanApplicationID = analyticDataLayer.value.loan_application_id
        if (loanApplicationID) {
          postEBSEvent("credigo_iframe", accountID, loanApplicationID, status, checkingDocumentUUID.value)
        }
      }
    }

    return {
      src,
      checkingDocumentUUID,
      analyticDataLayer,
      logEBSEvent
    }
  },
  mounted() {
    window.addEventListener("message", this.logEBSEvent)
  }
})
