<template>
  <v-sheet
    id="payment-plan-offers-last-card"
    class="offer-sheet outer-offer-sheet"
    height="400px"
    color="white"
    rounded
    :elevation="4"
    tile>
    <h3 id="payment-plan-offers-last-card--header">
      {{ $t('speakToSomeone') }}
    </h3>
    <div id="payment-plan-offers-last-card--buttons">
      <v-btn id="whatsapp-button" class="last-card-cta" :href="contact.whatsApp.url">
        <img id="whatsapp-icon" class="icon" src="~@/assets/images/svg/btn-ico_whatsapp.svg" alt="whatsapp-icon" />
        <span>{{ $t('whatsapp') }}</span>
      </v-btn>
      <v-btn v-if="chatEnabled" id="chat-button" class="last-card-cta">
        <img id="chat-icon" class="icon" src="~@/assets/images/svg/btn-ico_chat.svg" alt="chat-icon" />
        <span>{{ $t('chat') }}</span>
      </v-btn>
      <v-btn id="call-button" class="last-card-cta" :class="{copying: showCallCopying}" @click="onCopy('call', $t('callCopyText'))">
        <span v-if="showCallCopying" class="copied" v-html="$t('copied')" />
        <img v-if="!showCallCopying" id="call-icon" class="icon" src="~@/assets/images/svg/btn-ico_ligacao.svg" alt="call-icon" />
        <span v-if="!showCallCopying">{{ $t('call') }}</span>
      </v-btn>
    </div>
  </v-sheet>
</template>
<script>
import { defineComponent, ref } from "@vue/composition-api"
import contact from "@/config/contact"

export default defineComponent({
  name: "OffersLastCard",
  computed: {
    contact() {
      return contact
    }
  },
  setup() {
    // feature flag off until chat feature is developed
    const chatEnabled = ref(false)
    const showCallCopying = ref(false)
    const onCopy = (type, text) => {
      showCallCopying.value = true
      navigator.clipboard.writeText(text)
      setTimeout(() => {
        showCallCopying.value = false
      }, 3000)
    }

    return {
      onCopy,
      showCallCopying,
      chatEnabled
    }
  }
})
</script>
<style lang="scss" scoped>
@import '~/src/scss/variables.scss';
h3 {
  margin-bottom: 2em;
}
#payment-plan-offers-last-card--buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .last-card-cta {
    position: relative;
    margin-bottom: 1.5em;
    background-color: $simplic-yellow !important;
    font-weight: bold;
    text-transform: capitalize;
    padding: 1.5em 1em;
    font-size: 1.1em;
    width: 100%;
    color: $simplic-copy;
    justify-content: start;
    &.copying {
      background-color: rgba(0, 0, 0, 0.5) !important;
    }
  }
  .icon {
    width: 25px;
    margin-right: 5px;
  }
  #chat-icon {
    width: 30px;
  }
}
.offer-sheet {
  color: black;
  padding: 1em;
}
#payment-plan-offers-last-card--header {
  text-align: center;
}
.copied {
  width: 100%;
  text-align: center;
  color: white;
}
</style>
<i18n>
{
  "pt": {
    "whatsapp": "WhatsApp",
    "chat": "Chat",
    "call": "Ligação",
    "speakToSomeone": "Falar com atendente",
    "copied": "Copiado",
    "whatsappCopyText": "11-3335-2976",
    "callCopyText": "3004-7242"
  }
}
</i18n>
