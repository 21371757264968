





























































import { computed, defineComponent, onMounted, Ref, ref } from "@vue/composition-api"
import analytics from "@/lib/analytics"
import Hero from "@/components/loan-processing/Hero.vue"
import Activity from "@/components/loan-processing/Activity.vue"
import { useLPStore } from "@/store"
import { LPActivities, LPActivityData } from "@/api/secure/lp-service"
import lodash from "lodash"
import contact from "@/config/contact"

export default defineComponent({
  name: "LoanProcessing",
  components: { Hero, Activity },
  methods: {
    activityHeader(activityName: string) {
      switch (activityName) {
      case("phone_verification"):
        return this.$t("activities.phoneVerification")
      case("identity_verification"):
        return this.$t("activities.identityVerification")
      case("income_verification"):
        return this.$t("activities.incomeVerification")
      }
    }
  },
  setup() {
    const { activities, directDebitOptIn, primaryOffer, secondaryOffer } = useLPStore()
    const showOptIn: Ref<boolean> = ref(false)

    const pendingActivities = computed(() => lodash.filter(activities.value, (activity) => {
      // invalid phone verification is handled by template
      if (activity.name === "phone_verification" && activity.status === "invalid") {
        return false
      }

      return ["in_progress", "invalid"].includes(activity.status)
    }))
    const underReviewActivities = computed(() => lodash.filter(activities.value, (activity) => activity.status === "in_review"))
    const completedActivities = computed(() => lodash.filter(activities.value, (activity) => !["in_progress", "in_review", "invalid"].includes(activity.status)))

    const activityLinks = {
      "phone_verification": "ConfirmTelephone",
      "identity_verification": "ConfirmIdentity",
      "income_verification": "ConfirmEBS"
    }

    const activityStatus = (activity: LPActivityData) => {
      if (["success", "failure"].includes(activity.status)) {
        return "complete"
      }

      return activity.status
    }

    const invalidPhoneVerification = computed(() => {
      for (const activity of activities.value) {
        if (activity.name === "phone_verification" && activity.status === "invalid") {
          return true
        }
      }

      return false
    })

    const showCompletedHeader = () => {
      return pendingActivities.value.length == 0 && !invalidPhoneVerification.value
    }

    const optInStatus = () => {
      return directDebitOptIn.value
    }

    const optInSavings = () => {
      const savingsAmount = Number(secondaryOffer?.value?.first_installments_amount) - Number(primaryOffer?.value?.first_installments_amount)
      if (isNaN(savingsAmount)) {
        return 0
      }

      return Math.abs(savingsAmount)
    }

    const showOptInFeature = () => {
      showOptIn.value = analytics.plugins.optimizely.isFeatureEnabled("opt_in_on_lp_page")
    }

    onMounted(async () => {
      analytics.track("lp_page_viewed")
      showOptInFeature()
    })

    return {
      activities,
      activityLinks,
      activityStatus,
      directDebitOptIn,
      optInStatus,
      optInSavings,
      showOptIn,
      pendingActivities,
      completedActivities,
      underReviewActivities,
      invalidPhoneVerification,
      contact,
      showCompletedHeader
    }
  }
})
