
import { defineComponent, nextTick, onMounted, onUnmounted, ref } from "@vue/composition-api"
import LowerMyPaymentQuotes from "@/components/account-home/LowerMyPaymentQuotes.vue"
import { useAccountDetailsStore } from "@/store"
import lodash from "lodash"

export default defineComponent({
  name: "LowerMyPayment",
  components: { LowerMyPaymentQuotes },
  props: {
    useV2Layout: {
      default: false,
      type: Boolean
    }
  },
  setup() {
    const { eligibleProducts } = useAccountDetailsStore()
    const productDetails = eligibleProducts.value?.eligible_offers?.lower_my_payment?.eligible_product_details
    const quotes = eligibleProducts.value?.eligible_offers?.lower_my_payment?.quotes
    const sortedQuotes = lodash.orderBy(quotes, ["InstallmentsRequested", ["asc"]])

    const windowWidth = ref(window.innerWidth)
    const onResize = function() {
      windowWidth.value = window.innerWidth
    }

    onMounted(() => {
      nextTick(() => {
        window.addEventListener("resize", onResize)
      })
    })

    onUnmounted(() => {
      window.removeEventListener("resize", onResize)
    })

    const isMobile = function() {
      return windowWidth.value < 700
    }

    return {
      productDetails,
      sortedQuotes,
      isMobile
    }
  }
})
