import Vue from "vue"
import Vuetify from "vuetify/lib"
import "@mdi/font/css/materialdesignicons.css"
import ArrowBackGrey from "@/components/icons/ArrowBackGrey.vue" // Ensure you are using css-loader version "^2.1.1" ,

Vue.use(Vuetify)

const vuetify = new Vuetify({
  iconfont: "mdi",
  icons: {
    values: {
      arrowBackGrey: {
        component: ArrowBackGrey
      }
    }
  },
  theme: {
    themes: {
      light: {
        primary: "#0099CC",
        secondary: "#F86821",
        accent: "#82B1FF",
        error: "#DB2435",
        info: "#2A7AB0",
        success: "#0DAA4B",
        warning: "#FDF5AA"
      }
    },
    options: {
      customProperties: true
    }
  }
})


export default vuetify
