
import { computed, defineComponent, ref, watch } from "@vue/composition-api"
export default defineComponent({
  name: "AccountTypeList",
  emits: ["select"],
  props: {
    bankType: {
      type: String,
      required: true
    },
    accountType: {
      type: String,
      required: false,
      default: ""
    },
    caixaAccountType: {
      type: String,
      required: false,
      default: ""
    },
    rules: {
      type: Array,
      required: false,
      default: () => []
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { emit }) {
    const currentSelection = ref<string>("")

    const selectBradesco = () => {
      if (props.accountType == "") {
        currentSelection.value = ""
      } else {
        currentSelection.value = props.accountType == "checking" ? "corrente" : "poupança"
      }
    }

    const selectCaixa = () => {
      currentSelection.value = props.caixaAccountType
    }

    const updateCurrentSelectionFromBankType = () => {
      if (props.bankType !== "") {

        if (props.bankType == "237") {
          selectBradesco()
          return
        }

        if (props.bankType == "104") {
          selectCaixa()
          return
        }

        return
      }

      currentSelection.value = ""
    }

    updateCurrentSelectionFromBankType()

    watch(() => props.bankType, () => {
      updateCurrentSelectionFromBankType()
    })

    const bradesco = [
      { text: "Por favor selecione", value: "" },
      { text: "Conta Corrente Individual - 01", value: "corrente" },
      { text: "Conta Poupança Individual - 02", value: "poupança" }
    ]

    const caixa =  [
      { text: "Por favor selecione", value: "" },
      { text: "Conta corrente (nova numeração) - 3701", value: "3701" },
      { text: "Conta poupança (nova numeração) - 1288", value: "1288" },
      { text: "Conta corrente - 001", value: "001" }
    ]

    const items = computed(() => {
      if (props.bankType == "237") {
        return bradesco
      } else if (props.bankType == "104") {
        return caixa
      }

      return []
    })

    const onSelect = (value: string) => {
      currentSelection.value = value
      emit("select", value)
    }

    return {
      items,
      currentSelection,
      onSelect
    }
  }
})
