

















import { computed, defineComponent } from "@vue/composition-api"
import { useLPStore } from "@/store"
export default defineComponent({
  name: "SimplicExpressHeader",
  setup() {
    const { activities } = useLPStore()

    const activity = computed(() => {
      for (const activity of activities.value) {
        if (activity.name != "income_verification") {
          continue
        }

        return activity
      }
    })

    const status = computed(() => {
      return activity.value?.status
    })

    const reason = computed(() => {
      return activity.value?.reason
    })

    return {
      status,
      reason
    }
  }
})
