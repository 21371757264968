




















































/* eslint-disable @typescript-eslint/camelcase */
import { useCookieStore } from "@/store"
import Vue from "vue"
import analytics from "@/lib/analytics"
import contact from "@/config/contact"

export default {
  name: "LogoutDialog",
  data() {
    return {
      showDialog: false,
      chatUrl: "/chat"
    }
  },
  computed: {
    contact() {
      return contact
    }
  },
  methods: {
    logout: function (this: Vue) {
      const { analyticDataLayer } = useCookieStore()
      if (analyticDataLayer.value.inDefaultNoPmtPlan == true) {
        analytics.plugins.optimizely.trackEvent("exit_collection_pop_shown")
        this.$data.showDialog = true
      } else {
        this.$data.showDialog = false
        this.$router.push("/logout")
      }
    },
    whatsAppClick: function() {
      analytics.plugins.optimizely.trackEvent("exit_collection_pop_whatsapp_clicked")
    },
    chatClick: function(this: Vue) {
      analytics.plugins.optimizely.trackEvent("exit_collection_pop_chat_clicked")
    },
    closeDialog: function(this: Vue) {
      this.$data.showDialog = false
      analytics.plugins.optimizely.trackEvent("exit_collection_pop_voltar_clicked")
    }
  }
}
