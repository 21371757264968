import Vue from "vue"
import analytics from "@/lib/analytics"
import { NavigationGuardNext } from "vue-router"
import { useCookieStore } from "@/store"
import { AppRoute } from "@/router/meta"

export function loggedIn(): boolean {
  try {
    const { readAccessTokens } = useCookieStore()
    return readAccessTokens().access_token
  } catch (e) {
    return false
  }
}

// beforeEach handles routing specific logic before the route is landed on, for all routes.
// Used to ensure pages can't be viewed if they require login and the user is not logged in.
export default async function beforeEach(to: AppRoute, from: AppRoute, next: NavigationGuardNext<Vue>) {
  if (process.env.VUE_APP_ENVIRONMENT === "development" || !to.meta?.requiresLogin) {
    next()
    return
  }
  if (!loggedIn()) {
    next("/login")
    return
  }

  const { analyticDataLayer } = useCookieStore()
  analytics.identify(String(analyticDataLayer.value.customerId), analyticDataLayer.value)

  next()
}
