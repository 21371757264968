




















import { defineComponent } from "@vue/composition-api"
import Contact from "./Contact.vue"
import Legal from "./Legal.vue"
export default defineComponent({
  name: "MobileFooter",
  components: { Contact, Legal }
})
