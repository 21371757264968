import { NullableValidationObject } from "./common"

export interface ValidatorArguments {
  value: NullableValidationObject;
  message: string;
  opts?: Record<string, any>;
}

export abstract class BaseValidator {
  static validate(args: ValidatorArguments): boolean | string {
    if (args.value) {
      return true
    } else return args.message || "Not a valid value."
  }

  // 'Required' core validator, can be negated to allow for empty values on most validators
  static required(value: NullableValidationObject): boolean | string {
    if (Array.isArray(value)) return !!value.length
    if (value === undefined || value === null) {
      return false
    }

    if (value === false) {
      return true
    }

    if (value instanceof Date) {
      // invalid date won't pass
      return !isNaN(value.getTime())
    }

    if (typeof value === "object") {
      for (const _ in value) return true
      return false
    }

    return !!String(value).length
  }

  static len(value: NullableValidationObject): number {
    if (Array.isArray(value)) return value.length
    if (value === null || value === undefined) return 0
    if (typeof value === "object") {
      return Object.keys(value).length
    }
    return String(value).length
  }
}