import { render, staticRenderFns } from "./CodeVerification.vue?vue&type=template&id=3b8a5f78&"
import script from "./CodeVerification.vue?vue&type=script&lang=ts&"
export * from "./CodeVerification.vue?vue&type=script&lang=ts&"
import style0 from "./CodeVerification.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./CodeVerification.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VOtpInput } from 'vuetify/lib/components/VOtpInput';
installComponents(component, {VBtn,VDivider,VForm,VOtpInput})
