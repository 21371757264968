
































































































/* eslint-disable @typescript-eslint/camelcase */
import { computed, defineComponent, onMounted, Ref, ref } from "@vue/composition-api"
import PaymentPlanOffersMobile from "@/components/payment-plans/OffersCarousel.vue"
import PaymentPlanOffersDesktop from "@/components/payment-plans/OffersCards.vue"
import SimplicDatepicker from "@/components/SimplicDatepicker.vue"
import store, {
  useAccountStore,
  useCookieStore,
  usePaymentPlansStore
} from "@/store"
import {
  fetchUnpaidInstallments,
  PaymentPlanOffer,
  UnpaidInstallmentsPayload
} from "@/api/secure/sysopen-gateway-service"
import analytics, { logError } from "@/lib/analytics"
import {
  defaultedInstallmentBalance,
  calculateDownPaymentOptions,
  nextHighestNumberPast
} from "@/lib/account/helpers"

export default defineComponent({
  name: "PaymentPlanOffers",
  components: { PaymentPlanOffersMobile, SimplicDatepicker, PaymentPlanOffersDesktop },
  setup(props, { root }) {
    const paymentMethods = computed(() => store.getters["paymentPlans/availablePaymentMethods"])
    const { accountID, cpf } = useAccountStore()
    const { offers, loading, fetchOffers, selectOffer, confirmed, confirmOffer, fetchBarcodeByPaymentMethod, firstDueDate: firstDueDateStore, downPaymentAmount: downPaymentAmountStore, withDownPayment, currentPaymentMethod, selectedOffer } = usePaymentPlansStore()
    const fetchingNewOffers = ref(false)
    const confirmingOfferSelection = ref(false)
    const datepicker: Ref<{ valid: boolean } | null> = ref(null)
    const defaultMaxDays = 10
    const firstDueDate = ref(firstDueDateStore.value?.substring(0, 10))
    const firstDueDateMaxDaysFromNow = ref(defaultMaxDays)
    const downPaymentAmount = ref(downPaymentAmountStore.value)
    const { analyticDataLayer } = useCookieStore()
    const minDaysInDefaultForDownPayment = 31
    const wantsCustomDownPayment = ref(false)
    const startingSelection = "Selecione uma opção"
    const downPaymentOptions = ref([startingSelection])
    const downPaymentSelection = ref(startingSelection)

    const onSelectOfferDesktop = (offer: PaymentPlanOffer) => {
      selectOffer(offer)
      scrollBy(0, 100)
    }
    const onSelectOfferMobile = (offer: PaymentPlanOffer) => {
      selectOffer(offer)
      root.$router.push({
        name: "PaymentPlanPaymentConfirmation"
      })
    }

    const routeToConfirmation = () => {
      if (selectedOffer.value?.payment_method === "Pix") {
        root.$router.push({
          name: "PixPaymentPlanConfirmation"
        })
      } else {
        root.$router.push({
          name: "BoletoPaymentPlanConfirmation"
        })
      }
    }

    const onConfirm = async () => {
      try {
        confirmingOfferSelection.value = true

        if (confirmed.value) {
          routeToConfirmation()
          return
        }

        // Confirm button is only visible if there is a selected offer
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const confirmationResult = await confirmOffer()
        if (!confirmationResult) {
          // error handling happens at store level
          return
        }

        const barcodeResult = await fetchBarcodeByPaymentMethod()
        if (!barcodeResult) {
          root.$router.push("/loan-status")
          return
        }

        routeToConfirmation()
      } finally {
        confirmingOfferSelection.value = false
      }
    }

    const onDateSelection = async (chosenIsoDate: string) => {
      selectedOffer.value = null
      confirmed.value = false

      if (!datepicker.value?.valid) {
        return false
      }

      fetchingNewOffers.value = true
      if (chosenIsoDate !== firstDueDate.value) {
        await fetchOffers(chosenIsoDate, downPaymentAmount.value)
        firstDueDate.value = chosenIsoDate
      }
      fetchingNewOffers.value = false
    }

    const onDownPaymentCheckboxChange = async () => {
      if (downPaymentSelection.value !== startingSelection) {
        selectedOffer.value = null
        confirmed.value = false

        fetchingNewOffers.value = true
        const amount = !wantsCustomDownPayment.value ? "000" : downPaymentSelection.value
        await fetchOffers(firstDueDate.value || "", amount)
        downPaymentAmount.value = amount
        fetchingNewOffers.value = false
        return true
      }
      return false
    }

    const onDownPaymentChange = async () => {
      selectedOffer.value = null
      confirmed.value = false

      fetchingNewOffers.value = true
      const amount = (downPaymentSelection.value === startingSelection) ? "000" : downPaymentSelection.value
      await fetchOffers(firstDueDate.value || "", amount)
      downPaymentAmount.value = amount
      fetchingNewOffers.value = false
      return true
    }

    const paymentMethodImage = (paymentMethod: string) => {
      return require(`@/assets/images/svg/${paymentMethod.toLowerCase()}_btn.svg`)
    }

    const setCurrentPaymentMethod = (method: string) => {
      currentPaymentMethod.value = method
      selectedOffer.value = store.getters["paymentPlans/matchingOfferByPaymentMethod"]
    }

    onMounted(() => {
      analytics.track("payment_plan_offers_presented")

      const dueDateEnabled = analytics.plugins.optimizely.isFeatureEnabled("payments_plan_firstduedate")
      if (dueDateEnabled) {
        const maxDaysFromNow = analytics.plugins.optimizely.getFeatureVariableInteger("payments_plan_firstduedate", "first_due_date", accountID.value.toString())
        firstDueDateMaxDaysFromNow.value = maxDaysFromNow || defaultMaxDays
      }

      let maxDaysInDefault = 0
      if (analyticDataLayer.value.maxDaysInDefault !== null) {
        maxDaysInDefault = analyticDataLayer.value.maxDaysInDefault
      }

      if (maxDaysInDefault >= minDaysInDefaultForDownPayment) {
        withDownPayment.value = true
      }

      if (withDownPayment.value) {
        const payload: UnpaidInstallmentsPayload = { cpf: cpf.value.toString() }
        fetchUnpaidInstallments(accountID.value, payload)
          .then((response) => {
            const unpaidInstallments = response.unpaid_installments
            const balance = defaultedInstallmentBalance(unpaidInstallments)
            const options = calculateDownPaymentOptions(balance)
            downPaymentOptions.value.push(...options)

            const optimizelyVariation = analytics.plugins.optimizely.activate("collection_platform_default_offers")
            if (optimizelyVariation === "down_payment_offers") {
              const optionsAsNumbers = options.map(Number)
              const preselectedOption = nextHighestNumberPast(balance * 0.30, optionsAsNumbers) || 100

              wantsCustomDownPayment.value = true
              downPaymentSelection.value = preselectedOption.toString()
              onDownPaymentCheckboxChange()
            }
          })
          .catch(error => {
            logError(error)
          })
      }
    })

    return {
      datepicker,
      loading,
      firstDueDate,
      firstDueDateMaxDaysFromNow,
      downPaymentAmount,
      withDownPayment,
      offers,
      confirmed,
      onSelectOfferDesktop,
      onSelectOfferMobile,
      onDateSelection,
      onDownPaymentChange,
      selectedOffer,
      onConfirm,
      fetchingNewOffers,
      confirmingOfferSelection,
      paymentMethods,
      currentPaymentMethod,
      paymentMethodImage,
      setCurrentPaymentMethod,
      wantsCustomDownPayment,
      downPaymentOptions,
      downPaymentSelection,
      onDownPaymentCheckboxChange
    }
  }
})
