





































import contact from "@/config/contact"
import { defineComponent } from "@vue/composition-api"
import { useSessionStore } from "@/store"
export default defineComponent({
  name: "NavigationDrawerUnAuth",
  setup: (_, { emit  }) => {
    const updateDrawer = (payload: boolean) => {
      emit("input", payload)
    }
    const { logIn, navDrawerOpen } = useSessionStore()
    const items = [
      {
        title: "Como Funciona",
        path: "/como-funciona",
        icon: "mdi-format-list-numbered"
      },
      {
        title: "Quem Somos",
        path: "/quem_somos",
        icon: "mdi-account-multiple"
      },
      {
        title: "Ajuda",
        path: "/faq",
        icon: "mdi-help-box"
      },
      {
        title: "Blog",
        path: "/blog",
        icon: "mdi-file-document-multiple"
      }
    ]
    return {
      updateDrawer,
      items,
      contact,
      logIn,
      navDrawerOpen
    }
  }
})
