import Vue from "vue"
import VueGtm from "vue-gtm"
import "@/plugins/composition-api"
import "@/plugins/sentry/sentry"
import vuetify from "@/plugins/vuetify"
import "@/plugins/stylesheets"
import "@/plugins/axios"
import i18n from "./plugins/i18n"
import "@/plugins/meta-tags"
import "@/plugins/hotjar"
import "@/plugins/directives"
import "@/plugins/vue-masonry-wall"

import App from "./App.vue"
import router from "./router"
import store from "./store"
import VueRouter from "vue-router"
import analytics from "@/lib/analytics"

Vue.use(VueRouter)

Vue.use(VueGtm, {
  id:           "GTM-NL6QKJ",
  enabled:      process.env.VUE_APP_ENVIRONMENT !== "development",
  debug:        false,
  loadScript:   true,
  vueRouter:    router
})

Vue.config.productionTip = false
Vue.config.devtools = process.env.VUE_APP_ENVIRONMENT !== "production"

// This forces analytics to wait for the optimizely plugin to load before any use, and the .ready() callback
// only fires once, so this is the place to do the check
analytics.ready(() => {
  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App)
  }).$mount("#app")
})
