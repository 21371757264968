























































































































import { computed, defineComponent, ref } from "@vue/composition-api"
import { useCookieStore, useAccountStore } from "@/store"
import lodash from "lodash"
import LogoutListItem from "@/components/dialogs/LogoutDialog.vue"

export default defineComponent({
  name: "DesktopHeaderAuth",
  components: { LogoutListItem },
  setup(_, { root }) {
    const userMenu = ref(false)
    const aboutMenu = ref(false)
    const loanApplicationsMenu = ref(false)
    const { accountHomeMenu } = useCookieStore()
    const displayRefiLink = accountHomeMenu.value?.top_up_refi?.display
    const onLPPage = root.$route?.path?.includes("/lp")
    const logoLink = ref("/")

    const { fullName } = useAccountStore()
    const firstName = computed(() => {
      const fName =  fullName.value!.split(" ")[0]
      return lodash.capitalize(fName)
    })

    return { userMenu, aboutMenu, loanApplicationsMenu, firstName, displayRefiLink, onLPPage, logoLink }
  }
})
