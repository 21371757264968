









import { defineComponent, ref } from "@vue/composition-api"
import { getWhatsAppConsentText } from "@/api/secure/whatsapp-consent"
import VueI18n from "@/plugins/i18n"

export default defineComponent({
  name: "WhatsAppConsentCheckbox",
  props: {
  },
  methods: {
    emitCheckboxClick () {
      this.checkboxIsSelected = !this.checkboxIsSelected
      this.$emit("consentCheckbox", this.checkboxIsSelected)
    }
  },
  setup() {
    const checkboxIsSelected = ref(false)
    const iOptIn = ref<string>("")

    const getOptInText = async () => {
      try {
        const response = await getWhatsAppConsentText()
        iOptIn.value = response.configuration.consent_text
      } catch (error) {
        iOptIn.value = VueI18n.t("iOptIn").toString()
      }
    }

    getOptInText()

    return {
      checkboxIsSelected,
      iOptIn
    }
  }
})
