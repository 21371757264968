import { createHelpers } from "vuex-map-fields"
import { Store } from "@/store"
import { computed, WritableComputedRef } from "@vue/composition-api"


type GettersAndSetter<T> = { [key in keyof T]: { get(): T[key]; set(v: T[key]): void } }

export interface BuildHelpers<State> {
  mapGettersAndSettersToComputed: (fields: Array<keyof State>) => { [key in keyof GettersAndSetter<State>]: WritableComputedRef<State[key]> };
}

// Store is not initialized at runtime, so passing a storeResolver
export function buildHelpers<State>(storeResolver: () => Store, moduleNamespace: string): BuildHelpers<State> {
  const { mapFields } = createHelpers({
    getterType: `${moduleNamespace}/getField`,
    mutationType: `${moduleNamespace}/updateField`
  })

  // Maps Vuex's getters and setters to computed references for Vue to use in templates
  function mapGettersAndSettersToComputed(fields: Array<keyof State>): { [key in keyof GettersAndSetter<State>]: WritableComputedRef<State[key]> } {
    const mappedFields = mapFields(fields)
    // Vue.$store is bound to captcha store...
    //  work around for now is to FORCE bind function to a store we need
    fields.forEach(f => {
      mappedFields[f].get = mappedFields[f].get.bind({ $store: storeResolver() })
      mappedFields[f].set = mappedFields[f].set.bind({ $store: storeResolver() })
    })
    const refs = {} as { [key in keyof State]: WritableComputedRef<State[key]> }
    return fields
      .reduce((p, f) => {
        /*   While types below work, this explicit cast should not be necessary
         *   Maybe some TS bug related to overloaded functions, as computed has overloaded definitions
         * */
        return Object
          .assign(p, { [f]: computed(mappedFields[f] as { get(): State[typeof f]; set(x: State[typeof f]): void }) })
      }, refs)
  }


  return { mapGettersAndSettersToComputed }
}
