
import { defineComponent, onMounted, ref } from "@vue/composition-api"
import tagRecordings from "@/plugins/hotjar/tagRecordings"
import { VueWithHotjar } from "@/types"
export default defineComponent({
  name: "LowerMyPaymentCard",
  setup(_, { root }) {
    const lowerMyPaymentPath = ref<string>("/lower-my-payment")

    onMounted(() => {
      tagRecordings(root as VueWithHotjar, ["lower_my_payment_card_displayed"])
    })

    return {
      lowerMyPaymentPath
    }
  }
})
