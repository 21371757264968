








































import { checkCode } from "@/api/secure"
import { defineComponent, onMounted, Ref, ref } from "@vue/composition-api"
import { CodeCheckPayload, CodeRequestResponse } from "@/api/secure/frontend-service"
import { useLPStore } from "@/store"
import { logError } from "@/lib/analytics"

export default defineComponent({
  name: "CodeVerification",
  setup (_, { emit, root }) {
    const { activities } = useLPStore()
    const showCodeInvalid: Ref<boolean> = ref(false)
    const codeSubmitting: Ref<boolean> = ref(false)
    const code: Ref<string> = ref("")

    const validateCode = async () => {
      const c = code.value
      if (c.length !== 4) {
        return false
      }
      codeSubmitting.value = true
      /* eslint-disable @typescript-eslint/camelcase */
      const pData: CodeCheckPayload = {
        code: c,
        which_phone: "main_phone",
        main_phone_verification_1: c[0],
        main_phone_verification_2: c[1],
        main_phone_verification_3: c[2],
        main_phone_verification_4: c[3]
      }
      /* eslint-enable @typescript-eslint/camelcase */
      const response: CodeRequestResponse = await checkCode(pData)
      codeSubmitting.value = false
      if (response.status !== "valid_code") {
        showCodeInvalid.value = true
        code.value = ""
        emit("phoneNumberValidated", false)
        return
      }
      showCodeInvalid.value = false
      emit("phoneNumberValidated", true)
      activities.value.forEach(activityData => {
        if(activityData.name == "phone_verification") {
          activityData.status = "success"
        }
      })
    }

    const onChange = (c: string) => {
      code.value = c
    }

    const onFinish = (c: string) => {
      code.value = c
      validateCode()
    }

    onMounted(() => {
      if ("OTPCredential" in window) {
        window.addEventListener("DOMContentLoaded", e => {
          const input = document.querySelector("input[autocomplete=\"one-time-code\"]")
          if (!input) return
          const ac = new AbortController()
          const form = input.closest("form")
          if (form) {
            form.addEventListener("submit", e => {
              ac.abort()
            })
          }
          navigator.credentials.get({
            otp: { transport:["sms"]},
            signal: ac.signal
          } as never).then((otp: (Credential & { code?: string }) | null) => {
            code.value = otp?.code || ""
            if (form) form.submit()
          }).catch(err => {
            logError(err)
          })
        })
      }
    })

    return {
      showCodeInvalid,
      codeSubmitting,
      code,
      onChange,
      onFinish,
      validateCode
    }
  }
})
